import { Injectable } from '@angular/core';
import * as R from 'ramda';

import { LocationAttribute, LocationAttributeChoice, LocationAttributeV4, LocationAttributeWithChoices } from '../../shared-models';

import {
  LOCATION_ATTRIBUTE_LABEL_MAPPING,
  RESERVED_LOCATION_ATTRIBUTE_KEY_MAPPING
} from '../constants';
import { mapRegionChoices } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class LocationAttributeMappingService {

  /**
   * Parses an array of Location Attributes and maps pre-defined
   * Location Attribute keys to corresponding keys of the Locations API response.
   *
   * Run this method _before_ performing any label mapping, if applicable.
   *
   * @param attributes An array of location attributes
   */
  public mapAttributeKeysToLocationKeys
  <T extends LocationAttribute | LocationAttributeWithChoices | LocationAttributeV4>
  (attributes: T[]): T[] {
    return attributes.map(attribute => this.mapAttributeKeyToLocationKey(attribute));
  }

  public mapAttributeKeyToLocationKey
  <T extends LocationAttribute | LocationAttributeWithChoices | LocationAttributeV4>
  (attribute: T): T {
    const copyAttribute = R.clone(attribute);
    const existingAttr = RESERVED_LOCATION_ATTRIBUTE_KEY_MAPPING.find(attr => copyAttribute.key === attr.unmappedKey);
    if (existingAttr) {
      copyAttribute.key = existingAttr.mappedKey;
    }

    return copyAttribute;
  }

  /**
   * Parses an array of Location Attributes and maps certain
   * Location Attribute labels provided by the API to preferred
   * labels for display on the front end.
   *
   * Run this method _after_ performing any key mapping, if applicable.
   *
   * @param attributes An array of location attributes
   */
  public mapAttributeLabels<T extends LocationAttribute | LocationAttributeWithChoices>(attributes: T[]): T[] {
    return attributes.map(attr => this.mapAttributeLabel(attr));
  }

  public mapAttributeLabel<T extends LocationAttribute | LocationAttributeWithChoices>(attr: T): T {
    const mappedAttribute = LOCATION_ATTRIBUTE_LABEL_MAPPING.find(mappedAttr => mappedAttr.attributeKey === attr.key);
    if (mappedAttribute) {
      attr.label = mappedAttribute.attributeLabel;
    }
    return attr;
  }

  /**
   * Parses an array of Location Attributes for a certain
   * region-related Location Attribute and then maps certain
   * `choices` values to shorten their label for display purposes.
   *
   * @param attributes An array of location attributes
   * @param keysMapped A boolean flag, indicating whether the Location
   * Attribute keys have been mapped from Location Attributes API to Locations API
   * values.
   */
  public mapRegionAttributeChoices(
    attributes: LocationAttributeWithChoices[],
    keysMapped: boolean
  ): LocationAttributeWithChoices[] {
    // TODO: It is currently unclear, which key this mapping
    // was supposed to be applied to (likely what is now "subregion").
    // Asked for confirmation, if this mapping is even still necessary
    // as encoding seems to have changed. Answer pending, leaving mapping
    // in for now.
    const regionKey = keysMapped ? 'subregion' : 'region';
    return attributes.map(attr => {
      if (attr.key === regionKey && attr.choices && attr.choices.length) {
        attr.choices = [...mapRegionChoices(attr.choices)];
      }
      return attr;
    });
  }
}
