import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LoadingModule } from '../loading';
import {  IconModule } from '../icon';
import { ErrorModule } from '../error';
import { DetailsNorowsOverlayComponent } from './';

@NgModule({
  declarations: [
    DetailsNorowsOverlayComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    ErrorModule,
    RouterModule,
    LoadingModule,
  ],
  exports: [
    DetailsNorowsOverlayComponent,
  ]
})
export class DetailsNorowsOverlayModule { }
