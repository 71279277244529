import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BlockLayoutContentItem } from '../../../shared-models';
import { WrittenContentLinkActionsUnion } from '../../listeners/written-content-anchor-click-listener';

@Component({
  selector: 'grid-ui-body-item-block-layout',
  templateUrl: './body-item-block-layout.component.html'
})
export class BodyItemBlockLayoutComponent implements OnInit {

  @Input() public contentItem: BlockLayoutContentItem | null = null;

  @Output() public writtenContentLinkActionEmit = new EventEmitter<WrittenContentLinkActionsUnion>();

  public contentWidths: string[] = [];
  public validContent = false;

  public ngOnInit(): void {
    if (this.contentItem && this.validateWidthsAgainstBlocks(this.contentItem)) {
      this.contentWidths = this.createBootstrapColumns(this.contentItem.value.widths);
      this.validContent = true;
    } else {
      this.validContent = false;
    }
  }

  public createBootstrapColumnsFromWidths(widths: number[]): number[] {
    const totalsOfWidths = widths.reduce((a, b) => a + b, 0);
    let newWidths = widths.map((rawWidth) => {
      const percentage = Math.round(rawWidth / totalsOfWidths * 100);

      const bootstrapColumnValue = Math.round((percentage / 100) * 12);
      return bootstrapColumnValue;
    });

    const shouldHaveZero = widths.some((nw) => nw === 0);
    const hasZero = newWidths.some((nw) => nw === 0);
    if (!shouldHaveZero && hasZero) {
      const highest = newWidths.indexOf(Math.max(...newWidths));
      const numberWithZero = newWidths.filter((w) => w === 0).length;
      newWidths[highest] = newWidths[highest] - numberWithZero;
      newWidths = newWidths.map(nw => {
        if (nw === 0) {
          nw = 1;
        }
        return nw;
      });

    }

    return newWidths;
  }

  public emitWrittenContentLinkAction(action: WrittenContentLinkActionsUnion): void {
    this.writtenContentLinkActionEmit.emit(action);
  }

  private createBootstrapColumns(widths: number[]): string[] {
    const bootstrapColumnClass = 'col';
    const bootstrapWidths = this.createBootstrapColumnsFromWidths(widths);
    const widthsGreaterThanZero = bootstrapWidths.filter((w) => w > 0);
    const bootstrapClasses = widthsGreaterThanZero.map((width) => `${bootstrapColumnClass}-${width}`);
    return bootstrapClasses;
  }

  private validateWidthsAgainstBlocks(contentItem: BlockLayoutContentItem): boolean {
    if (contentItem.value && contentItem.value.widths && contentItem.value.blocks) {
      const widths = contentItem.value.widths;
      const blocks = contentItem.value.blocks;
      if ((widths.length === blocks.length) && (!widths.some(isNaN))) {

        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }







}
