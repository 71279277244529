import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowUtilitiesService } from '../../shared-utilities';
import { ErrorComponentData } from '../../shared-models';


export enum DetailsOverlayModule {
  alerts = 'alerts',
};

export interface DetailsNoRowsOverlayAdditionalParams {
  shouldShowLoadingMsg$?: Observable<boolean>;
  shouldShowErrorMsg$: Observable<boolean>;
  error$: Observable<ErrorComponentData | null>;
  loadData: () => null | void;
  module?: `${DetailsOverlayModule}`;
  shouldShowNoRowsMsg$?: Observable<boolean>;
};


@Component({
  selector: 'grid-ui-details-norows-overlay',
  templateUrl: './details-norows-overlay.component.html',
  styleUrls: ['./details-norows-overlay.component.scss']
})
export class DetailsNorowsOverlayComponent implements ILoadingOverlayAngularComp {
  public params!: DetailsNoRowsOverlayAdditionalParams;
  public modules = DetailsOverlayModule;

  public get shouldShowNoRowsMsg$(): Observable<boolean> {
    return combineLatest([
      this.params.shouldShowErrorMsg$,
      this.params.shouldShowLoadingMsg$ || of(false),
      this.params.shouldShowNoRowsMsg$ || of(false),
    ]).pipe(map(([ loading, err, noRows ]) =>
      !loading && !err && noRows && !!this.params.module
    ));
  }

  public constructor (private readonly windowUtilitiesService: WindowUtilitiesService) { }

  public agInit(params: any): void {
    this.params = params;
  }

  public reloadApp(): void {
    this.windowUtilitiesService.reloadApp();
  }
}
