<div class="modal-container account-settings-modal">
  <div class="modal-header">
    <input ngbAutofocus class="no-size">
    <h5 class="modal-title">Account Settings</h5>
    <grid-ui-icon
      class="close-icon"
      aria-label="Close"
      data-hook="settingsCloseButton"
      (click)="close(null)"
      ngbTooltip="Close window"
      name="cross"
    >
    </grid-ui-icon>
  </div>
  <div class="modal-body">
    <div class="mc-actions-header">
      <h2 class="mr-auto lh-22">Account Settings</h2>
      <button type="button" class="btn mc-btn-secondary save-btn ml-auto"
        [disabled]="savingStatus.status === SavingRequestStatus.saving"
        data-hook="settingsEditPasswordButton"
        (click)="close(AccountSettingsModalResolvedValues.CHANGE_PASSWORD)"
      >
        Edit Password
      </button>
    </div>
    <form class="modal-body-main-content" [formGroup]="form" *ngIf="form">
      <div class="mc-form-container">
  
        <div class="mc-form-group-container">
          <div class="form-group">
            <label [for]="controlNames.FIRST_NAME">First name(s)</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.FIRST_NAME"
              [id]="controlNames.FIRST_NAME"
              placeholder="Enter first name(s)"
              data-hook="settingsFirstName"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.FIRST_NAME)}"
            />
            <div class="validation-error"
              *ngIf="isErrorInFormValue(controlNames.FIRST_NAME)"
            >
              <span *ngIf="form!.get(controlNames.FIRST_NAME)?.hasError('required')"
                data-hook="settingsFirstNameRequired"
              >
                First name is required
              </span>
              <span *ngFor="let errorMessage of parsedSavingError.first_name; index as i"
                [attr.data-hook]="'settingsFirstNameValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
          <div class="form-group">
            <label [for]="controlNames.LAST_NAME">Last name</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.LAST_NAME"
              [id]="controlNames.LAST_NAME"
              placeholder="Enter last name"
              data-hook="settingsLastName"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.LAST_NAME)}"
            />
            <div class="validation-error"
              *ngIf="isErrorInFormValue(controlNames.LAST_NAME)">
              <span *ngIf="form!.get(controlNames.LAST_NAME)?.hasError('required')"
                data-hook="settingsLastNameRequired"
              >
                Last name is required
              </span>
              <span *ngFor="let errorMessage of parsedSavingError.last_name; index as i"
                [attr.data-hook]="'settingsLastNameValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
        </div>
  
        <div class="mc-form-group-container">
          <div class="form-group">
            <label>Corporate email address</label>
            <div class="email-container"> 
              <div
                class="email-field"
                ngbTooltip="Contact support@maplecroft.com to request a change to your email address"
                data-hook="settingsEmail"
              >{{ currentAccountSettings.email }}</div>
          </div>
           
          </div>
          <div class="form-group">
            <label [for]="controlNames.PHONE">Corporate phone number</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.PHONE"
              [id]="controlNames.PHONE"
              placeholder="Enter phone number"
              data-hook="settingsPhone"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.PHONE)}"
            />
            <div class="validation-error"
              *ngIf="isErrorInFormValue(controlNames.PHONE)">
              <span *ngFor="let errorMessage of parsedSavingError.phone_number; index as i"
                [attr.data-hook]="'settingsPhoneValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
        </div>
  
        <div class="mc-form-group-container">
          <div class="form-group">
            <label [for]="controlNames.CITY">City</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.CITY"
              [id]="controlNames.CITY"
              placeholder="Enter city"
              data-hook="settingsCity"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.CITY)}"
            />
            <div class="validation-error"
            *ngIf="isErrorInFormValue(controlNames.CITY)">
            <span *ngFor="let errorMessage of parsedSavingError.city; index as i"
              [attr.data-hook]="'settingsCityValidation-' + i"
            >
              {{errorMessage}}
            </span>
          </div>
          </div>
          <div class="form-group">
            <label [for]="controlNames.COUNTRY">Country</label>
            <mc-searchable-dropdown
              [id]="controlNames.COUNTRY"
              [unfilteredItems]="countries"
              [selectedItemId]="form!.get(controlNames.COUNTRY)?.value"
              [searchTerm]="countriesSearchTerm"
              [allowNullItem]="true"
              [disabled]="this.countries.content === null || this.countries.content.length === 0 || savingStatus.status === SavingRequestStatus.saving"
              [invalid]="getBackendErrors(controlNames.COUNTRY).length > 0"
              dropdownTogglePlaceholder="Select country"
              searchInputPlaceholder="Search countries"
              noMatchingResultsMessage="No country matches the search term"
              noDataAvailableMessage="No countries available"
              [styleTheme]="dropdownTheme"
              maxBodyHeight="90px"
              (action)="handleCountryDropdownAction($event)"
            >
            </mc-searchable-dropdown>
            <div class="validation-error"
              *ngIf="form!.get(controlNames.COUNTRY)?.touched || form!.get(controlNames.COUNTRY)?.dirty"
            >
              <span *ngFor="let errorMessage of parsedSavingError.country; index as i"
                [attr.data-hook]="'settingsCountryValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
        </div>
  
  
        <div class="mc-form-group-container">
          <div class="form-group">
            <label [for]="controlNames.JOB_TITLE">Job title</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.JOB_TITLE"
              [id]="controlNames.JOB_TITLE"
              placeholder="Enter job title"
              data-hook="settingsJobTitle"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.JOB_TITLE)}"
            />
            <div class="validation-error"
              *ngIf="isErrorInFormValue(controlNames.JOB_TITLE)">
              <span *ngFor="let errorMessage of parsedSavingError.job_title; index as i"
                [attr.data-hook]="'settingsJobTitleValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
          <div class="form-group">
            <label [for]="controlNames.DEPARTMENT">Department</label>
            <input
              type="text" class="form-control"
              [formControlName]="controlNames.DEPARTMENT"
              [id]="controlNames.DEPARTMENT"
              placeholder="Enter department"
              data-hook="settingsDepartment"
              [ngClass]="{invalid: isErrorInFormValue(controlNames.DEPARTMENT)}"
            />
            <div class="validation-error"
              *ngIf="isErrorInFormValue(controlNames.DEPARTMENT)">
              <span *ngFor="let errorMessage of parsedSavingError.department; index as i"
                [attr.data-hook]="'settingsDepartmentValidation-' + i"
              >
                {{errorMessage}}
              </span>
            </div>
          </div>
        </div>

      </div>
    </form>
  
    <div
      *ngIf="savingStatus.status === SavingRequestStatus.error && parsedSavingError.general !== undefined"
      class="mc-backend-error"
    >
      <mc-unexpected-error-status
        [errorStatus]="parsedSavingError.general!"
      >
      </mc-unexpected-error-status>
    </div>
  </div>
  <div class="modal-footer mc-actions-footer">
    <span class="mc-info-links">
      <a class="mc-info-link" href="{{emailPreferenceUrl}}{{currentAccountSettings.email}}" target="_blank" rel="noreferrer noopener"
        data-hook="settingsEmailPreferences"
      >
        Manage email preferences
      </a>
      <a class="mc-info-link" href="https://www.maplecroft.com/privacy-notice/" target="_blank" rel="noopener noreferrer"
        [hidden]="savingStatus.status === SavingRequestStatus.saving || savingStatus.status === SavingRequestStatus.saved"
        data-hook="settingsPrivacyLink"
      >
        Privacy notice
      </a>
      
      <a class="mc-info-link" href="https://www.maplecroft.com/conditions-of-use/" target="_blank"
        rel="noopener noreferrer"
        [hidden]="savingStatus.status === SavingRequestStatus.saving || savingStatus.status === SavingRequestStatus.saved"
        data-hook="settingsTandCLink"
      >
        Conditions of use
      </a>
    </span>
    <button type="button" class="btn mc-btn-primary save-btn"
      [disabled]="getSubmitDisabled()"
      data-hook="settingsSubmitButton"
      (click)="saveSettings()">
      Done
      <mc-loading
        class="mc-saving"
        data-hook="settingsSavingIcon"
        *ngIf="savingStatus.status === SavingRequestStatus.saving"
        [loadingMessage]="''"
        class="loading-icon"
      ></mc-loading>
    </button>
  </div>
</div>