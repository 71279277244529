import { DataWizardUploadAttributeTypes } from '../shared-models';
import { LocationsValidatorsMaxLength } from './validators';

export const attributeTooltip = (attributeType: DataWizardUploadAttributeTypes): string => {
  switch (attributeType) {
    case 'string':
      return 'Alphanumeric and symbols supported, maximum length ' + LocationsValidatorsMaxLength.DEFAULT + ' characters';
    case 'decimal':
      return 'Decimal positive and negative numbers';
    case 'integer':
      return 'Whole positive and negative numbers';
    case 'datetime':
      return 'Use the date picker to select date and time';
    default:
      return '';
  }
};