import { VideoItemValue } from '../../../../shared-models';

export const BLANK_VIDEO_ITEM: VideoItemValue = {
  thumbnail: '',
  description: '',
  video: '',
  id: '',
  title: '',
  options: ''
};
