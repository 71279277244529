export enum PollStatusEnum {
  NotStarted = 'NotStarted',
  Active = 'Active',
  Completed = 'Completed',
  Failed = 'Failed',
  FailedDuplicate = 'FailedDuplicate',
  FailedScoringInProgress = 'FailedScoringInProgress',
  Cancelled = 'Cancelled',
  TimedOut = 'TimedOut',
  ParameterRequired = 'ParameterRequired',
}
