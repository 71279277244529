import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { QuickSearchDropdownItemType } from '../models';

@Component({
  selector: 'grid-ui-quick-search-dropdown-results',
  templateUrl: './quick-search-dropdown-results.component.html',
  styleUrls: ['./quick-search-dropdown-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickSearchDropdownResultsComponent {
  /** Indicates which of the results is active. Used to apply
   * the appropriate styles and to fire the resultSelected event */
  @Input() public activeIndex = 0;
  /** Results to be shown */
  @Input() public results: QuickSearchDropdownItemType[] = [];

  /** Fires when the user hovers on a result, passing along its ID.
   * Bubbled up from child component */
  @Output() public activated = new EventEmitter<number>();
  /** Fires when the user clicks on a result and emits the Country Risk Index id or
   * Country/Site geo_id of the selected result.
   * Bubbled up from child component.
   */
  @Output() public resultSelected = new EventEmitter<QuickSearchDropdownItemType>();

  public activate(index: number): void {
    this.activated.emit(index);
  }

  public resultClicked(item: QuickSearchDropdownItemType): void {
    this.resultSelected.emit(item);
  }
}
