import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { API_SERVICES_CONFIG, CountryRiskView, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';

import { CountryRiskViewsService, CountryRiskDynamicViewsService } from '../../country-risk-views';

import {
  CountryRiskLastViewedSourceItem,
  ApiCountryRiskLastViewedSourceItem
} from '../models';

/**
 * A service to resolve the last view seen by the user.
 */
@Injectable({
  providedIn: 'root'
})
export class CountryRiskLastViewedService {

  private resourceConfig: NonPaginatedResourceConfig;

  constructor(
    private countryRiskViewsService: CountryRiskViewsService,
    private dynamicViewService: CountryRiskDynamicViewsService,
    private readonly http: PortalHttpClient
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.countryRisk.lastViewed._configuration;
  }

  /**
   * This method resolves the "last view" seen by the user in Analyse. This can be a My Risk View or Maplecroft View,
   * or a Dynamic View.
   *
   * Should the user not have any "last viewed" views, or they could not be found when trying to resolve them, a fallback view
   * will be resolved. The fallback is in order of priority:
   * - the first My Risk View, if any exist,
   * - the first Maplecroft Risk View, if any exist, or
   * - a new empty view.
   *
   * Note: When resolving a Dynamic View, it will resolve a dynamic view based on the same source as the last viewed Dynamic
   * View, i.e. Risk Index, Country or Site. The actual view may, however, be newly generated.
   */
  public resolveLastViewed(): Observable<CountryRiskView> {
    return this.getLastViewedItemSource().pipe(
      switchMap(item => this.resolveFromLastViewSource(item))
    );
  }

  /**
   * Get the last viewed item sources on which to base the resolution of the last view seen by the user
   * in Analyse. Returns null, if no exising "last viewed" items could be found.
   */
  private getLastViewedItemSource(): Observable<CountryRiskLastViewedSourceItem | null> {
    return this.http.get<ApiCountryRiskLastViewedSourceItem | null>(
      this.resourceConfig,
      {}
    ).pipe(
      map(sourceItem => this.mapSourceItem(sourceItem))
    );
  }

  private mapSourceItem(apiSourceItem: ApiCountryRiskLastViewedSourceItem | null): CountryRiskLastViewedSourceItem | null {
    if (apiSourceItem === null) {
      return null;
    }
    let sourceItem: CountryRiskLastViewedSourceItem;
    switch (apiSourceItem.type) {
      case 'index':
        sourceItem = {
          type: apiSourceItem.type,
          id: apiSourceItem.identifier,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          last_viewed: apiSourceItem.last_viewed!
        };
        break;
      case 'country':
        sourceItem = {
          type: apiSourceItem.type,
          geo_id: apiSourceItem.identifier,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          last_viewed: apiSourceItem.last_viewed!
        };
        break;
      case 'site':
        sourceItem = {
          type: apiSourceItem.type,
          geo_id: apiSourceItem.identifier,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          last_viewed: apiSourceItem.last_viewed!
        };
        break;
      default:
        sourceItem = {
          type: apiSourceItem.type,
          id: parseInt(apiSourceItem.identifier, 10),
          last_viewed: apiSourceItem.last_viewed
        };
    }
    return sourceItem;
  }

  /**
   * Resolve a view from a view source. Return a fallback view, if the "last viewed" item source was null.
   *
   * @param lastViewSource The item source for the "last view" to resolve.
   */
  private resolveFromLastViewSource(lastViewSource: CountryRiskLastViewedSourceItem | null): Observable<CountryRiskView> {

    if (lastViewSource !== null) {
      let viewSource: Observable<CountryRiskView>;

      switch (lastViewSource.type) {
        case 'country':
        case 'site':
          viewSource = this.dynamicViewService.createDynamicView({ location: lastViewSource.geo_id });
          break;
        case 'index':
          viewSource = this.dynamicViewService.createDynamicView({ index: lastViewSource.id });
          break;
        default:
          viewSource = this.countryRiskViewsService.resolveExistingView(lastViewSource.id);
          break;
      }
      return viewSource;
    } else {
      return this.countryRiskViewsService.createNewView();
    }
  }

}
