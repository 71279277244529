import { FeatureToggleService } from '@grid-ui/common';
import { appendHotjarScript, appendGoogleTagManagerScript } from '@grid-ui/user-analytics';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { sentryConfig } from '../../sentry-config';

export function appInitializerFactory(
  featureToggleService: FeatureToggleService,
  sentry = Sentry
): () => Promise<any> {
  if (environment.appConfig.hotjarTrackingCode) {
    appendHotjarScript(environment.appConfig.hotjarTrackingCode);
  }
  if (environment.googleTagManagerId) {
    appendGoogleTagManagerScript(environment.googleTagManagerId);
  }
  if (environment.appConfig.sentryDsn) {
    sentry.init({
      release: '__mcDeployToken_sentryRelease__',
      dsn: environment.appConfig.sentryDsn,
      ...sentryConfig,
      environment: environment.environment,
      integrations: [
        new Sentry.Integrations.TryCatch({
          XMLHttpRequest: false,
          // setTimeout is needed to prevent duplicate errors being logged, ideally remove this if
          // sentry fic this issue https://github.com/getsentry/sentry-javascript/issues/2744
          setTimeout: false
        })
      ],
    });
  }
  return () =>
    featureToggleService.init().toPromise()
      .catch((error) => {
        console.error('An error occured during the Angular App initialization process: ', error);
        throw error;
      });

}
