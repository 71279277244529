import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '../providers';

@Injectable({
  providedIn: 'root'
})
export class BrowserStorageService {
  public constructor(
    @Inject(WINDOW) private readonly window: Window | any,
  ) { }

  public getSession<T>(key: string): T | null {
    if (this.window instanceof Window) {
      const data = this.window.sessionStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    }
    return null;
  }

  public setSession<T>(key: string, value: T): T | null {
    if (this.window instanceof Window) {
      this.window.sessionStorage.setItem(key, JSON.stringify(value));
      return value;
    }
    return null;
  }

  public getLocal<T>(key: string): T | null {
    if (this.window instanceof Window) {
      const data = this.window.localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    }
    return null;
  }

  public setLocal<T>(key: string, value: T): T | null {
    if (this.window instanceof Window) {
      this.window.localStorage.setItem(key, JSON.stringify(value));
      return value;
    }
    return null;
  }

  public removeLocal(key: string): void | null {
    if (this.window instanceof Window) {
      this.window.localStorage.removeItem(key);
    }
    return null;
  }

  public addToArray<T>(key: string, value: T, local: boolean = false): T[] | null {
    const sessions: T | null = local ? this.getLocal(key) : this.getSession(key);
    if (Array.isArray(sessions)) {
      return local ? this.setLocal(key, [...sessions, value]) : this.setSession(key, [...sessions, value]);
    }
    return local ? this.setLocal(key, [value]) : this.setSession(key, [value]) ;
  }

  public removeSession(key: string): void | null {
    if (this.window instanceof Window) {
      this.window.sessionStorage.removeItem(key);
    }
    return null;
  }

  public removeAllSessions(): void | null {
    if (this.window instanceof Window) {
      for (const key in window.sessionStorage) {
        if (Object.prototype.hasOwnProperty.call(this.window.sessionStorage, key)) {
          this.removeSession(key);
        }
      }
    }
    return null;
  }
}
