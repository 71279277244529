import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'thousandSuff'
})
export class ThousandSuffixesPipe implements PipeTransform {

  public constructor(
    private readonly decimalPipe: DecimalPipe
  ) {}

  public transform(input: any, args?: any): string | null {

    const suffixes = ['k', 'm', 'bn', 'tn', 'qrn', 'qtn'];

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 1000) {
      return this.decimalPipe.transform(input, '1.2-2');
    }

    const exp: number = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }
}
