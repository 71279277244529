import { SearchableItemIDType, SearchableItem } from './searchable-item.interface';

export enum SearchableDropdownActionTypes {
  CHANGE_SEARCHTERM = '[Searchable Dropdown] Change Searchterm',
  CHANGE_SELECTION = '[Searchable Dropdown] Change Selection',
  CLOSED = '[Searchable Dropdown] Dropdown Closed',
  OPENED = '[Searchable Dropdown] Dropdown Opened',
  RETRY_LOAD = '[Searchable Dropdown] Retry Load',
  TOUCHED = '[Searchable Dropdown] Dropdown Touched'
}

export class SearchableDropdownChangeSearchtermAction {
  readonly type = SearchableDropdownActionTypes.CHANGE_SEARCHTERM;
  constructor(public searchterm: string) { }
}

export class SearchableDropdownChangeSelectionAction<T extends SearchableItemIDType = SearchableItemIDType> {
  readonly type = SearchableDropdownActionTypes.CHANGE_SELECTION;
  constructor(public selection: SearchableItem<T> | null) { }
}

export class SearchableDropdownClosedAction {
  readonly type = SearchableDropdownActionTypes.CLOSED;
  constructor() { }
}

export class SearchableDropdownOpenedAction {
  readonly type = SearchableDropdownActionTypes.OPENED;
  constructor() { }
}

export class SearchableDropdownRetryLoadAction {
  readonly type = SearchableDropdownActionTypes.RETRY_LOAD;
  constructor() { }
}

export class SearchableDropdownTouchedAction {
  readonly type = SearchableDropdownActionTypes.TOUCHED;
  constructor() { }
}

export type SearchableDropdownActionsUnion<T extends SearchableItemIDType = SearchableItemIDType> =
  SearchableDropdownChangeSearchtermAction
  | SearchableDropdownClosedAction
  | SearchableDropdownOpenedAction
  | SearchableDropdownChangeSelectionAction<T>
  | SearchableDropdownRetryLoadAction
  | SearchableDropdownTouchedAction;
