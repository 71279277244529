import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalModuleAnnouncementEffects } from './+state/global-module-announcement.effects';
import { GlobalModuleAnnouncementFacade } from './+state/global-module-announcement.facade';
import { GLOBAL_MODULE_ANNOUNCEMENT_FEATURE_KEY, globalModuleAnnouncementReducerFn } from './+state/global-module-announcement.reducer';
import { AnnouncementHttpService } from './api-service/announcements-http.service';


const STORE_MODULES = [
  StoreModule.forFeature(GLOBAL_MODULE_ANNOUNCEMENT_FEATURE_KEY, globalModuleAnnouncementReducerFn),
  EffectsModule.forFeature([GlobalModuleAnnouncementEffects]),
];

const FACADE = [GlobalModuleAnnouncementFacade];

const HTTP_API_SERVICES = [AnnouncementHttpService];

@NgModule({
  imports: [
    CommonModule,
    STORE_MODULES,
  ],
  providers: [FACADE, HTTP_API_SERVICES],
})

export class DataAccessGlobalModuleAnnouncementModule {
  static forRoot(): ModuleWithProviders<DataAccessGlobalModuleAnnouncementModule> {
    return {
      ngModule: DataAccessGlobalModuleAnnouncementModule,
      providers: [...FACADE]
    };
  }
}