import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  public transform(text: string | null, maxLen: number = 40, appendEllipsis: boolean = true): string {
    if (text !== null) {
      const len: number = text.length;
      if (len > maxLen) {
        const truncatedText = text.substr(0, maxLen - 1);
        text = appendEllipsis ? truncatedText + '…' : truncatedText;
      }
      return text;
    } else {
      return '';
    }
  }

}
