import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import {
  DataWizardExtractErrorType,
  DataWizardModalActionsEnum,
  DataWizardModalOptions,
  DataWizardModuleType
} from '../../../shared-models';
import {
  DataWizardExtractConfirmCancelModalComponent,
  DataWizardExtractErrorModalComponent
} from '../../../shared-ui-components/data-wizard-extract-modals';

@Injectable()
export class DataWizardRootModalManagerService {

  private ngbModalRef: NgbModalRef;

  private readonly defaultModalOptions: NgbModalOptions = {
    centered: true,
    backdrop: 'static',
    keyboard: true,
    size: 'lg',
  };

  constructor(
    private readonly ngBoostrapModalService: NgbModal,
  ) { }


  public closeCurrentModal(): void {
    if (this.ngbModalRef) {
      this.ngbModalRef.close();
    }
  }

  public openConfirmCancelModal(module: DataWizardModuleType): Observable<DataWizardModalActionsEnum> {
    return this.openModal(DataWizardExtractConfirmCancelModalComponent, { module });
  }

  public openErrorModal(errorType: DataWizardExtractErrorType): Observable<DataWizardModalActionsEnum> {
    return this.openModal(DataWizardExtractErrorModalComponent, { errorType });
  }

  public setModalInputValue(input: string, value: any): void {
    if (this.ngbModalRef) {
      this.ngbModalRef.componentInstance[input] = value;
    }
  }

  private openModal(content: any, options: DataWizardModalOptions): Observable<DataWizardModalActionsEnum> {
    this.closeCurrentModal();
    this.ngbModalRef = this.ngBoostrapModalService.open(content, this.defaultModalOptions);

    this.ngbModalRef.componentInstance.options = options;
    return this.ngbModalRef.componentInstance.modalAction;
  }
}
