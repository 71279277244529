import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorHandlerService } from '@grid-ui/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { DocumentLibraryService } from '../../../api-services';
import { LoadingRequestStatus, ErrorComponentData } from '../../../shared-models';
import { WindowUtilitiesService, ClientDimensionsService } from '../../../shared-utilities/window-services';
import { ServiceOfferingCard } from '../../models';
import { NavService, SvgService } from '../../../core/services';

@Component({
  selector: 'mc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  /**
   * The services offering cards to be displayed according to the user
   * permissions & external tools
   **/
  public error: ErrorComponentData | null = null;
  public heading = 'GlobalRisk';
  public loadingStatus: LoadingRequestStatus = LoadingRequestStatus.loading;
  public permittedServiceOfferingCards: ServiceOfferingCard[] = [];
  public readonly RequestStatus = LoadingRequestStatus;
  public routerLink = 'subscriptions/details';
  public routeText = 'click here';
  public splashPageHeading = 'Dashboard';
  public subHeading = 'Maplecroft Account: For more information about your subscription';
  public cardContainerWidth: number;

  public indexCatalogueDownloadURL: string;

  private permissionsSubscription: Subscription;
  private windowSupported = false;

  constructor(
    private readonly windowUtilitiesService: WindowUtilitiesService,
    private readonly clientDimensionsService: ClientDimensionsService,
    private readonly navService: NavService,
    private readonly httpErrorHandlerService: HttpErrorHandlerService,
    private readonly svgService: SvgService,
    private readonly documentLibraryService: DocumentLibraryService,
    private readonly router: Router
  ) { }

  @HostListener('window:resize')
  public onResize(): void {
    this.cardContainerWidth = this.clientDimensionsService.getClientWidth();
  }

  public ngOnInit(): void {
    this.indexCatalogueDownloadURL = this.documentLibraryService.getIndexCatalogueDownloadURL();
    this.cardContainerWidth = this.clientDimensionsService.getClientWidth();
    this.windowSupported = this.windowUtilitiesService.isSupported();
    this.windowUtilitiesService.scrollToTop();
    this.setServiceOfferings();
  }

  public ngOnDestroy(): void {
    if (this.permissionsSubscription && !this.permissionsSubscription.closed) {
      this.permissionsSubscription.unsubscribe();
    }
  }

  public reloadApp(): void {
    if (this.windowSupported) {
      this.windowUtilitiesService.reloadApp();
    }
  }

  public getSvg(svgId: string) {
    return this.svgService.getNavigationIcon(svgId);
  }

  public navigate(offeringOrLink: ServiceOfferingCard | string): void {
    if (typeof offeringOrLink === 'string') {
      this.router.navigate([offeringOrLink]);
      return;
    }
    const { open_in_window, link } = offeringOrLink as ServiceOfferingCard;
    if (open_in_window) {
      this.windowUtilitiesService.openNewTab(link as string);
    } else {
      this.router.navigate([link]);
    }
  }

  public setServiceOfferings(): void {
    this.resetLoadingState();

    this.navService
      .getPermissions()
      .then((permissions: ServiceOfferingCard[]) => {
        this.permittedServiceOfferingCards = permissions;
        this.loadingStatus = LoadingRequestStatus.loaded;
      })
      .catch((err: HttpErrorResponse) => {
        // 403s from WhoAmIService are dealt with by navigating to the login page
        // but there can be a delay that allows this error handle to execute.
        // Here we ensure the loading icon is displayed until the navigation happens,
        // instead of showing an error.
        if (err.status !== 403) {
          this.error = this.httpErrorHandlerService.mapHttpErrorResponseToUIComponent(
            err,
            'Try Again',
            this.windowSupported ? 'Reload App' : null,
            'full'
          );
          this.loadingStatus = LoadingRequestStatus.error;
        }
      });
  }

  private resetLoadingState(): void {
    this.loadingStatus = LoadingRequestStatus.loading;
    this.permittedServiceOfferingCards = [];
    this.error = null;
  }
}
