import { CountryRiskView } from '@grid-ui/common';
import { CommodityRiskView } from '../risk-view';
import { CountryRiskIndex } from '../country-risk-index';
import { ItemInformationListingItemDatatype } from '../item-information-listing';

/**
 * Typeguard to determine whether the specified data for an Item Information Listing
 * supports unique identification by "id" property.
 *
 * @param data An Item Information Listing item.
 */
export function isIdDatatype(data: ItemInformationListingItemDatatype): data is CountryRiskView | CountryRiskIndex | CommodityRiskView {
  return (data as any).id !== undefined;
}
