import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '../../../api';

import { AskAnAnalystRequest } from '../models';


@Injectable({
  providedIn: 'root'
})
export class AskAnAnalystService {

  private resourceConfig: NonPaginatedResourceConfig;

  public constructor(
    private readonly http: PortalHttpClient
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.askAnAnalyst._configuration;
  }

  public request(request: AskAnAnalystRequest): Observable<AskAnAnalystRequest> {
    return this.http.post<AskAnAnalystRequest>(
      this.resourceConfig, { body: request }
    );
  }
}
