import {
  AnalyseFlatTreeNode
} from '../../../shared-models';

import {
  ApiAnalyseFlatTreeNode,
  ApiAnalyseFlatTreeReferenceNode
} from '../../models';

export function mapAnalyseFlatTreeNodeFromApiToApp(apiNode: ApiAnalyseFlatTreeNode, nodes: ApiAnalyseFlatTreeNode[]): AnalyseFlatTreeNode {
  if (apiNode.type === 'group') {
    const is_subnational = nodes
      .filter(node => node.parent === apiNode.id)
      .some((child: ApiAnalyseFlatTreeReferenceNode) => child.is_subnational);

    return {
      ...apiNode,
      parent: apiNode.parent !== null ? `${apiNode.parent}` : null,
      id: `${apiNode.id}`,
      is_subnational,
    };
  }

  return {
    ...apiNode,
    parent: `${apiNode.parent}`
  };
}
