<div [ngClass]="['citation-popover arrow-box', data.placement]" *ngIf="data.show" [ngStyle]="data.location">
  <div class="citation-header">
    <h3 class="citation-title">{{ get_header(data.type) }}</h3>
  </div>
  <div class="citation-default">
    <ul>
      <li *ngIf="data.interviewee">
        <span class="field field-interviewee">Interviewee</span>
        <span class="item item-interviewee">{{ data.interviewee }}</span>
      </li>
      <li *ngIf="data.jobTitle">
        <span class="field field-job-title">Job title</span>
        <span class="item item-job-title">{{ data.jobTitle }}</span>
      </li>
      <li *ngIf="data.interviewType">
        <span class="field field-interview-type">Interview type</span>
        <span class="item item-interview-type">{{ data.interviewType }}</span>
      </li>
      <li *ngIf="data.title">
        <span class="field field-title">Title</span>
        <span class="item item-title">{{ data.title }}</span>
      </li>
      <li *ngIf="data.source">
        <span class="field field-source">Source</span>
        <span class="item item-source">{{ data.source }}</span>
      </li>
      <li *ngIf="data.url">
        <span class="field field-source">URL</span>
        <a [href]="data.url" target="_blank" rel="noopener noreferrer" class="item item-source">{{ data.url | truncate }}</a>
      </li>
      <li *ngIf="data.date">
        <span class="field field-date">Date</span>
        <span class="item item-date">{{ data.date }}</span>
      </li>
      <li *ngIf="data.author">
        <span class="field field-author">Author</span>
        <span class="item item-author">{{ data.author }}</span>
      </li>
    </ul>
  </div>
</div>
