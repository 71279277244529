import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, AgPromise, IFilterParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RiskView } from '../../../shared-models';

type RiskViewStub = RiskView | {name: string; id: number};

@Component({
  selector: 'grid-ui-maplecroft-risk-view-filter',
  templateUrl: './maplecroft-risk-view-filter.component.html',
  styleUrls: ['./maplecroft-risk-view-filter.component.scss']
})
export class MaplecroftRiskViewFilterComponent implements IFilterAngularComp {
  public params!: IFilterParams;
  public selectedFilterableViews: RiskViewStub[] = [];
  public allFilterableViews: RiskViewStub[] = [];
  public filterForm = new UntypedFormGroup({
    filterSearch: new UntypedFormControl(''),
    allViews: new UntypedFormControl(true),
    views: new UntypedFormArray([])
  });

  public get displayedCheckboxes(): RiskViewStub[] {
    return this.allFilterableViews.filter(view => view.name.toLowerCase().includes(this.filterForm.get('filterSearch')?.value.toLowerCase()));
  }

  public viewIndex(view: RiskViewStub): string {
    return this.allFilterableViews.findIndex(x => x.id === view.id).toString();
  }

  public constructor(private readonly formBuilder: UntypedFormBuilder) { }

  public isFilterActive(): boolean {
    return this.selectedFilterableViews.length < this.allFilterableViews.length;
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    const maplecroftRiskViewsThisRow: RiskViewStub[] = params.data.maplecroftRiskViews;
    // Show the row if its list of views includes at least one of the selectedFilterableViews
    let containsASelectedView = false;
    this.selectedFilterableViews.forEach((selectedView) => {
      containsASelectedView = containsASelectedView || maplecroftRiskViewsThisRow.some((viewInThisRow) => viewInThisRow.id === selectedView.id);
    });

    return containsASelectedView;
  }

  public getModel() {
    return this.filterForm.value;
  }

  public setModel(model: any): void | AgPromise<void> {
    this.filterForm.setValue(model);
  }

  public agInit(params: any): void {
    this.params = params;
    this.allFilterableViews = params.values();
    const viewsFormArray: UntypedFormArray = new UntypedFormArray([]);
    this.allFilterableViews.forEach((x, index) => {
      viewsFormArray.setControl(index, new UntypedFormControl(true));
    });
    this.filterForm = this.formBuilder.group({
      filterSearch: new UntypedFormControl(''),
      allViews: new UntypedFormControl(true),
      views: viewsFormArray
    });

    // When changing allViews set all to false or true
    this.filterForm.get('allViews')?.valueChanges.subscribe(allViews => {
      if (allViews === true) {
        this.filterForm.get('views')?.setValue(new Array(this.allFilterableViews.length).fill(true));
      } else {
        this.filterForm.get('views')?.setValue(new Array(this.allFilterableViews.length).fill(false));
      }
    });

    // When any filter changes
    this.filterForm.valueChanges.subscribe(x => {
      this.selectedFilterableViews = this.allFilterableViews.filter((riskView, index) => x.views[index] === true);
      this.params.filterChangedCallback();
    });
  }
}
