import { NgModule } from '@angular/core';
import { RadioInputDirective } from './radio-input.directive';
import { RadioLabelDirective } from './radio-label.directive';

@NgModule({
  declarations: [
    RadioLabelDirective,
    RadioInputDirective
  ],
  exports: [
    RadioLabelDirective,
    RadioInputDirective
  ]
})
export class RadioGroupModule {}
