import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

/**
 * The full screen image 'thin' component to be used in the BodyItemImageComponent.
 * this component does not have an input property because it will be loaded
 * externally from ImageComponent
 *
 * @export
 * @class BodyItemExpandedImageComponent
 * @implements OnInit
 */
@Component({
  selector: 'grid-ui-body-item-expanded-image',
  templateUrl: './body-item-expanded-image.component.html',
  styleUrls: ['./body-item-expanded-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemExpandedImageComponent {

  @Input() public alt: string | undefined = '';
  @Input() public attribution = '';
  @Input() public caption = '';
  @Input() public subtitle = '';
  @Input() public title = '';
  @Input() public url = '';

  @Output() public dismiss = new EventEmitter();

  @ViewChild('offscreenContainerRef', { read: ElementRef }) public offscreenContainerRef: ElementRef<HTMLDivElement> | null = null;
  @ViewChild('modalHeaderRef', { read: ElementRef }) public modalHeaderRef: ElementRef<HTMLDivElement> | null = null;
  @ViewChild('subTitleRef', { read: ElementRef }) public subTitleRef: ElementRef<HTMLDivElement> | null = null;
  @ViewChild('modalFooterRef', { read: ElementRef }) public modalFooterRef: ElementRef<HTMLDivElement> | null = null;

  public copyrightText: string;

  public constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
    const currentYear = new Date().getFullYear();
    this.copyrightText = `© Verisk Maplecroft ${currentYear}`;
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  public downloadImage(): void {
    // create container
    const captureElement = this.offscreenContainerRef?.nativeElement;
    if (!captureElement) {
      return;
    }

    // append header title
    const captureHeader = this.modalHeaderRef?.nativeElement.cloneNode(true) as HTMLDivElement;
    captureElement.appendChild(captureHeader);

    // remove buttons from header
    const captureHeaderButtons = captureHeader.querySelectorAll('span');
    for (let i = 0; i < captureHeaderButtons.length; ++i) {
      captureHeader.removeChild(captureHeaderButtons[i]);
    }

    // append SubHeader title
    const captureSubHeader = this.subTitleRef?.nativeElement.cloneNode(true) as HTMLDivElement;
    captureElement.appendChild(captureSubHeader);

    // add full sized image
    const img = document.createElement('img');
    img.src = this.url;
    captureElement.appendChild(img);

    // append footer
    const captureFooter = this.modalFooterRef?.nativeElement.cloneNode(true) as HTMLDivElement;
    captureElement.appendChild(captureFooter);

    // make header and footer full width of the image
    const imgWidth = img.clientWidth + 'px';
    captureHeader.style.position = 'relative';
    captureHeader.style.width = imgWidth;
    captureHeader.style.top = '0';
    captureHeader.style.left = '0';

    captureSubHeader.style.position = 'relative';
    captureSubHeader.style.width = imgWidth;
    captureSubHeader.style.top = '0';
    captureSubHeader.style.left = '0';

    captureFooter.style.width = imgWidth;
    captureFooter.style.position = 'relative';
    captureFooter.style.top = '0';
    captureFooter.style.left = '0';

    html2canvas(captureElement, {
      logging: false
    })
      .then(canvas => {
        canvas.toBlob(blob => {
          if (blob) {
            const fileName = this.subtitle ? `${this.subtitle}` : this.title;
            saveAs(blob, `${fileName}.png`);
          }
          if (this.offscreenContainerRef) {
            this.offscreenContainerRef.nativeElement.innerHTML = '';
          }
        });
      })
      .catch(err => console.error(err));
  }
}
