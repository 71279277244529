import * as Sentry from '@sentry/browser';
import { Injectable } from '@angular/core';
import { WhoAmIService } from '../../../api-services';
import { extractError } from '../../../sentry-config';

export enum LogLevel {
  Debug = 'debug',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export type GridErrorType = 'PDF Download';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private readonly whoAmIService: WhoAmIService
  ) { }

  public async log(
    message: string,
    config?: {
      level?: LogLevel;
      addUserInfo?: boolean;
      additionalInfo?: { [key: string]: any };
      gridErrorType?: GridErrorType;
    }
  ) {
    const { level = LogLevel.Info, addUserInfo = true, additionalInfo } = config || { additionalInfo: undefined };
    const tags = config && config.gridErrorType ? { gridErrorType: config.gridErrorType } : undefined;

    let user: { account: string; user: string } | undefined;
    if (addUserInfo) {
      const userDetails = await this.whoAmIService.getOrganisation().toPromise();
      user = { account: userDetails.accountIdentifier, user: userDetails.userIdentifier };
    }

    this.captureEvent(
      { message, level: Sentry.Severity.fromString(level), contexts: additionalInfo, user, tags }
    );
  }

  /**
   * Manually log a caught error to Sentry, usually you will not require this as we prefer errors to bubble up to the global error handler
   * however in some cases (e.g. rxjs streams / ngrx) it is necessary to catch and swallow errors to prevent breaking the application
   * where an error may not be critical and we wish to continue
   * @param error the caught error object
   */
  public logUnexpected(error: any) {
    const extractedError = extractError(error);
    Sentry.captureException(extractedError);
  }

  private captureEvent(event: Sentry.Event): void {
    Sentry.captureEvent(event);
  }

}
