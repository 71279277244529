import { isPlatformBrowser } from '@angular/common';
import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken('WindowToken');

export abstract class WindowRef {

  public get nativeWindow(): Window | any {
    throw new Error('Do not use abstract class WindowRef for instantiation.');
  }

}

export class BrowserWindowRef extends WindowRef {

  constructor() {
    super();
  }

  public get nativeWindow(): Window | any {
    return window;
  }

}

export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: any): Window | any {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}
