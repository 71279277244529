import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AdditionalCountriesOfferBannerComponent } from './additional-countries-offer-banner';
import { AdditionalCountriesOfferFooterComponent } from './additional-countries-offer-footer';

@NgModule({
  declarations: [
    AdditionalCountriesOfferBannerComponent,
    AdditionalCountriesOfferFooterComponent,

  ],
  exports: [
    AdditionalCountriesOfferBannerComponent,
    AdditionalCountriesOfferFooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class AdditionalCountriesOfferModule { }
