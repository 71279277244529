import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { ErrorComponentSize } from '../../shared-models';

@Component({
  selector: 'mc-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {

  @Input() contactEmail: string | undefined = 'support@maplecroft.com';
  @Input() errorCode: string | number | undefined | null;
  @Input() errorMessage: string | undefined | null;
  @Input() errorPrimaryAction: string | undefined | null;
  @Input() errorSecondaryAction: string | undefined | null;
  @Input() showContact: boolean | undefined = false;
  @Input() size: ErrorComponentSize = 'full';

  @Output() primaryActionClick = new EventEmitter();
  @Output() secondaryActionClick = new EventEmitter();

  public triggerPrimaryAction($event: Event): void {
    $event.stopPropagation();
    this.primaryActionClick.emit();
  }
  public triggerSecondaryAction($event: Event): void {
    $event.stopPropagation();
    this.secondaryActionClick.emit();
  }
}
