import { AnalyseDataTableTreeNodeColumn } from './analyse-data-table-tree-node-column.interface';
import { AnalyseDataTableAllHistoricalScoresColumn } from './analyse-data-table-all-historical-scores-column.interface';
import { AnalyseDataTableColumn } from './analyse-data-table-column.type';

/**
 * Typeguard identifying a data table column for _non-historical_ table mode
 *
 * @param column Data table column
 */
export function isAnalyseDataTableTreeNodeColumn(
  column: AnalyseDataTableColumn
): column is AnalyseDataTableTreeNodeColumn {
  return (<any>column).type !== undefined;
}

/**
 * Typeguard identifying a data table column for _all historical scores_ table mode
 *
 * @param column Data table column
 */
export function isAnalyseDataTableAllHistoricalScoresColumn(
  column: AnalyseDataTableColumn
): column is AnalyseDataTableAllHistoricalScoresColumn {
  return (<any>column).active !== undefined;
}
