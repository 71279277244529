import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { FeatureToggleService } from '../services/feature-toggle.service';

@Directive({
  selector: '[gridUiFeatureToggle]'
})
export class FeatureToggleDirective implements OnInit {

  @Input() public gridUiFeatureToggle: string | undefined;

  public constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit(): void {
    if (this.gridUiFeatureToggle && this.featureToggleService.getFeatureToggles()[this.gridUiFeatureToggle]) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
