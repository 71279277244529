import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  API_SERVICES_CONFIG,
  CommodityRiskResourcesConfig,
  PaginationService,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  ApiResponseWithQueryContext,
  QueryParams
} from '@grid-ui/common';

import {
  CommodityRiskProfileCollection,
  CommodityRiskProfileDetail,
  CommodityRiskProfilesParameters,
  WrittenContentContext
} from '../../../shared-models';
import { ApiWrittenContentContext } from '../../models';

import { ApiCommodityRiskProfilesResult, CommodityRiskProfileRecentCollection } from '../models';

@Injectable()
export class CommodityRiskProfilesService {

  private commodityRiskResourceConfig: CommodityRiskResourcesConfig;

  constructor(
    private readonly http: PortalHttpClient,
    private readonly paginationService: PaginationService,
  ) {
    this.commodityRiskResourceConfig = API_SERVICES_CONFIG.v3.commodityRisk;
  }

  public getProfiles(
    profilesParameters: CommodityRiskProfilesParameters | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CommodityRiskProfileCollection> {
    const queryParams: QueryParams = profilesParameters
      ? profilesParameters
      : {};
    return this.http.getPaginated<ApiCommodityRiskProfilesResult>(
      this.commodityRiskResourceConfig.content.profiles._configuration,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map((apiResponseContext: ApiResponseWithQueryContext<ApiCommodityRiskProfilesResult>) => ({
        total: apiResponseContext.response.total,
        results: apiResponseContext.response.results,
        paginationContext: this.paginationService.getNewPaginationContext(
          apiResponseContext.response.links,
          apiResponseContext.queryParams
        )
      }))
    );
  }

  public getProfileDetails(
    id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CommodityRiskProfileDetail> {
    return this.http.get<CommodityRiskProfileDetail>(
      this.commodityRiskResourceConfig.content.profiles.detail._configuration,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  public getProfileDetailsContext(
    id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<WrittenContentContext> {
    return this.http.get<ApiWrittenContentContext>(
      this.commodityRiskResourceConfig.content.profiles.detail.context._configuration,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  public getProfileDetailUrlSegmentPath(): string {
    return this.commodityRiskResourceConfig.content.profiles.detail._configuration.path;
  }

  /**
   * Get recently opened profiles
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRecentlyOpenedProfiles(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CommodityRiskProfileRecentCollection> {
    return this.http.get<ApiCommodityRiskProfilesResult>(
      this.commodityRiskResourceConfig.content.profiles.recent._configuration,
      options
    ).pipe(
      map((profilesResult: ApiCommodityRiskProfilesResult) => ({ results: profilesResult.results }))
    );
  }
}
