import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OutlookItemValue } from '../../../shared-models';

@Component({
  selector: 'grid-ui-body-item-outlook',
  templateUrl: './body-item-outlook.component.html',
  styleUrls: ['./body-item-outlook.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemOutlookComponent {

  @Input() public value: OutlookItemValue | null = null;

}
