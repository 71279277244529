/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { AnalyticsUserDetails, IGtmConfigProvider } from '@grid-ui/user-analytics';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments';
import { selectWhoAmI } from '../../../core';

@Injectable()
export class GtmConfigProviderService implements IGtmConfigProvider {
  private readonly store = inject(Store);

  public readonly userDetails$: Observable<AnalyticsUserDetails> = this.store
    .select(selectWhoAmI)
    .pipe(
      filter((whoAmI) => !!whoAmI),
      map((whoAmI) => {
        const result: AnalyticsUserDetails = {
          accountId: whoAmI!.account_uuid,
          internalUser: whoAmI!.is_internal,
          userId: whoAmI!.user_uuid,
        };

        return result;
      })
    );

  public readonly gtmEnabled = !!environment.googleTagManagerId;
}
