import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  API_SERVICES_CONFIG,
  PaginatedResourceConfig,
  PageQueryParameter,
  HttpGETCustomOptions,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  PaginationService
} from '@grid-ui/common';

import { AnnouncementResponse } from '../../../shared-models';
import { ApiAnnouncementResponse } from '../models';

@Injectable()
export class AnnouncementService {
  private countryRiskConfiguration: PaginatedResourceConfig;
  private commodityRiskConfiguration: PaginatedResourceConfig;

  constructor(
    private readonly httpClient: PortalHttpClient,
    private readonly paginationService: PaginationService
  ) {
    this.countryRiskConfiguration = API_SERVICES_CONFIG.v3.countryRisk.announcements._configuration;
    this.commodityRiskConfiguration = API_SERVICES_CONFIG.v3.commodityRisk.announcements._configuration;
  }

  public getAnnouncements(
    module: 'country-risk' | 'commodity-risk',
    queryParams: PageQueryParameter,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnnouncementResponse> {
    const configuration = module === 'country-risk' ? this.countryRiskConfiguration : this.commodityRiskConfiguration;
    return this.httpClient.getPaginated<ApiAnnouncementResponse>(
      configuration,
      {
        ...options,
        queryParams
      }).pipe(
      map(apiResponseContext => {
        const response: AnnouncementResponse = {
          total: apiResponseContext.response.total,
          results: apiResponseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(
            apiResponseContext.response.links,
            apiResponseContext.queryParams
          )
        };
        return response;
      })
    );
  }
}
