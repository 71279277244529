<div *ngIf="showSideNavigation" class="mc-side-area" [class.mc-sticky-side-area]="stickiness.sticky"
  [class.mc-notification-bar-open]="isNotificationBarOpen" mcStickyTopElement [stickyAt]="45"
  (stickinessChange)="onStickinessChange($event)">

  <mc-app-side-area-links
    [navItems]="permittedNavItems"
    [currentModule]="currentModule"
  >
  </mc-app-side-area-links>
</div>

<div *ngIf="stickiness.sticky" class="mc-side-nav-placeholder"></div>
