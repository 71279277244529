import { ApiLocationAttributeKeyMapping } from '../models';

export const LOCATION_ATTRIBUTE_KEY_MAPPING: ApiLocationAttributeKeyMapping[] = [
  { mappedKey: 'region', unmappedKey: 'supranational_region' },
  { mappedKey: 'name', unmappedKey: 'raw_name' },
];

export const RESERVED_LOCATION_ATTRIBUTE_KEY_MAPPING: ApiLocationAttributeKeyMapping[] = [
  ...LOCATION_ATTRIBUTE_KEY_MAPPING,
  { mappedKey: 'national_region', unmappedKey: 'region' },
];
