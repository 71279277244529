<ng-container *ngIf="contentItem && validContent">
    <div *ngIf="contentWidths" class="row">
      <div [class]="contentWidths[i]"
      *ngFor="let bodyItem of contentItem!.value.blocks;let i = index">
        <grid-ui-body-item
          [bodyItem]="bodyItem"
          (writtenContentLinkActionEmit)="emitWrittenContentLinkAction($event)"
        >
        </grid-ui-body-item>
      </div>
    </div>
</ng-container>
