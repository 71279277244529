import { DebugElement } from '@angular/core';

/**
 * Helpers to test click events in unit tests.
 * Refer to https://angular.io/guide/testing#clicking for examples and usage
 *
 * Sample usage
 * mockClick(heroDe); // click helper with DebugElement
 * mockClick(heroEl); // click helper with native element
*/

/** Button events to pass to `DebugElement.triggerEventHandler` for RouterLink event handler */
export const MockButtonClickEvents = {
  left: new MouseEvent('click', { button: 0 }),
  right: new MouseEvent('click', { button: 2 })
};

/** Simulate element click. Defaults to mouse left-button click event. */
export function mockClick(el: DebugElement | HTMLElement, eventObj: any = MockButtonClickEvents.left): void {
  if (el instanceof HTMLElement) {
    el.click();
  } else {
    el.triggerEventHandler('click', eventObj);
  }
}
