import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICES_CONFIG, CountryRiskView, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { ApiCountryRiskConfiguration } from '../../models';

import { DynamicViewPayload, isIndexDynamicViewPayload } from '../models';
import { mapRiskViewFromApiToApp } from '../../utils';

@Injectable()
export class CountryRiskDynamicViewsService {

  private locationDynamicConfigResourceConfig: NonPaginatedResourceConfig;
  private indexDynamicConfigResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.locationDynamicConfigResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.locations.dynamicConfigurations._configuration;
    this.indexDynamicConfigResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.indices.dynamicConfigurations._configuration;
  }

  /**
   * Create a dynamic view (configuration) for use in Country Risk/Analyse, based on the specified location (country or site) or risk index.
   *
   * IMPORTANT: The resulting dynamic view will NOT appear in the risk view listing returned by the CountryRiskViewsService.
   * It is however individually queriable.
   *
   * @param payload An object containing a location (country or site) or risk index which will be used to dynamically
   * create a view for use in Country Risk Analyse.
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public createDynamicView(payload: DynamicViewPayload, customRetryAttempts?: number): Observable<CountryRiskView> {
    let resourceConfig: NonPaginatedResourceConfig;
    let id: string;
    if (isIndexDynamicViewPayload(payload)) {
      resourceConfig = this.indexDynamicConfigResourceConfig;
      id = payload.index;
    } else {
      resourceConfig = this.locationDynamicConfigResourceConfig;
      id = payload.location;
    }
    return this.http.post<ApiCountryRiskConfiguration>(
      resourceConfig,
      {
        body: { id },
        retryOptions: { customRetryAttempts }
      }
    ).pipe(map(mapRiskViewFromApiToApp));
  }

}
