export enum ServiceOfferingText {
  CommodityRisk = 'Commodity Risk',
  CountryRisk = 'Country Risk',
  DataWizard = 'Data Wizard',
  DocumentLibrary = 'Document Library',
  DynamicIndices = 'Dynamic Indices',
  EmailAlerts = 'Email Alerts',
  Forecasting = 'Forecasting',
  Industry = 'Industry Risk',
  Learn = 'Learn',
  MaplecroftNewsAndEvents = 'Maplecroft News and Events'
}
