export interface AnalyseLeafTypeAheadItem {
  /** Name of typeahead list item for display purposes */
  name: string;
  /** Unique ID of typeahead list item */
  id: string;
  /** Optional subnational of typeahead list item*/
  is_subnational?: boolean;
}

export interface AnalyseLeafTypeAheadFooter {
  /** A message string to be passed as an input to the Type Ahead Dropdown Footer */
  footerMessage: string;
}

export type AnalyseLeafTypeAheadRow = AnalyseLeafTypeAheadItem | AnalyseLeafTypeAheadFooter | null;

export interface AnalyseLeafTypeAheadGroup {
  name: string;
  items: AnalyseLeafTypeAheadRow[];
}

export function isAnalyseLeafTypeAheadItem(
  row: AnalyseLeafTypeAheadRow
): row is AnalyseLeafTypeAheadItem {
  return row !== null && (<AnalyseLeafTypeAheadItem>row).id !== undefined;
}
