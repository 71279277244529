import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as R from 'ramda';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  API_SERVICES_CONFIG,
  CommodityRiskResourcesConfig,
  HttpGETCustomOptions,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS
} from '@grid-ui/common';

import { CommodityRiskView } from '../../../shared-models';

import { ApiCommodityRiskConfigurationCollection } from '../../models';

import { mapRiskViewFromApiToApp } from '../../utils';


import { CommodityRiskConfigurationsQueryParams } from '../models';


@Injectable()
/**
 * Service for accessing the Country Risk Configurations (Views) API
 */
export class CommodityRiskViewsService {

  private commodityRiskResourceConfig: CommodityRiskResourcesConfig;

  /** Ignored view IDs */
  private ignoreIDs: number[] = [];

  constructor(
    private readonly http: PortalHttpClient
  ) {
    this.commodityRiskResourceConfig = API_SERVICES_CONFIG.v3.commodityRisk;

  }

  /**
   * A convenience method to get a list of all country risk views (configurations) which where generated by or shared with the user,
   * excluding Verisk Maplecroft reference views.
   *
   * @param creator An optional string specifying the email address of the creator of the views to include in the results set. Omitting
   * the parameter or setting it to null, will not filter the results by creator.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getMyRiskViews(
    creator: string | null = null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CommodityRiskView[]> {
    const queryParams: CommodityRiskConfigurationsQueryParams = { reference: false };
    if (creator !== null) {
      queryParams.creator = creator;
    }
    return this.getRiskViews(queryParams, options);
  }

  /**
   * A convenience method to get a list of Verisk Maplecroft reference country risk views (configurations) which the user is entitled to.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getVeriskMaplecroftRiskViews(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<CommodityRiskView[]> {

    return this.getRiskViews({ reference: true }, options);
  }

  /**
   * Get a list of all country risk views (configurations) which a user is entitled to and meet the specified criteria.
   *
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all risk views within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskViews(
    queryParams: CommodityRiskConfigurationsQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CommodityRiskView[]> {
    // TODO: Old "naive" caching logic removed, caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiCommodityRiskConfigurationCollection>(
      this.commodityRiskResourceConfig.configurations._configuration,
      {
        ...options,
        queryParams
      },
    ).pipe(
      map(configCollection => configCollection.results.map(
        mapRiskViewFromApiToApp
      ))
    );
  }

  /**
   * Map a simple query parameter object to a HttpParams object for use
   * with Angular's httpClient
   *
   * @private
   * @param queryParams An optional object with query parameters.
   */
  private mapQueryParams(
    queryParams: CommodityRiskConfigurationsQueryParams
  ): HttpParams {
    let params = new HttpParams();
    if (queryParams.reference !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      params = params.append('reference', queryParams.reference!.toString());
    }
    if (queryParams.creator) {
      params = params.append('creator', queryParams.creator);
    }
    return params;
  }

  private getFirstValidView(
    views: CommodityRiskView[],
    viewResolver: (viewId: number | null) => Observable<CommodityRiskView | null> = this.getViewById(this)
  ): Observable<CommodityRiskView | null> {
    if (views.length > 0) {
      const [firstView, ] = views;
      return this.isValidView(firstView.id, viewResolver).pipe(switchMap(isValid => {
        if (isValid) {
          return of(firstView);
        }
        return this.getFirstValidView(R.tail(views), viewResolver);
      }));
    }
    return of(null);
  }

  private isValidView(
    viewId: number | null,
    viewResolver: (viewId: number | null) => Observable<CommodityRiskView | null> = this.getViewById(this)
  ): Observable<boolean> {
    return viewResolver(viewId).pipe(map(view => view !== null));
  }

  private getViewById = (self: CommodityRiskViewsService) => (viewId: number | null) => {
    if (viewId === null) {
      return of(null);
    }
    return self.http.get<CommodityRiskView>(
      this.commodityRiskResourceConfig.configurations.detail._configuration,
      { queryParams: { viewId } }
    ).pipe(map(view => view.id ? view : null), catchError(err => of(null)));
  };
}
