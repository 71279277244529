<div class="ag-set-filter">
  <div ref="eFilterLoading" class="ag-filter-loading ag-hidden">Loading...</div>
  <form [formGroup]="filterForm">
    <!--AG-INPUT-TEXT-FIELD-->
    <div
      role="presentation"
      class="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field"
      ref="eMiniFilter"
    >
      <div
        ref="eLabel"
        class="ag-input-field-label ag-label ag-hidden ag-text-field-label"
        role="presentation"
      ></div>
      <div
        ref="eWrapper"
        class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
        role="presentation"
      >
        <input
          formControlName="filterSearch"
          ref="eInput"
          class="ag-input-field-input ag-text-field-input"
          type="text"
          id="ag-400-input"
          aria-label="Search filter values"
          placeholder="Search..."
        />
      </div>
    </div>
    <div ref="eFilterNoMatches" class="ag-filter-no-matches ag-hidden">
      No matches.
    </div>
    <div ref="eSetFilterList" class="ag-set-filter-list" role="presentation">
      <div
        class="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed"
        role="listbox"
      >
        <div
          class="ag-tab-guard ag-tab-guard-top"
          role="presentation"
          tabindex="0"
        ></div>
        <div
          class="ag-virtual-list-container ag-filter-virtual-list-container"
          ref="eContainer"
          [ngStyle]="{ height: 24 * (displayedCheckboxes.length + 1) + 'px' }"
        >
          <!--AG-CHECKBOX-->
          <div
            class="ag-virtual-list-item ag-filter-virtual-list-item"
            role="option"
            aria-setsize="2"
            aria-posinset="1"
            tabindex="-1"
            aria-selected="true"
            aria-checked="true"
            style="height: 24px; top: 0px"
          >
            <div class="ag-set-filter-item">
              <label
                role="presentation"
                ref="eCheckbox"
                class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
              >
                <div
                  ref="eLabel"
                  class="ag-input-field-label ag-label ag-checkbox-label"
                  id="ag-420-label"
                >
                  All Views
                </div>
                <div
                  ref="eWrapper"
                  class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                  [ngClass]="{
                    'ag-checked': filterForm.get('allViews')?.value
                  }"
                  role="presentation"
                >
                  <input
                    ref="eInput"
                    formControlName="allViews"
                    class="ag-input-field-input ag-checkbox-input"
                    type="checkbox"
                    id="ag-420-input"
                    aria-labelledby="ag-420-label"
                  />
                </div>
              </label>
            </div>
          </div>
          <!--AG-CHECKBOX-->
          <ng-container *ngFor="let view of displayedCheckboxes; let i = index">
            <div
              formArrayName="views"
              class="ag-virtual-list-item ag-filter-virtual-list-item"
              role="option"
              aria-setsize="2"
              aria-posinset="2"
              tabindex="-1"
              aria-selected="true"
              aria-checked="true"
              style="height: 24px"
              [ngStyle]="{ top: 24 * (i + 1) + 'px' }"
            >
              <div class="ag-set-filter-item">
                <label
                  role="presentation"
                  ref="eCheckbox"
                  class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                >
                  <div
                    ref="eLabel"
                    class="ag-input-field-label ag-label ag-checkbox-label"
                    id="ag-422-label"
                  >
                    {{ view.name }}
                  </div>
                  <div
                    ref="eWrapper"
                    class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                    [ngClass]="{
                      'ag-checked': filterForm
                        .get('views')
                        ?.get(viewIndex(view).toString())?.value
                    }"
                    role="presentation"
                  >
                    <input
                      ref="eInput"
                      [formControlName]="viewIndex(view)"
                      class="ag-input-field-input ag-checkbox-input"
                      type="checkbox"
                      id="ag-422-input"
                      aria-labelledby="ag-422-label"
                    />
                  </div>
                </label>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="ag-tab-guard ag-tab-guard-bottom"
          role="presentation"
          tabindex="0"
        ></div>
      </div>
    </div>
  </form>
</div>
