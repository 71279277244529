import { ColDef } from '@ag-grid-community/core';
import { MaplecroftRiskViewFilterComponent } from '../../..';

import { GridFilterType } from './models';
import { CustomGridColumnTypeNames } from './models';

export const setGridColumnFilters = (
  filterType: GridFilterType,
  newColDef: ColDef,
  asyncChoiceGetter?: (params: any) => void
): ColDef => {
  if (!newColDef.type) {
    newColDef.type = [];
  }
  if (typeof newColDef.type === 'string') {
    throw new Error('colDef.type must be of type string[]');
  }

  switch (filterType) {
    case GridFilterType.TEXT:
      newColDef.type.push(CustomGridColumnTypeNames.FILTER_TEXT);
      newColDef.filter = GridFilterType.TEXT;
      break;
    case GridFilterType.NUMBER:
      newColDef.type.push(CustomGridColumnTypeNames.FILTER_NUMBER);
      newColDef.filter = GridFilterType.NUMBER;
      break;
    case GridFilterType.SET:
      newColDef.filter = 'agSetColumnFilter';
      newColDef.filterParams = {
        ...newColDef.filterParams,
        buttons: ['clear', 'apply'],
        closeOnApply: true,
        suppressSorting: !!asyncChoiceGetter,
        values: asyncChoiceGetter,
        refreshValuesOnOpen: true,
      };
      break;
    case GridFilterType.DATE:
      newColDef.type.push(CustomGridColumnTypeNames.FILTER_DATE);
      newColDef.filterParams = {
        buttons: ['clear', 'apply'],
        closeOnApply: true,
      };
      break;
    case GridFilterType.MAPLECROFT_RISK_VIEW:
      newColDef.filterFramework = MaplecroftRiskViewFilterComponent;
      newColDef.filterParams = {
        ...newColDef.filterParams,
        values: asyncChoiceGetter,
        refreshValuesOnOpen: true,
      };
      break;
    // TODO: Currently not in use, not supported by backend
    case GridFilterType.DATETIME:
    case null:
      newColDef.filter = undefined;
      newColDef.suppressFiltersToolPanel = true;
      break;
    default:
      break;
  }
  return newColDef;
};
