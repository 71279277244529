import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as R from 'ramda';
import {
  API_SERVICES_CONFIG,
  PaginatedResourceConfig,
  NonPaginatedResourceConfig,
  PaginationService,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ApiResponseWithQueryContext,
  QueryParams,
  HttpGETCustomOptions
} from '@grid-ui/common';

import {
  CountryRiskProfile,
  CountryRiskProfileCollection,
  CountryRiskProfileDetail,
  CountryRiskProfilesParameters,
  CountryRiskProfilesSubscriptionItem,
  CountryRiskProfileSubscriptionParameters,
  CountryRiskProfileSubscriptionsCollection,
  WrittenContentContext
} from '../../../shared-models';


import { ApiWrittenContentContext } from '../../models';

import { ApiCountryRiskProfileDetails, ApiCountryRiskProfilesResult } from '../models';

@Injectable()
export class CountryRiskProfilesService {

  private profilesResourceConfig: PaginatedResourceConfig;
  private profileDetailResourceConfig: NonPaginatedResourceConfig;
  private profileDetailContextResourceConfig: NonPaginatedResourceConfig;
  private recentProfilesResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient,
    private readonly paginationService: PaginationService,
  ) {
    this.profilesResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.content.profiles._configuration;
    this.profileDetailResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.content.profiles.detail._configuration;
    this.profileDetailContextResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.content.profiles.detail.context._configuration;
    this.recentProfilesResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.content.profiles.recent._configuration;
  }

  /**
   * Get a collection of paginated Profile listing items based on the specified query parameters.
   *
   * @param parameters A query parameter object
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getProfiles(
    profilesParams: CountryRiskProfilesParameters | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskProfileCollection> {
    const queryParams: QueryParams = profilesParams || {};
    return this.http.getPaginated<ApiCountryRiskProfilesResult>(
      this.profilesResourceConfig,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map((apiResponseContext: ApiResponseWithQueryContext<ApiCountryRiskProfilesResult>) => ({
        total: apiResponseContext.response.total,
        results: apiResponseContext.response.results,
        paginationContext: this.paginationService.getNewPaginationContext(
          apiResponseContext.response.links,
          apiResponseContext.queryParams
        ),
        replaced_search: apiResponseContext.response.replaced_search,
        suggestions: apiResponseContext.response.suggestions
      }))
    );
  }

  /**
   * Get the specified country risk profile detail.
   *
   * @param id Id of country risk profile detail to retrieve.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getProfileDetails(id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskProfileDetail> {
    return this.http.get<ApiCountryRiskProfileDetails>(
      this.profileDetailResourceConfig,
      {
        ...options,
        pathParams: { id }
      }
    ).pipe(
      map(this.transformProfileDetail)
    );
  }

  /**
   * Get the context of the specified country risk profile detail. The returned small data structure
   * is suitable to e.g. build the partial breadcrumbs trail for the specified profile detail, or generate
   * download links.
   *
   * @param id Id of country risk profile to retrieve.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getProfileDetailsContext(
    id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<WrittenContentContext> {
    return this.http.get<ApiWrittenContentContext>(
      this.profileDetailContextResourceConfig,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  /**
   * Get the URL path segments for Profile Details
   */
  public getProfileDetailUrlPath(): string {
    return this.profileDetailResourceConfig.path;
  }

  /**
   * Get a collection of paginated Profile listing items based on the specified query parameters.
   *
   * Note: The returned collection contains both restricted and unrestricted profile items.
   *
   * @param parameters A query parameter object
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getProfileSubscriptions(
    parameters: CountryRiskProfileSubscriptionParameters | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskProfileSubscriptionsCollection> {
    const queryParams: QueryParams = { ...parameters, 'show_restricted': '1', 'fields': 'title,id,region,lead_tag' };
    return this.http.get<ApiCountryRiskProfilesResult>(
      this.profilesResourceConfig,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map((profileSubscriptionsResult: ApiCountryRiskProfilesResult) => ({
        total: profileSubscriptionsResult.total,
        results: this.flattenProfileToSubscribableListItem(profileSubscriptionsResult.results),
        paginationContext: this.paginationService.getNewPaginationContext(profileSubscriptionsResult.links, parameters)
      }))
    );
  }

  /**
   * Get recently opened profiles
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRecentlyOpenedProfiles(options: HttpGETCustomOptions = { forceAPICall: true }): Observable<CountryRiskProfileCollection> {
    return this.http.get<CountryRiskProfileCollection>(
      this.recentProfilesResourceConfig,
      options
    );
  }

  /**
   * Flattens an array of country risk profiles obtained with `show_restricted=1`
   * parameter and not including group children into an array of country risk profile
   * subscription items
   *
   * @param profiles Array of profiles.
   */
  private flattenProfileToSubscribableListItem(profiles: CountryRiskProfile[]): CountryRiskProfilesSubscriptionItem[] {
    const subscribables: CountryRiskProfilesSubscriptionItem[] = [];
    profiles.forEach(profile => {
      const lead_tag = profile.lead_tag;
      const region = profile.region;
      profile.grouped.forEach(profileDetail => {
        const item: CountryRiskProfilesSubscriptionItem = {
          profile_id: profileDetail.profile_id,
          type: profileDetail.title,
          country: lead_tag.label,
          region,
          restricted: !!profileDetail.restricted
        };
        subscribables.push(item);
      });
    });
    return subscribables;
  }

  private transformProfileDetail(rawDetail: ApiCountryRiskProfileDetails): CountryRiskProfileDetail {
    const detail = R.omit(['links'], rawDetail);
    return detail as CountryRiskProfileDetail;
  }
}
