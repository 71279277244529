import { ScoreCategoryType } from '../shared-models';

export function getScoreCategory(score: number | null | undefined): ScoreCategoryType | null {
  if (typeof score !== 'number' || score > 10 || score < 0) {
    return null;
  }
  if (score <= 2.5) {
    return 'extreme';
  }
  if (score <= 5) {
    return 'high';
  }
  if (score <= 7.5) {
    return 'medium';
  }
  return 'low';
}
