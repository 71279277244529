import { LocationAttributeV4 } from '../../../../shared-models/locations';

export const V4_CONCRETE_ATTRIBUTES: LocationAttributeV4[] = [
  {
    key: 'name',
    description: 'Site Name',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'country_name',
    description: 'Country Name',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'country_code',
    description: 'Country Code',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'created',
    description: 'Date Uploaded',
    type: 'date',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'city',
    description: 'City',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'region',
    description: 'Region',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'postcode',
    description: 'Postcode',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'latitude',
    description: 'Latitude',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'longitude',
    description: 'Longitude',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
  {
    key: 'customer_id',
    description: 'Site ID',
    type: 'string',
    is_reference: true,
    distinct_value_count: 0,
  },
];
