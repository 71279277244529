import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
// eslint-disable-next-line import/no-unresolved
import { ENVIRONMENT_CONFIG } from '@grid-ui/environment';
import { WindowUtilitiesService } from '../../../../shared-utilities/window-services/services';

export const URLS_EXCLUDED_LOGIN_REDIRECT_REGEX = [
  /fe-api\/analytics\/events/,
  /v4\/location\/delete-request/,
  /fe-api\/shared-link/,
  /api\/v3\/feature-flags/,
  /api\/v3\/auth\/whoami\/user/,
  /api\/v3\/announcements/,
  /fe-api\/module-announcement/,
];

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
    private readonly windowUtilitiesService: WindowUtilitiesService,
    @Inject(ENVIRONMENT_CONFIG) private environmentConfig: any
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const redirecting = this.checkError(error);
        if (!redirecting) {
          return throwError(error);
        }
        // we need to return something to stop Angular cancelling the redirect, however we don't want it actioned on
        // therefore adding a very long delay (20 seconds) to ensure redirect is complete before observalbe emits
        return of({} as any).pipe(delay(20000));
      })
    );
  }

  private checkError(err: HttpErrorResponse): boolean {
    // no redirect in local dev as this causes a redirect loop
    if (
      [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(err.status) &&
      this.environmentConfig?.environment !== 'e2e' &&
      !URLS_EXCLUDED_LOGIN_REDIRECT_REGEX.some(regex => regex.test(err.url || ''))
    ) {
      const currentLocation = encodeURIComponent(this.windowUtilitiesService.getFullPath());
      const redirectUrl = err.status === HttpStatusCode.Unauthorized
        ? currentLocation ? `/accounts/login?next=${currentLocation}` : '/accounts/login'
        : '/no-access.html';
      this.windowUtilitiesService.setHref(redirectUrl);
      return true;
    }
    return false;
  }
}
