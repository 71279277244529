import * as R from 'ramda';
import { format, parse } from 'date-fns';

import { GridFilterModel } from './models';

const DATE_FROM_PARAM_KEY = 'date_from';
const DATE_TO_PARAM_KEY = 'date_to';

export function assignFilterParametersToObject(
  obj: { [key: string]: any },
  filterModel: GridFilterModel,
  legacyFilter?: boolean,
): { [key: string]: any } {
  Object.keys(filterModel).forEach(key => {
    const criterion = filterModel[key];
    switch (criterion.filterType) {
      case 'text':
        switch (criterion.type) {
          case 'contains':
            obj[legacyFilter ? key : `${key}__icontains`] = criterion.filter;
            break;
          default:
            console.error('Unsupported filter text filter type:', criterion);
            throw (new Error('Unsupported text filter type'));
        }
        break;
      case 'set':
        obj[key] = criterion.values;
        break;
      case 'date':
        if (
          (criterion.type === 'greaterThanOrEqual' && criterion.dateFrom)
          || (criterion.type === 'inRange' && criterion.dateFrom && criterion.dateTo)
        ) {
          obj[DATE_FROM_PARAM_KEY] = convertToApiDateFormat(criterion.dateFrom);
        }

        // Note: Intuitively lessThanOrEqual option should populate dateTo,
        // however AgGrid populates dateFrom instead.
        // Adding here checks for both fields to protect against future lib changes.
        if (criterion.type === 'lessThanOrEqual' && criterion.dateTo) {
          obj[DATE_TO_PARAM_KEY] = convertToApiDateFormat(criterion.dateTo);
        }

        if (criterion.type === 'lessThanOrEqual' && criterion.dateFrom) {
          obj[DATE_TO_PARAM_KEY] = convertToApiDateFormat(criterion.dateFrom);
        }

        if (criterion.type === 'inRange' && criterion.dateFrom && criterion.dateTo) {
          obj[DATE_TO_PARAM_KEY] = convertToApiDateFormat(criterion.dateTo);
        }

        if (criterion.type === 'equalsDate' && criterion.dateFrom) {
          obj[DATE_FROM_PARAM_KEY] = convertToApiDateFormat(criterion.dateFrom);
          obj[DATE_TO_PARAM_KEY] = convertToApiDateFormat(criterion.dateFrom);
        }
        break;
      case 'number':
        obj[key] = criterion.filter;
        break;
      default:
        console.error('Unsupported filter criterion:', criterion);
        throw (new Error('Unsupported filter type'));
    }
  });
  return obj;
}

export function assignFilterParametersToObjectV4(
  obj: Record<string, any>,
  filterModel: GridFilterModel,
  keysMap: Record<string, any>,
): { [key: string]: any } {
  Object.keys(filterModel).forEach(key => {

    // concrete location attributes get mapped differently to customer attributes
    const prefix = key in keysMap ? '' : 'attributes__';

    // remap to a v4 specific param, prefixed if necessary
    const apiKey = prefix + '' + (keysMap[key] || key);

    const criterion = filterModel[key];

    switch (criterion.filterType) {
      case 'text':
        switch (criterion.type) {
          case 'contains':
            obj[`${apiKey}__icontains`] = criterion.filter;
            break;
          default:
            console.error('Unsupported filter text filter type:', criterion);
            throw (new Error('Unsupported text filter type'));
        }
        break;
      case 'set':
        obj[`${apiKey}__in`] = criterion.values.join(',');
        break;
      case 'number':
        switch (criterion.type) {
          case 'greaterThanOrEqual':
            obj[`${apiKey}__gte`] = criterion.filter;
            break;
          case 'lessThanOrEqual':
            obj[`${apiKey}__lte`] = criterion.filter;
            break;
          default:
            obj[`${apiKey}__exact`] = criterion.filter;
        }
        break;

      case 'date':
        if (
          (criterion.type === 'greaterThanOrEqual' && criterion.dateFrom)
          || (criterion.type === 'inRange' && criterion.dateFrom && criterion.dateTo)
        ) {
          obj[`${apiKey}__gte`] = criterion.dateFrom;
        }
        // Note: Intuitively lessThanOrEqual option should populate dateTo,
        // however AgGrid populates dateFrom instead.
        // Adding here checks for both fields to protect against future lib changes.
        if (criterion.type === 'lessThanOrEqual' && criterion.dateTo) {
          obj[`${apiKey}__lte`] = criterion.dateTo;
        }

        if (criterion.type === 'lessThanOrEqual' && criterion.dateFrom) {
          obj[`${apiKey}__lte`] = criterion.dateFrom;
        }

        if (criterion.type === 'inRange' && criterion.dateFrom && criterion.dateTo) {
          obj[`${apiKey}__lte`] = criterion.dateTo;
        }

        if (criterion.type === 'equalsDate' && criterion.dateFrom) {
          obj[`${apiKey}__date__exact`] = criterion.dateFrom;
        }

        break;
      default:
        console.error('Unsupported filter criterion:', criterion);
        throw (new Error('Unsupported filter type'));
    }
  });

  return obj;
}

export function updateQueryParametersWithFilters(
  queryParams: Record<string, any>,
  filterModel: GridFilterModel,
  apiParamMap?: Record<string, string>,
  legacyFilter?: boolean,
): Record<string, any> {
  const keys = Object.keys(filterModel);
  if (keys.length === 0) {
    return queryParams;
  }

  const newQueryParam = R.clone(queryParams);

  if (apiParamMap) {
    assignFilterParametersToObjectV4(newQueryParam, filterModel, apiParamMap);
  } else {
    assignFilterParametersToObject(newQueryParam, filterModel, legacyFilter);
  }

  return newQueryParam;
}

function convertToApiDateFormat(date: string): string {
  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  return format(parsedDate, 'dd-MM-yyyy');
}
