import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';

import { WindowUtilitiesService } from '../../../../../shared-utilities/window-services';

import * as fromNotificationBar from '../../../store/reducers';
import { ExtractDownloadedFromNotificationBar } from '../../../store/actions/notifications';
import { DataWizardExtractNotificationService } from '../../../services';

@Component({
  selector: 'mc-extract-notification',
  templateUrl: './extract-notification.component.html',
  styleUrls: ['./extract-notification.component.scss']
})
export class ExtractNotificationComponent implements OnInit, OnDestroy {

  @Output() close = new EventEmitter<void>();

  public complete$: Observable<boolean>;
  public extractDownloadUrl: string;
  private downloadExtractUrlSubscription: Subscription;

  constructor(
    private windowUtilitiesService: WindowUtilitiesService,
    private readonly extractNotificationService: DataWizardExtractNotificationService,
    private readonly store: Store<fromNotificationBar.State>
  ) {
  }

  ngOnInit(): void {
    this.complete$ = this.extractNotificationService.extractComplete();

    this.downloadExtractUrlSubscription = this.extractNotificationService
      .getDownloadExtractUrl()
      .subscribe((extractDownloadUrl: string) => {
        this.extractDownloadUrl = extractDownloadUrl;
      });
  }

  ngOnDestroy(): void {
    this.downloadExtractUrlSubscription.unsubscribe();
  }

  public cancel(): void {
    this.extractNotificationService.cancel();
  }

  public downloadExtract(): void {
    this.extractNotificationService.downloadExtract();
    this.store.dispatch(new ExtractDownloadedFromNotificationBar(this.extractDownloadUrl));
    this.windowUtilitiesService.downloadAsset(this.extractDownloadUrl);
    this.close.emit();
  }

  public closeNotification(): void {
    this.extractNotificationService.closeNotification();
    this.close.emit();
  }
}
