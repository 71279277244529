export const ADDITIONAL_COUNTRIES_OFFER_ALERT_MESSAGE_COUNTRIES =
  'For a full list of countries Verisk Maplecroft offer please view';

export const ADDITIONAL_COUNTRIES_OFFER_ALERT_MESSAGE_SITES =
  'Some sites aren’t shown because you’re not entitled to some countries. Please view';


export const ADDITIONAL_COUNTRIES_OFFER_ALERT_MESSAGE_SITES_LIST =
  'These sites are in unsubscribed countries, which means you cannot access risk scores for these sites.' +
  ' To subscribe to these locations, please visit';
