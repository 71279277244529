import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';
import { GLOBAL_ROUTING_ACTION_CREATORS } from '../data-access/+state/routing-state.actions';

@Component({
    template: '',
    selector: 'grid-ui-routing-component',
})
export class RoutingComponent implements OnDestroy {
    isComponentAlive = true;
    
    constructor(private router: Router, private store: Store) {
        this.router.events
            .pipe(takeWhile(() => this.isComponentAlive))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.store.dispatch(GLOBAL_ROUTING_ACTION_CREATORS.routeChange({ route: event.url }));
                }
            });
    }

    ngOnDestroy(): void {
        this.isComponentAlive = false;
    }
}