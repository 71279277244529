import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { GoogleAnalyticsEvent } from '../models';
import { AnalyticsUserDetails } from '../models/analytics-user-details.model';

export interface WindowWithDataLayer {
  dataLayer: any[];
}

export abstract class IGtmConfigProvider {
  abstract gtmEnabled: boolean;
  abstract userDetails$: Observable<AnalyticsUserDetails>;
}

@Injectable()
export class GoogleAnalyticsService {

  constructor(
    @Inject(WINDOW) readonly windowRef: WindowWithDataLayer,
    gtmConfigProvider: IGtmConfigProvider
  ) {
    gtmConfigProvider.userDetails$
      .pipe(take(1))
      .subscribe(config => {
        if (gtmConfigProvider.gtmEnabled) {
          this.setDataLayerUserDetails(config);
        }
      });
  }

  public emitEvent(event: GoogleAnalyticsEvent): void {
    this.windowRef.dataLayer?.push(event);
  }

  private setDataLayerUserDetails(userDetails: AnalyticsUserDetails) {
    this.windowRef.dataLayer?.push(userDetails);
  }

}

