import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import {
  ItemInformationListingDeleteAction,
  ItemInformationListingDownloadAction,
  ItemInformationListingEditAction,
  ItemInformationListingItem,
  ItemInformationListingShareAction,
  ItemInformationListingReadAction,
  ItemInformationListingSubscribeAction,
  ItemInformationListingAnalyseAction,
  ItemInformationListingUnShareAction,
  ItemInformationListingCopyAction,
} from '../../../shared-models';
import { CellRendererActionsCellValue } from '../models';

@Component({
  selector: 'grid-ui-cell-renderer-actions',
  templateUrl: './cell-renderer-actions.component.html',
  styleUrls: ['./cell-renderer-actions.component.scss']
})
export class CellRendererActionsComponent implements ICellRendererAngularComp {

  public canExtract = false;
  public canShareOrDeleteAsCreator = false;
  public isShared = false;
  public canEdit = true;
  public canRead = false;
  public canSubscribe = false;
  public canDownload = false;
  public canAnalyse = false;
  public canUnshare =  false;
  public isTextual = false;
  public canDelete = false;
  public canCopy = false;

  public downloadUrl = '';
  public tooltipPostfix = '';

  private cellValue?: CellRendererActionsCellValue;

  public refresh(params: ICellRendererParams): boolean {
    return true;
  }

  public agInit(params: CellRendererActionsCellValue): void {
    this.cellValue = params;
    this.canExtract = this.cellValue.canExtract;
    this.canShareOrDeleteAsCreator = this.cellValue.showShareOrDelete && this.cellValue.data.is_creator;
    this.canDownload = this.cellValue.canDownload || false;
    this.canDelete = this.cellValue.canDelete ?? false;
    this.canCopy = this.cellValue.canCopy ?? false;

    this.isShared = this.cellValue.data.shared;
    this.canEdit = this.cellValue.canEdit ?? true;
    this.canRead = this.cellValue.canRead ?? false;

    this.canSubscribe = (typeof this.cellValue.canSubscribe !== 'undefined') ? this.cellValue.canSubscribe :
      (typeof this.cellValue.data.restricted !== 'undefined' ? this.cellValue.data.restricted : false);
    this.canAnalyse = (typeof this.cellValue.canAnalyse !== 'undefined') ? this.cellValue.canAnalyse :
      (typeof this.cellValue.data.restricted !== 'undefined' ? !this.cellValue.data.restricted : false);

    this.canUnshare = this.cellValue.canUnshare ?? false;
    this.isTextual = this.cellValue.isTextual ?? false;

    this.downloadUrl = this.cellValue.data.href;
  }

  public read(): void {
    if (this.canRead) {
      this.cellValue?.handleAction(
        new ItemInformationListingReadAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public edit(): void {
    if (this.canEdit) {
      this.cellValue?.handleAction(
        new ItemInformationListingEditAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public extract(): void {
    this.cellValue?.handleAction(
      new ItemInformationListingDownloadAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
    );
  }

  public delete(): void {
    if (this.canShareOrDeleteAsCreator || this.canDelete) {
      this.cellValue?.handleAction(
        new ItemInformationListingDeleteAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public share(): void {
    if (this.canShareOrDeleteAsCreator) {
      this.cellValue?.handleAction(
        new ItemInformationListingShareAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public unshare(): void {
    if (this.canUnshare) {
      this.cellValue?.handleAction(
        new ItemInformationListingUnShareAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public subscribe(): void {
    if (this.canSubscribe) {
      this.cellValue?.handleAction(
        new ItemInformationListingSubscribeAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public analyse(): void {
    if (this.canAnalyse) {
      this.cellValue?.handleAction(
        new ItemInformationListingAnalyseAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  public copy(): void {
    if (this.canCopy) {
      this.cellValue?.handleAction(
        new ItemInformationListingCopyAction({ item: this.getInformationListingItem(), index: this.cellValue.rowIndex })
      );
    }
  }

  private getInformationListingItem(): ItemInformationListingItem<any> {
    return {
      data: this.cellValue?.data,
      showAnnouncements: false,
      showInformation: false,
      actions: null
    };
  }
}
