import { Environment } from './models';

export const gridEnvironment: Environment = {
  environment: 'prod',
  csrfToken: 'csrftoken',
  production: true,
  test: false,
  appConfig: {
    minIndexEdition: '2017-Q1',
    agGrid: {
      useUnsortIcon: true,
      suppressMenuHide: true
    },
    locationTables: {
      filterMaxOptions: 500
    },
    jiraTicketCollector: {
      enableForAllUsers: false
    },
    hotjarTrackingCode: '1132920',
    sentryDsn: 'https://0b8d82ecd51b46c3aeb7cf2bd6b5da11@sentry.maplecroft.com/17'
  },
  paths: {
    img: '/assets/img',
    icons: '/assets/img/icons',
  },
  googleTagManagerId: 'GTM-K8HT8SF',
  changePasswordUrl: 'https://sso.verisk.com/signin/forgot-password',
  blankMapTileUrl: 'https://tiles.maplecroft.com/map-resources/tiles/rasters-2012.blank_raster/{z}/{x}/{y}.png',
  industryFrontendApi: '/industry-risk/fe-api/v1/',
  mapsBackendBaseURL: 'https://maps.maplecroft.com/maps', // Assumes CloudFront.
  mapsOpenStreetMapVectorTilesetName: 'grid-analyse_v0.2.15'
};
