import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[mcRouterLinkClickListener]'
})
export class RouterLinkClickListenerDirective {

  constructor(private router: Router) { }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!this.CheckValidRouterElement(target)) {
      return;
    }

    this.router.navigate([target.getAttribute('routerlink')]);
    event.preventDefault();
  }

  /*
    Check if the target element is a valid element to intercept.
    Currently just elements with a routerLink should be intercepted, but this can be expanded.
   */
  private CheckValidRouterElement(target: HTMLElement): boolean {
    return target.getAttribute('routerlink') ? true : false;
  }
}
