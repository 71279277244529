<div class="body-item-container" *ngIf="bodyItem" [ngSwitch]="bodyItem.type">

  <h4 class="mc-body-item-heading h-sup" [class.consecutive]="isConsecutiveH3()" *ngSwitchCase="'heading'">
    {{ bodyItem.value | updateLink }}
  </h4>

  <h4 class="mc-body-item-subheading" *ngSwitchCase="'subheading'">{{ bodyItem.value | updateLink }}</h4>

  <grid-ui-body-item-paragraph *ngSwitchCase="'paragraph'"
    [class]="'item-paragraph'"
    [value]="bodyItem.value"
    (writtenContentLinkActionEmit)="emitWrittenContentLinkAction($event)"
  >
  </grid-ui-body-item-paragraph>

  <grid-ui-body-item-quote *ngSwitchCase="'quote'"
    [value]="bodyItem.value">
  </grid-ui-body-item-quote>

  <grid-ui-body-item-video *ngSwitchCase="'video'"
    [value]="bodyItem.value">
  </grid-ui-body-item-video>

  <grid-ui-body-item-image *ngSwitchCase="'image'"
    [value]="bodyItem.value"
    (loaded)="handleImageLoaded()"
  >
  </grid-ui-body-item-image>

  <grid-ui-body-item-major-risks *ngSwitchCase="'majorrisks'"
    [value]="bodyItem.value">
  </grid-ui-body-item-major-risks>

  <grid-ui-body-item-focusbox *ngSwitchCase="'focusbox'"
    [value]="bodyItem.value"
    (writtenContentLinkActionEmit)="emitWrittenContentLinkAction($event)"
  >
  </grid-ui-body-item-focusbox>

  <grid-ui-body-item-outlook *ngSwitchCase="'outlook'"
    [value]="bodyItem.value">
  </grid-ui-body-item-outlook>

  <grid-ui-body-item-table *ngSwitchCase="'table'"
    [value]="bodyItem.value">
  </grid-ui-body-item-table>

  <grid-ui-body-item-table *ngSwitchCase="'enhancedtable'"
    [value]="bodyItem.value">
  </grid-ui-body-item-table>

  <!-- Both country 'riskcomparisonchart' and 'riskscorecard' use vega with
    slightly different specs, so we can use the same component -->
  <grid-ui-body-item-scorecard *ngSwitchCase="'countryriskcomparisonchart'"
    [value]="bodyItem.value">
  </grid-ui-body-item-scorecard>

  <grid-ui-body-item-scorecard *ngSwitchCase="'riskscorecard'"
    [value]="bodyItem.value">
  </grid-ui-body-item-scorecard>

  <ng-container *ngSwitchCase="'narrativesectionpage'">
    <ng-container *ngIf="enableNestedBodyItem">
      <grid-ui-body-item
        *ngFor="let nestedBodyItem of bodyItem.value.body"
        [bodyItem]="nestedBodyItem"
        [enableNestedBodyItem]="false"
        (writtenContentLinkActionEmit)="emitWrittenContentLinkAction($event)"
      >
      </grid-ui-body-item>
    </ng-container>
  </ng-container>

</div>
