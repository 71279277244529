import { NgModule, ModuleWithProviders, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserWindowRef, WindowRef, WINDOW, windowFactory } from './providers';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class WindowProviderModule {
  static forRoot(): ModuleWithProviders<WindowProviderModule> {
    return {
      ngModule: WindowProviderModule,
      providers: [
        { provide: WindowRef, useClass: BrowserWindowRef },
        {
          provide: WINDOW,
          useFactory: windowFactory,
          deps: [WindowRef, PLATFORM_ID]
        }
      ]
    };
  }
}
