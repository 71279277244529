import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as R from 'ramda';

import { NonPaginatedResourceConfig, API_SERVICES_CONFIG, PortalHttpClient, Country } from '@grid-ui/common';

import {
  ApiCountriesCollection,
  CountryQueryParams,
  EnhancedCountriesCollection
} from '../models';

export type ApiCountryAttributeFieldName =
  'subregion' |
  'income-group-wb' |
  'jpm-embi' |
  'msci-designation' |
  'vm-risk-views';

export const API_COUNTRY_ADDITIONAL_FIELD_NAMES: ApiCountryAttributeFieldName[] = [
  'subregion',
  'income-group-wb',
  'jpm-embi',
  'msci-designation',
  'vm-risk-views',
];

/**
 * Service for accessing the Countries API
 */
@Injectable()
export class CountriesService {

  private allCountriesResourceConfig: NonPaginatedResourceConfig;
  private scoredCountriesResourceConfig: NonPaginatedResourceConfig;

  private unentitledCountriesCount: number | null = null;

  constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.allCountriesResourceConfig = API_SERVICES_CONFIG.v3.countries.all._configuration;
    this.scoredCountriesResourceConfig = API_SERVICES_CONFIG.v3.countries.scored._configuration;
  }

  // TODO: validate App requirements for search logic, relative to what the API provides.

  /**
   * Get list of all scored countries matching the search criteria from the API or in-memory cache,
   * if already cached. If no search parameters are specified, or searchParam is set to null,
   * the list of all scored countries is returned.
   *
   * Country name matches are case-insensitive and performed using "contains" logic. Region
   * matches are done case-sensitive, but exact.
   *
   * @param queryParams An optional object of parameters. It includes search and sort parameters.
   * The search parameters can be specified for country name or geographic region.
   * If omitted, or set to null, a list of all countries is returned.
   * @param customRetryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public getScoredCountries(
    queryParam?: CountryQueryParams | null,
    customRetryAttempts?: number
  ): Observable<Country[]> {
    // TODO: Caching logic to be implemented once ETags are supported server-side
    const queryParams = { ...R.omit(['name', 'region'], queryParam) };

    const countries$: Observable<Country[]> = this.http
      .get<ApiCountriesCollection>(this.scoredCountriesResourceConfig, {
      queryParams,
      retryOptions: { customRetryAttempts },
    })
      .pipe(map(response => response.results));

    if (queryParam && (queryParam.name || queryParam.region)) {
      return this.filterCountries(countries$, queryParam);
    } else {
      return countries$;
    }
  }

  public getUnEntitledScoredCountries(
    queryParam?: CountryQueryParams | null,
    customRetryAttempts?: number,
  ): Observable<Country[]> {
    return this.getScoredCountries({ ...queryParam, unentitled: 1 }, customRetryAttempts);
  }

  /**
   * Get list of all countries including un-scored, entitled and un-entitled matching the
   * search criteria from the API or in-memory cache, if already cached.
   * If no search parameters are specified, or searchParam is set to null,
   * the list of all countries is returned.
   *
   * Country name matches are case-insensitive and performed using "contains" logic. Region
   * matches are done case-sensitive, but exact.
   *
   * @param queryParams An optional object of parameters. It includes search and sort parameters.
   * The search parameters can be specified for country name or geographic region.
   * If omitted, or set to null, a list of all countries is returned.
   * @param customRetryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public getAllCountries(
    queryParams?: CountryQueryParams,
    customRetryAttempts?: number
  ): Observable<Country[]> {
    return this.http
      .get<ApiCountriesCollection>(this.allCountriesResourceConfig, {
      queryParams,
      retryOptions: { customRetryAttempts }
    })
      .pipe(map(response => response.results));
  }

  /**
   * Get a collection of countries matching the specified query parameters,
   * enhanced with additional entitlement related attributes
   *
   * @param queryParams An optional object of parameters. It includes search and sort parameters.
   * The search parameters can be specified for country name or geographic region.
   * If omitted, or set to null, a list of all countries is returned.
   * @param customRetryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public getEnhancedCountriesCollection(
    queryParams?: CountryQueryParams | null,
    customRetryAttempts?: number
  ): Observable<EnhancedCountriesCollection> {
    // HACK: The below workaround is in place until there may be more comprehensice API
    // support to retrieve the necessary information in a single API call
    return forkJoin([
      this.getScoredCountries(queryParams, customRetryAttempts),
      this.getUnentitledCountriesCount(customRetryAttempts)
    ]).pipe(
      map(([countries, unentitledCount]) => {
        const collection: EnhancedCountriesCollection = {
          total: countries.length,
          results: countries,
          userHasUnentitledCountries: unentitledCount > 0
        };
        return collection;
      })
    );
  }

  /**
   * Get a readonly map from country code to country name.
   *
   * @param includeUnentitled Optional parameter to indicate whether the returned node map
   * should include unentitled countries on top of the entitled ones. Defaults to false.
   */
  public getCountryNameMap(includeUnentitled: boolean = false): Observable<Readonly<Map<string, string>>> {
    const param: CountryQueryParams = includeUnentitled ? { unentitled: -1 } : {};
    return this.getScoredCountries(param).pipe(
      map(countries => {
        const countryNameMap = Object.freeze(new Map<string, string>(
          countries.map(country => [country.geo_id, country.name] as [string, string])
        ));
        return countryNameMap;
      }),
    );
  }

  /**
   * Get the count of unentitled countries for the user
   *
   * @param customRetryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public getUnentitledCountriesCount(customRetryAttempts?: number): Observable<number> {
    if (this.unentitledCountriesCount !== null) {
      return of(this.unentitledCountriesCount);
    }
    const queryParams: CountryQueryParams = { unentitled: 1, grad: 1 };

    return this.http
      .get<ApiCountriesCollection>(this.scoredCountriesResourceConfig, {
      queryParams,
      retryOptions: { customRetryAttempts }
    })
      .pipe(
        map(countriesCollection => countriesCollection.total),
        tap(total => this.unentitledCountriesCount = total)
      );
  }

  /**
   * Returns an observable containing the list of countries, which meet the specified
   * search parameter.
   *
   * IMPORTANT: Currently both country name and region are matched case-insensitive, but
   * country name is matched using "contains" logic, whereas region is matched exact.
   *
   * @param countries$ An observable of an array of Country.
   * @param searchParam A search parameter object.
   */
  private filterCountries(countries$: Observable<Country[]>, searchParam: CountryQueryParams): Observable<Country[]> {

    const name: string | null = searchParam.name ? searchParam.name.toLocaleLowerCase() : null;
    const region: string | null = searchParam.region ? searchParam.region.toLocaleLowerCase() : null;

    return countries$.pipe(
      map(countries =>
        countries.filter(country =>
          (name === null || country.name.toLocaleLowerCase().includes(name)) &&
          (region === null || country.region.toLocaleLowerCase() === region)
        )
      )
    );
  }

}
