<div class="container-fluid mc-flex-container">
  <div class="mc-value mc-value-actions">
    <ng-container *ngIf="canRead">
      <div
        class="mc-row-icon mc-text"
        (click)="read()"
        *ngIf="isTextual"
      >
        Read
      </div>
      <span
        class="mc-row-icon mc-read-icon"
        (click)="read()"
        placement="top"
        ngbTooltip="Read Content"
        container="body"
        *ngIf="!isTextual"
      >
        <svg class="icon small info-grey">
          <use xlink:href="#shape-folder-open" />
        </svg>
      </span>
    </ng-container>
    <ng-container *ngIf="canAnalyse">
      <div
        class="mc-row-icon mc-text"
        (click)="analyse()"
        *ngIf="isTextual"
      >
        Analyse
      </div>
      <span
          class="mc-row-icon mc-analyse-icon"
          (click)="analyse()"
          placement="top"
          ngbTooltip="Open in Analyse"
          container="body"
          *ngIf="!isTextual"
        >
          <a>Analyse</a>
        </span>
    </ng-container>

      <ng-container *ngIf="canEdit">
        <span
          class="mc-row-icon mc-edit-icon"
          (click)="edit()"
          placement="top"
          ngbTooltip="Edit"
          container="body"
        >
          <grid-ui-icon class="icon" name="edit"></grid-ui-icon>
        </span>
      </ng-container>

      <ng-container *ngIf="canDownload">
        <a [href]="downloadUrl" target="_blank">
          <span
            class="mc-row-icon mc-download-icon"
            placement="top"
            ngbTooltip="Download"
            container="body"
          >
            <grid-ui-icon class="icon" name="download"></grid-ui-icon>
          </span>
        </a>
      </ng-container>

      <ng-container *ngIf="canExtract">
        <span
          class="mc-row-icon mc-download-icon"
          (click)="extract()"
          placement="top"
          ngbTooltip="Extract data"
          container="body"
        >
          <grid-ui-icon class="icon" name="download"></grid-ui-icon>
        </span>
      </ng-container>

    <ng-container *ngIf="canShareOrDeleteAsCreator || canDelete">
      <span
        class="mc-row-icon mc-delete-icon"
        (click)="delete()"
        placement="top"
        ngbTooltip="Delete"
        container="body"
      >
        <grid-ui-icon class="icon" name="bin"></grid-ui-icon>
      </span>
      <span
        *ngIf="canShareOrDeleteAsCreator"
        class="mc-row-icon mc-share-icon"
        (click)="share()"
        [class.mc-shared]="isShared"
        placement="top"
        [ngbTooltip]="isShared ? 'Sharing options' : 'Bookmark or Share'"
        container="body"
      >
        <grid-ui-icon class="icon" [name]="isShared ? 'people-plus' : 'people'"></grid-ui-icon>
      </span>
    </ng-container>

    <ng-container *ngIf="canUnshare">
      <div
        class="mc-row-icon mc-text mc-unshare"
        (click)="unshare()"
      >
        Unshare
      </div>
    </ng-container>

    <ng-container *ngIf="canSubscribe">
      <div
        class="mc-row-icon mc-text"
        (click)="subscribe()"
        *ngIf="isTextual"
      >
        Subscribe
      </div>
      <span
          class="mc-row-icon mc-subscribe-icon"
          (click)="subscribe()"
          placement="top"
          ngbTooltip="Subscribe"
          container="body"
          *ngIf="!isTextual"
        >
          <svg class="icon small info-grey">
            <use xlink:href="#shape-plus" />
          </svg>
        </span>
    </ng-container>

    <ng-container *ngIf="canCopy">
      <span
        class="mc-row-icon mc-copy-icon"
        (click)="copy()"
        placement="top"
        ngbTooltip="Copy"
        container="body"
      >
        <grid-ui-icon class="icon" name="copy"></grid-ui-icon>
      </span>
    </ng-container>
  </div>
</div>
