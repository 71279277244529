import { createReducer, on } from '@ngrx/store';
import { GLOBAL_ROUTING_ACTION_CREATORS } from './routing-state.actions';

export const GLOBAL_ROUTING_FEATURE_KEY = 'GLOBAL_ROUTING';

export interface GlobalRoutingState {
    currentRouteUrl: string | undefined;
}

export const initialGlobalRoutingState: GlobalRoutingState = {
    currentRouteUrl: undefined,
};

export const globalRoutingReducer = createReducer(
    initialGlobalRoutingState,
    on(GLOBAL_ROUTING_ACTION_CREATORS.routeChangeSuccess, (state, { route }) => ({
        ...state,
        currentRouteUrl: route,
    }))

);

export function globalRoutingReducerFn(state: GlobalRoutingState | undefined, action: any): GlobalRoutingState {
    return globalRoutingReducer(state, action);
}