import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PollConfig } from '../../../../shared-models';

import { PollingActions, PollingActionTypes } from '../actions/polling.actions';


export type State = EntityState<PollConfig>;

export const adapter: EntityAdapter<PollConfig> = createEntityAdapter<PollConfig>();

export const initialState: State = adapter.getInitialState({ });

export function pollsReducer(state = initialState, action: PollingActions): State {
  switch (action.type) {

    case PollingActionTypes.ContinueOngoingPoll:
    case PollingActionTypes.StartPolling: {
      return adapter.addOne(action.payload.poll, state);
    }

    case PollingActionTypes.Poll: {
      return adapter.updateOne({ id: action.payload.poll.id, changes: action.payload.poll }, state);
    }

    case PollingActionTypes.RemovePoll: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PollingActionTypes.UpdatePollStatus: {
      return adapter.updateOne({ id: action.payload.id, changes: { status: action.payload.status } }, state);
    }

    case PollingActionTypes.UpdateExtractId: {
      return adapter.updateOne({ id: action.payload.id, changes: { extractId: action.payload.extractId } }, state);
    }

    case PollingActionTypes.UserCancel: {
      return adapter.updateOne({ id: action.payload.id, changes: { userCancelled: true } }, state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
