import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSimpleHtmlTags'
})
/**
 * A pipe that removes HTML tags so that the string can be displayed as plain text.
 */
export class RemoveSimpleHtmlTagsPipe implements PipeTransform {

  public transform(value: string | null): string {
    // TODO: Assess alternative regex: value.replace(/<.*?>/g, '')
    return value !== null ? value.replace(/<[^>]*>/g, '') : '';
  }

}
