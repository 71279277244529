/**
 * Based on https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
 */
export enum Cursor {
  /**
   * General
   */
  // The UA will determine the cursor to display based on the current context. E.g., equivalent to text when hovering text.
  auto = 'auto',
  // The platform-dependent default cursor. Typically an arrow.
  default = 'default',
  // No cursor is rendered.
  none = 'none',

  /**
   * Links & Status
   */
  // A context menu is available.
  contextMenu = 'context-menu',
  // Help information is available.
  help = 'help',
  // The cursor is a pointer that indicates a link. Typically an image of a pointing hand.
  pointer = 'pointer',
  // The program is busy in the background, but the user can still interact with the interface (in contrast to wait).
  progress = 'progress',
  // The program is busy, and the user can't interact with the interface (in contrast to progress). Sometimes an image of an hourglass or a watch.
  wait = 'wait',

  /**
   * Selection
   */
  // The table cell or set of cells can be selected.
  cell = 'cell',
  // Cross cursor, often used to indicate selection in a bitmap.
  crosshair = 'crosshair',
  // The text can be selected. Typically the shape of an I-beam.
  text = 'text',
  // The vertical text can be selected. Typically the shape of a sideways I-beam.
  verticalText = 'vertical-text',

  /**
   * Drag and Drop
   */
  // An alias or shortcut is to be created.
  alias = 'alias',
  // Something is to be copied.
  copy = 'copy',
  // Something is to be moved.
  move = 'move',
  // An item may not be dropped at the current location. [Firefox bug 275173](https://bugzil.la/275173): On Windows and macOS, no-drop is the same as not-allowed.
  noDrop = 'no-drop',
  // The requested action will not be carried out.
  notAllowed = 'not-allowed',
  // Something can be grabbed (dragged to be moved).
  grab = 'grab',
  // Something is being grabbed (dragged to be moved).
  grabbing = 'grabbing',

  /**
   * Resizing and Scrolling
   */
  // Something can be scrolled in any direction (panned). [Firefox bug 275174](https://bugzil.la/275174): On Windows, all-scroll is the same as move.
  allScroll = 'all-scroll',
  // The item/column can be resized horizontally. Often rendered as arrows pointing left and right with a vertical bar separating them.
  colResize = 'col-resize',
  // The item/row can be resized vertically. Often rendered as arrows pointing up and down with a horizontal bar separating them.
  rowResize = 'row-resize',

  /**
   * Some edge is to be moved. For example, the se-resize cursor is used when the movement starts from the south-east corner of the box.
   * In some environments, an equivalent bidirectional resize cursor is shown. For example, n-resize and s-resize are the same as ns-resize.
   */
  nResize = 'n-resize',
  eResize = 'e-resize',
  sResize = 's-resize',
  wResize = 'w-resize',
  neResize = 'ne-resize',
  nwResize = 'nw-resize',
  seResize = 'se-resize',
  swResize = 'sw-resize',
  ewResize = 'ew-resize',
  nsResize = 'ns-resize',
  neswResize = 'nesw-resize',
  nwseResize = 'nwse-resize',

  /**
   * Zooming
   */
  zoomIn = 'zoom-in',
  zoomOut = 'zoom-out',
}
