import { NgModule } from '@angular/core';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { FeatureToggleGuard } from './guards/feature-toggle.guard';

@NgModule({
  declarations: [
    FeatureToggleDirective
  ],
  exports: [
    FeatureToggleDirective,
  ],
  providers: [
    FeatureToggleGuard
  ]
})
export class FeatureToggleModule { }
