import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataWizardExtractErrorType, DataWizardModalActionsEnum, DataWizardModalOptions } from '../../../shared-models';

@Component({
  selector: 'mc-data-wizard-extract-error-modal',
  templateUrl: './data-wizard-extract-error-modal.component.html',
  styleUrls: ['./data-wizard-extract-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataWizardExtractErrorModalComponent {

  @Input() options: DataWizardModalOptions;

  @Output() modalAction = new EventEmitter<DataWizardModalActionsEnum>();

  public readonly DataWizardExtractErrorType = DataWizardExtractErrorType;

  public close(): void {
    this.modalAction.emit(DataWizardModalActionsEnum.Close);
  }

}
