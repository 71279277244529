import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  TopUserMenuAccountSettingsAction,
  TopUserMenuActionsUnion,
  TopUserMenuLogoutAction,
  TopUserMenuSubscriptionsAction
} from '../../models';
import { PermissionSelector } from '../../services';
import { selectWhoAmIUserName } from '../../store';

@Component({
  selector: 'mc-top-user-menu',
  templateUrl: './top-user-menu.component.html',
  styleUrls: ['./top-user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopUserMenuComponent {
  private readonly store = inject(Store);
  public readonly username$ = this.store.select(selectWhoAmIUserName);

  @Input() isHome = false;

  @Output() menuActionEmit = new EventEmitter<TopUserMenuActionsUnion>();

  public readonly PermissionSelector = PermissionSelector;

  public clickAccountSettings(): void {
    this.menuActionEmit.emit(new TopUserMenuAccountSettingsAction());
  }

  public clickSubscriptions(): void {
    this.menuActionEmit.emit(new TopUserMenuSubscriptionsAction());
  }

  public clickLogout(): void {
    this.menuActionEmit.emit(new TopUserMenuLogoutAction());
  }
}
