/** Selection of a Group Node in Indices or Locations Tree */
export interface AnalyseGroupNodeSelection {
  /** Type group selection */
  type: 'group';
  /** Unique Id of group node */
  nodeId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}

/** Selection of a Reference Node (Site, Country, Index) in Indices or Locations Tree */
export interface AnalyseReferenceNodeSelection {
  /** Type leaf selection */
  type: 'leaf';
  /** Unique Id of leaf node */
  nodeId: string;
  /** Unique Id of parent group node */
  parentGroupId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}


/** Selection of an Indicator Group */
export interface AnalyseIndicatorGroupNodeSelection {
  /** Type indicator group selection */
  type: 'indicator-group';
  /** Unique Id of the Indicator Group */
  indicatorGroupId: string;
  /**
   * Unique Id of the Reference node (e.g. Risk Index) for which
   * the indicator group is shown.
   */
  nodeId: string;
  /**
   * Unique Id of the parent group node of the Reference node (e.g. Risk Index)
   * for which the indicator group is shown.
   */
  parentGroupId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}

// As confirmed with Richard, both indicator group and
// indicator IDs should be globally unique within the entire tree
// returned by the backend. So while we need to keep track
// of the details of the Risk Index for which the indicator tree was retrieved,
// we do not need to include the Indicator Group ID of the parent group
// of an indicator leaf into the selection. Note: for purposes
// of front end Scorecard and Data Table tree structures, a risk indicator leaf
// can be a child of a Risk Index, it does not have to be a child
// of an indicator group.

/** Selection of an Indicator */
export interface AnalyseIndicatorNodeSelection {
  /** Type indicator leaf selection */
  type: 'indicator-leaf';

  /** Unique Id of the Indicator */
  indicatorId: string;
  /**
   * Unique Id of the Reference node (e.g. Risk Index) for which
   * the indicator group is shown.
   */
  nodeId: string;
  /**
   * Unique Id of the parent group node of the Reference node (e.g. Risk Index)
   * for which the indicator group is shown.
   */
  parentGroupId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}

/**
 * Enum for node selection types
 */
export enum AnalyseNodeSelectionTypes {
  group = 'group',
  leaf = 'leaf',
  indicatorGroup = 'indicator-group',
  indicator = 'indicator'
}

/** Selection of a Node in Indices or Locations Tree */
export type AnalyseNodeSelection =
  AnalyseGroupNodeSelection
  | AnalyseReferenceNodeSelection
  | AnalyseIndicatorGroupNodeSelection
  | AnalyseIndicatorNodeSelection;
