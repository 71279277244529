import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedPipesModule } from '../../shared-pipes';
import { CitationComponent } from './citation';
import { CitationPopoverDirective } from './citation-popover';

@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule
  ],
  declarations: [
    CitationComponent,
    CitationPopoverDirective
  ],
  exports: [
    CitationComponent,
    CitationPopoverDirective
  ]
})
export class CitationModule { }
