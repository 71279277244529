import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StickyTopElementDirective } from './sticky-top-element.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    StickyTopElementDirective
  ],
  exports: [
    StickyTopElementDirective
  ]
})
export class StickyTopElementModule { }
