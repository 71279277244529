import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

type IconSize = 'small' | 'medium' | 'large' | 'very-large';
type Position = 'right' | 'bottom';
type FontSize = 'small' | 'normal';
type Color = 'primary' | 'secondary';

@Component({
  selector: 'grid-ui-loading',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'data-hook': 'loading-spinner'
  },
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {

  @Input() public message = 'Loading, please wait.';
  @Input() public messagePosition: Position = 'bottom';
  @Input() public size: IconSize = 'small';
  @Input() public fontSize: FontSize = 'normal';
  @Input() public color: Color = 'primary';

  public constructor() { }

}
