import { Injectable } from '@angular/core';
import { ApiPaginationLinks } from '../models';
import { PaginationContext, PageQueryParameter } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  public getPageQueryParameter<T extends PageQueryParameter>(
    parameters: T | null,
    maximumPageSize: number,
    defaultPageSize: number
  ): PageQueryParameter {
    if (parameters === null) {
      return {
        page: 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        page_size: defaultPageSize
      };
    } else {
      const pageSize = parameters.page_size;
      return {
        page: parameters.page ? parameters.page : 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        page_size: pageSize && pageSize > 0
          ? pageSize <= maximumPageSize
            ? pageSize
            : maximumPageSize
          : defaultPageSize
      };
    }
  }

  public getNewPaginationContext<T extends PageQueryParameter>(
    apiLinks: ApiPaginationLinks,
    queryParameters: T | null
  ): PaginationContext<T> {
    const page: number | null = queryParameters && queryParameters.page ? queryParameters.page : null;
    return {
      prev: this.getQueryParameters(
        apiLinks ? apiLinks.prev : null,
        queryParameters,
        this.getPreviousPageNumber(page)),
      self: { ...(queryParameters as any) } as T,
      next: this.getQueryParameters(
        apiLinks ? apiLinks.next : null,
        queryParameters,
        this.getNextPageNumber(page))
    };
  }

  private getQueryParameters<T extends PageQueryParameter>(
    apiPaginationLink: string | null,
    queryParameters: T | null,
    page: number | null
  ): T | null {
    return apiPaginationLink === null || page === null
      ? null
      // HACK: Spread operator on generic types is still not
      // implemented on Typescript, one workaround is to cast it to object.
      // See https://github.com/Microsoft/TypeScript/issues/10727
      : { ...queryParameters as Record<string, unknown>, page: page } as T;
  }

  private getPreviousPageNumber(pageNumber: number | null): number | null {
    return pageNumber
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? (pageNumber! - 1)
      : null;
  }

  private getNextPageNumber(pageNumber: number | null): number {
    return pageNumber
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? (pageNumber! + 1)
      : 2;
  }
}
