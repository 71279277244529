export interface DownloadRequestResponse {
  download_request_id: string;
  filter: DownloadRequestFilter;
  status: `${DownloadRequestStatus}`;
  site_count: number;
  url: string;
  download_url: string;
}

export interface DownloadRequestFilter {
  location_id?: string[];
  type?: string[];
  location_name?: string[];
  location_name__icontain?: string;
  regions?: string[];
  customer_id?: string[];
  country_codes?: string[];
  country_name?: string[];
  exclude_ids?: string[];
  created__lte?: string;
  created__gte?: string;
  created__date__exact?: string;
  all?: boolean;
  sort?: string;
}

export enum DownloadRequestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
