import { Action } from '@ngrx/store';
import { NotificationBarNotificationInternal } from '../../models/notification.model';

export enum NotificationActionTypes {
  AddNotification = '[Notifications] Add notification',
  ExtractDownloadedFromNotificationBar = '[Notifications] Extract downloaded from notification bar',
  RemoveCurrentNotification = '[Notifications] Remove current notification',
  RemoveNotification = '[Notifications] Remove specified notification',
  Reset = '[Notifications] Reset',
}

export class AddNotification implements Action {
  public readonly type = NotificationActionTypes.AddNotification;

  constructor(public payload: NotificationBarNotificationInternal) {
  }
}

export class ExtractDownloadedFromNotificationBar implements Action {
  public readonly type = NotificationActionTypes.ExtractDownloadedFromNotificationBar;

  constructor(public payload: string) {
  }
}

export class RemoveNotification implements Action {
  public readonly type = NotificationActionTypes.RemoveNotification;

  constructor(public payload: number) {
  }
}

export class RemoveCurrentNotification implements Action {
  public readonly type = NotificationActionTypes.RemoveCurrentNotification;

  constructor() {
  }
}

export class Reset implements Action {
  public readonly type = NotificationActionTypes.Reset;

  constructor() {
  }
}

export type NotificationActions =
  AddNotification |
  ExtractDownloadedFromNotificationBar |
  RemoveNotification |
  RemoveCurrentNotification |
  Reset;
