import { createAction, props } from '@ngrx/store';

import { HttpErrorResponse } from '@angular/common/http';
import { WhoAmI } from '@grid-ui/api-models';
import {
  WhoAmIUserPersonalInformation, WhoAmIUserPersonalInformationChange
} from '../../../shared-models';

export enum WhoAmIActionTypes {
  GET_WHO_AM_I = '[Who Am I] Get',
  GET_WHO_AM_I_SUCCESS = '[Who Am I] Get Success',
  GET_WHO_AM_I_FAILED = '[Who Am I] Get Failed',
  LOAD_USER = '[Who Am I] Load User',
  LOAD_USER_SUCCEEDED = '[Who Am I] User Loaded Successfully',
  LOAD_USER_FAILED = '[Who Am I] User Load Failed',
  SAVE_USER = '[Who Am I] Save User',
  SAVE_USER_SUCCEEDED = '[Who Am I] User Saved Successfully',
  SAVE_USER_FAILED = '[Who Am I] User Save Failed',
  RESET_USER = '[Who Am I] Reset User',
  RESET_SAVE_USER = '[Who Am I] Reset User Save State'
}

export const getWhoAmI = createAction(WhoAmIActionTypes.GET_WHO_AM_I);

export const getWhoAmISuccess = createAction(WhoAmIActionTypes.GET_WHO_AM_I_SUCCESS, props<{ whoAmI: WhoAmI }>());

export const getWhoAmIFailed = createAction(WhoAmIActionTypes.GET_WHO_AM_I_FAILED, props<{ error: HttpErrorResponse }>());

export const whoAmILoadUser = createAction(WhoAmIActionTypes.LOAD_USER);

export const whoAmILoadUserSucceeded = createAction(
  WhoAmIActionTypes.LOAD_USER_SUCCEEDED,
  props<{ payload: WhoAmIUserPersonalInformation }>()
);

export const whoAmILoadUserFailed = createAction(
  WhoAmIActionTypes.LOAD_USER_FAILED,
  props<{ payload: HttpErrorResponse }>()
);

export const whoAmISaveUser = createAction(
  WhoAmIActionTypes.SAVE_USER,
  props<{ payload: WhoAmIUserPersonalInformationChange }>()
);

export const whoAmISaveUserSucceeded = createAction(
  WhoAmIActionTypes.SAVE_USER_SUCCEEDED,
  props<{ payload: WhoAmIUserPersonalInformation }>()
);

export const whoAmISaveUserFailed = createAction(
  WhoAmIActionTypes.SAVE_USER_FAILED,
  props<{ payload: HttpErrorResponse }>()
);

export const whoAmIResetUser = createAction(WhoAmIActionTypes.RESET_USER);

export const whoAmIResetSaveUser = createAction(WhoAmIActionTypes.RESET_SAVE_USER);
