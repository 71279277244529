import { Injectable } from '@angular/core';

import { Observable, combineLatest, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, switchAll, take, takeWhile, tap } from 'rxjs/operators';
import { API_SERVICES_CONFIG, PollingResourceConfig, format } from '@grid-ui/common';

import {
  DataWizardExtract,
  DataWizardExtractService,
  DocumentLibraryService,
} from '../../../api-services';

import {
  DataWizardExtractRequest,
  DataWizardExtractErrorType,
  DataWizardModalActionsEnum,
  mapPollStatusToDataWizardExtractErrorType,
  PollConfig,
  PollStatusEnum
} from '../../../shared-models';

import { PollingService, PollItem, PollingProgress } from '../../polling';

import { NotificationBarEmbeddedNotification, NotificationBarNotificationType } from '../models';
import { NotificationBarService } from './notification-bar.service';

import { DataWizardRootModalManagerService } from './data-wizard-root-modal-manager.service';


const POLL_STATUS_ERRORS: PollStatusEnum[] = [
  PollStatusEnum.Failed,
  PollStatusEnum.FailedDuplicate,
  PollStatusEnum.FailedScoringInProgress,
  PollStatusEnum.TimedOut,
  PollStatusEnum.ParameterRequired,
];

@Injectable()
export class DataWizardExtractProcessHandlerService {

  /**
   * Long-lived, flatten stream of PollStatusEnum.
   * This Observable won't complete even after an extract
   * has finished.
   */
  public pollingProgress$: Observable<PollingProgress>;

  /** Stream of boolean values that indicates whether extract notifications
   *  on side nav bar should be shown to user.
   *  True value is emitted upon new extract process start or an ongoing one
   *  is continued, and false value is emitted upon click on
   *  DownloadedFileModal buttons, notification bar
   *  buttons or (not implemented as of P2-2165) new generated document
   *  download.
   *  Active extract icon will be shown on side bar for as long the extract
   *  is active and completed until a false value is emitted by this stream.
   *  See P2-1729 XDs for details. *
   *  */
  public showSideNavNotification$: Observable<boolean>;

  /**
   * Higher order Subject that emits an stream of PollConfig for
   * each new extract.
   */
  private pollStatusSource = new Subject<Observable<PollConfig | undefined>>();
  private showSideNavNotificationSource = new Subject<boolean>();
  private currentNotificationId: number | null = null;
  private currentPollServerId: number | undefined;
  private currentPollItem: PollItem | null = null;
  private extractPollingConfig: PollingResourceConfig;

  constructor(
    private readonly pollingService: PollingService,
    private readonly dataWizardRootModalManagerService: DataWizardRootModalManagerService,
    private readonly notificationBarService: NotificationBarService,
    private readonly dataWizardExtractService: DataWizardExtractService,
    private readonly documentLibraryService: DocumentLibraryService
  ) {

    this.extractPollingConfig = API_SERVICES_CONFIG.v3.countryRisk.extract._configuration;

    this.showSideNavNotification$ = this.showSideNavNotificationSource;
    this.pollingProgress$ = this.pollStatusSource.pipe(
      switchAll(),
      filter(pollConfig => !!pollConfig),
      map<PollConfig, PollingProgress>(pollConfig => ({ status: pollConfig.status, id: pollConfig.extractId })),
      shareReplay(1)
    );

    this.pollingProgress$.pipe(
      filter(x => POLL_STATUS_ERRORS.includes(x.status))
    ).subscribe((pollStatus: PollingProgress) => {
      const errorType = mapPollStatusToDataWizardExtractErrorType(pollStatus.status);
      if (errorType) {
        this.cancelNotification();
        this.openErrorModal(errorType);
      }
    });
  }

  public getExtractDownloadUrl(): Observable<string> {
    const docsUrl = this.documentLibraryService.getDocumentsURLPathSegment('extracts');

    return this.pollingProgress$.pipe(
      filter(progress => progress.status === PollStatusEnum.Completed),
      map((progress: PollingProgress) =>
        progress.id ? format(docsUrl, { id: progress.id }) : ''
      ));
  }

  public cancel(): Promise<boolean> {
    return new Promise((res) => {
      const cancelModalResult = this.dataWizardRootModalManagerService.openConfirmCancelModal('Country Risk')
        .pipe(take(1));

      cancelModalResult
        .subscribe((action: DataWizardModalActionsEnum) => {
          switch (action) {
            case DataWizardModalActionsEnum.ContinueExtract:
              this.dataWizardRootModalManagerService.closeCurrentModal();
              res(true);
              break;
            case DataWizardModalActionsEnum.CancelExtract:
              res(false);
              this.cancelExtract();
              break;
          }
        });
    });
  }

  public checkOngoingExtract(): Observable<PollingProgress | null> {
    if (this.currentPollItem) {
      return this.pollingProgress$;
    } else {
      return this.dataWizardExtractService
        .getInProgressExtract()
        .pipe(
          take(1),
          catchError(() => of(null)),
          map((extract: DataWizardExtract | null) => {
            if (extract) {
              this.createNotification();
              this.currentPollServerId = extract.id;
              this.currentPollItem = this.pollingService.continueOngoingPoll(
                this.getResourceConfig(),
                this.currentPollServerId
              );
              this.pollStatusSource.next(this.currentPollItem.poll$);
              this.showSideNavNotificationSource.next(true);
              return this.pollingProgress$;
            }
            return of(null);
          }),
          switchAll()
        );
    }
  }

  public closeNotifications(): void {
    this.cancelNotification();
    this.showSideNavNotificationSource.next(false);
  }

  public setSideNavNotificationOpenState(open: boolean): void {
    this.showSideNavNotificationSource.next(open);
  }

  public start(extractRequest: DataWizardExtractRequest): Observable<PollingProgress> {
    this.createNotification();
    this.startNewPoll(extractRequest);

    return this.pollingProgress$;
  }

  private cancelExtract(): void {
    if (this.currentPollServerId) {
      this.dataWizardRootModalManagerService.setModalInputValue('loading', true);
      this.pollingProgress$.pipe(
        take(1),
        map(x => {
          if (!x.status || x.status === PollStatusEnum.Completed || !this.currentPollServerId) {
            return of(null);
          } else {
            return combineLatest([
              this.requestBackendExtractCancel(this.currentPollServerId),
              this.getCancelledPollStatus$()
            ]);
          }
        }),
        switchAll(),
        take(1)
      ).subscribe(() => {
        this.dataWizardRootModalManagerService.closeCurrentModal();
      }, () => {
        this.openErrorModal(DataWizardExtractErrorType.CANCEL_EXTRACT_FAILED);
      });
    } else {
      this.dataWizardRootModalManagerService.closeCurrentModal();
    }
  }

  private cancelNotification(): void {
    if (this.currentNotificationId) {
      this.notificationBarService.cancelNotification(this.currentNotificationId);
    }
  }

  private closeCurrentNotification(): void {
    this.notificationBarService.closeCurrentNotification();
  }

  private createNotification(): void {
    this.closeCurrentNotification();
    this.currentNotificationId = this.notificationBarService.createNotification(
      {
        type: NotificationBarNotificationType.embedded,
        embedded: NotificationBarEmbeddedNotification.dataExtract
      });
  }

  private getCancelledPollStatus$(): Observable<PollingProgress | null> {
    return this.currentPollItem
      ? this.pollingProgress$.pipe(filter(x => x.status === PollStatusEnum.Cancelled))
      : of(null);
  }

  private getResourceConfig(): PollingResourceConfig {
    // TODO: Uncomment below logic when CommodityRisk extract is implemented in backend.
    // const selectedModule = this.getSelectedModule(this.dataWizardFormGroup.value.selectedModule);
    // const pollingResourceConfig = selectedModule === 'country-risk'
    //   ? this.apiServicesConfig.v3.countryRisk.extract._configuration
    //   : this.apiServicesConfig.v3.commodityRisk.extract._configuration;
    const pollingResourceConfig = this.extractPollingConfig;
    return pollingResourceConfig;
  }

  private openErrorModal(errorType: DataWizardExtractErrorType): void {
    this.dataWizardRootModalManagerService.openErrorModal(errorType)
      .pipe(take(1))
      .subscribe(() => this.dataWizardRootModalManagerService.closeCurrentModal());
  }

  private requestBackendExtractCancel(pollServerId: number): Observable<DataWizardExtract> {
    return this.dataWizardExtractService.cancelExtract(pollServerId).pipe(
      tap(() => {
        this.cancelNotification();
        if (this.currentPollItem) {
          this.pollingService.cancel(this.currentPollItem.id);
        }
      })
    );
  }

  private startNewPoll(extractRequest: DataWizardExtractRequest): void {
    const pollingResourceConfig = this.getResourceConfig();
    this.currentPollItem = this.pollingService.start(pollingResourceConfig, extractRequest.body);

    this.currentPollItem.poll$
      .pipe(
        filter(pollConfig => !!pollConfig),
        takeWhile<PollConfig>(pollConfig => !pollConfig.serverPollingSessionId, true)
      ).subscribe(pollConfig => {
        this.currentPollServerId = pollConfig.serverPollingSessionId;
      });

    this.pollStatusSource.next(this.currentPollItem.poll$);
    this.showSideNavNotificationSource.next(true);
  }
}
