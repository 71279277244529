<div class="row-cell-content"
  [ngClass]="valueCategory || ''"
  data-hook="row-cell-content"
>
  <div class="score-container">
    <div class="score">
      <span>
        {{ value | number: scoreFormat }}
      </span>
    </div>
  </div>
</div>
