import {
  Component,
  Input,
  OnDestroy,
  ElementRef,
  Renderer2,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';

import { View, Spec, SignalRef, parse } from 'vega';

@Component({
  selector: 'grid-ui-vega-chart',
  templateUrl: './vega-chart.component.html',
  styleUrls: ['./vega-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VegaChartComponent implements AfterViewInit, OnDestroy {
  @Input() public data: Spec | null = null;
  @Input() public id: string | null = null;
  @Input() public scaled = false;

  private view: View | null = null;

  public constructor(
    private elem: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) { }

  public ngOnDestroy(): void {
    if (this.view) {
      this.view.finalize();
    }
  }

  public ngAfterViewInit(): void {
    if (!this.data) {
      return;
    }

    // For GRID-897 we are overwriting the width as the BE uses 520 which was the width of the content in Portal 1
    // BE team have a ticket to change this is to 720px: GRID-1380, once updated (and assuming all content from Nessie is now at 720)
    // then we should remove overwriting the width & height as it will not be required
    this.data.width = 720;
    this.data.height = (720 / this.getDimensionAsNumber(this.data.width)) * this.getDimensionAsNumber(this.data.height);

    this.vegaInit(this.data || {});
    if (this.scaled) {
      const width = this.getDimensionAsNumber(this.data.width);
      const height = this.getDimensionAsNumber(this.data.height);
      const svg = this.elem.nativeElement.querySelector('svg');
      this.renderer.setAttribute(svg, 'width', `${width * 2}`);
      this.renderer.setAttribute(svg, 'height', `${height * 2}`);
      this.renderer.setAttribute(svg, 'viewBox', `0 0 ${width} ${height}`);
      // vega starts with a clean svg each time so we can't style from normal css files
      this.renderer.setStyle(svg, 'background-color', 'white');
      this.renderer.setStyle(svg, 'top', '50%');
      this.renderer.setStyle(svg, 'width', '80%');
      this.renderer.setStyle(svg, 'height', 'auto');
      this.renderer.setStyle(svg, 'max-width', '80%');
      this.renderer.setStyle(svg, 'max-height', '80%');
      this.renderer.setStyle(svg, 'position', 'absolute');
      this.renderer.setStyle(svg, 'transform', 'translate(-50%, -50%)');
      this.renderer.setStyle(svg, 'margin-left', '50%');
      this.renderer.setStyle(svg, 'box-shadow', '0 5px 5px rgba(0, 0, 0, 0.3)');
      this.renderer.setStyle(svg, 'padding', '5px 15px');
      this.renderer.setStyle(svg, 'overflow', 'visible');
    }

  }

  private vegaInit(spec: Spec): void {
    this.view = new View(parse(spec))
      .renderer('svg')
      .initialize(`#${this.id}`)
      .hover()
      .run();
  }

  private getDimensionAsNumber(dimension: number | SignalRef | undefined): number {
    if (dimension && typeof dimension === 'number') {
      return dimension;
    }
    return 0;
  }

}
