<div class="image-container">
  <h5 *ngIf="value.title" class="image-header">{{value.title}}</h5>
  <h5 *ngIf="value.subtitle" class="image-sub-header"><span>{{value.subtitle}}</span></h5>
  <grid-ui-icon
    *ngIf="value.url !== IMAGE_NOT_FOUND"
    (click)="openExpander()"
    class="expand-icon"
    name="expand"
    ngbTooltip="Expand image"
    placement="top"
  >
  </grid-ui-icon>
  <img
    class="image image-expander"
    alt="{{value.alt}}"
    src="{{value.url}}"
    [ngStyle]="value.url === IMAGE_NOT_FOUND ? {'cursor':'auto'} : {'cursor':'pointer'}"
    (load)="handleImageLoaded()"
    (click)="openExpander()"
    (error)="imageLoadError($event)"
  >
  <div class='caption-text' *ngIf="value.caption || value.attribution">
    <span *ngIf="value.caption">{{ value.caption }}</span>
    <span class='attribution-text' *ngIf="value.attribution">
      Source: <span *ngIf="value.attribution" [innerHTML]="value.attribution"></span>
    </span>
  </div>
</div>
