import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastService } from '../toast.service';

@Component({
  selector: 'grid-ui-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { 'class': 'toast-container' }
})
export class ToastContainerComponent {

  public constructor(public toastService: ToastService, private sanitizer: DomSanitizer) { }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
