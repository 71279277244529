
import { SortCriterion } from '../parameterization';
import { IndexInfoPopoverToggleEvent } from '../index-info-popover-event.interface';
import { AnalyseNodeSelection } from './analyse-node-selection.type';
// TODO: @thomas This import needs to be refactored once Index Info Popovers are refactored


export enum AnalyseScoreTableActionType {
  LOCATION_TREE_NODE_SELECTED = '[Analyse ScoreTable] Location Select Tree Node',
  INDEX_TREE_NODE_SELECTED = '[Analyse ScoreTable] Index Select Tree Node',
  TOGGLE_INDEX_INFO_POPOVER = '[Analyse ScoreTable] Toggle Risk Index Info',
  TOGGLE_TREE_NODE_EXPAND = '[Analyse ScoreTable] Toggle Tree Node Expand',
  OPTIONS_CHANGE_FROM = '[Analyse ScoreTable] Change "Change From" Edition',
  OPTIONS_TOGGLE_CHANGE_FROM = '[Analyse ScoreTable] Toggle Change From',
  OPTIONS_TOGGLE_HISTORICAL_SCORE = '[Analyse ScoreTable] Toggle historical score',
  OPTIONS_TOGGLE_SHOW_WEIGHTS = '[Analyse ScoreTable] Toggle Show weights',
  SORT_CHANGED = '[Analyse ScoreTable] Change Sort Order',
  SORT_RESET = '[Analyse ScoreTable] Reset Sort Order',
  RETRY_GET_SCORECARD_DATA = '[Analyse ScoreTable] Retry ScoreTable Data Load',
  RELOAD_APP = '[Analyse ScoreTable] Reload App'
}

export class ScoreTableRetryGetData {
  public readonly type = AnalyseScoreTableActionType.RETRY_GET_SCORECARD_DATA;
  constructor() { }
}

export class ScoreTableReloadApp {
  public readonly type = AnalyseScoreTableActionType.RELOAD_APP;
  constructor() { }
}

export interface ScoreTableOptionsChangeFromPayload {
  /** "Change From" edition string in YYYY-QQ format, e.g. 2017-Q3*/
  changeFromEdition: string;
  /**
   * Flag indicating whether the Change From view should be automatically
   * toggled on for the scoretable emitting this action if the changeFromEdition
   * (i.e. start edition) is different from the current edition (i.e. end edition).
   */
  toggleOn?: boolean;
}

export class ScoreTableOptionsChangeFromAction {
  public readonly type = AnalyseScoreTableActionType.OPTIONS_CHANGE_FROM;
  constructor(public payload: ScoreTableOptionsChangeFromPayload) {}
}

export class ScoreTableOptionsToggleChangeFromAction {
  public readonly type = AnalyseScoreTableActionType.OPTIONS_TOGGLE_CHANGE_FROM;
  constructor() {}
}

export class ScoreTableOptionsToggleHistoricalScoreAction {
  public readonly type = AnalyseScoreTableActionType.OPTIONS_TOGGLE_HISTORICAL_SCORE;
  constructor() {}
}

export class ScoreTableOptionsToggleShowWeightsAction {
  public readonly type = AnalyseScoreTableActionType.OPTIONS_TOGGLE_SHOW_WEIGHTS;
  constructor() {}
}

export class ScoreTableLocationTreeNodeSelectedAction {
  public readonly type = AnalyseScoreTableActionType.LOCATION_TREE_NODE_SELECTED;
  constructor(public payload: { selectedNode: AnalyseNodeSelection }) {}
}

export class ScoreTableIndexTreeNodeSelectedAction {
  public readonly type = AnalyseScoreTableActionType.INDEX_TREE_NODE_SELECTED;
  constructor(public payload: { selectedNode: AnalyseNodeSelection }) { }
}

export class ScoreTableToggleIndexInfoPopoverAction {
  public readonly type = AnalyseScoreTableActionType.TOGGLE_INDEX_INFO_POPOVER;
  constructor(public payload: IndexInfoPopoverToggleEvent) {}
}

export class ScoreTableSortChanged {
  public readonly type = AnalyseScoreTableActionType.SORT_CHANGED;
  constructor(public sortCriterion: SortCriterion<string>) { }
}

export class ScoreTableSortReset {
  public readonly type = AnalyseScoreTableActionType.SORT_RESET;
  constructor() { }
}

export class ScoreTableToggleTreeNodeExpandAction {
  public readonly type = AnalyseScoreTableActionType.TOGGLE_TREE_NODE_EXPAND;
  constructor(public payload: { groupNodeId: string }) { }
}

export type AnalyseScoreTableOptionsActionUnion = ScoreTableOptionsToggleChangeFromAction
| ScoreTableOptionsToggleHistoricalScoreAction
| ScoreTableOptionsToggleShowWeightsAction
| ScoreTableOptionsChangeFromAction;

export type AnalyseScoreTableActionUnion = AnalyseScoreTableOptionsActionUnion
| ScoreTableToggleIndexInfoPopoverAction
| ScoreTableSortChanged
| ScoreTableSortReset
| ScoreTableLocationTreeNodeSelectedAction
| ScoreTableIndexTreeNodeSelectedAction
| ScoreTableReloadApp
| ScoreTableRetryGetData
| ScoreTableToggleTreeNodeExpandAction;
