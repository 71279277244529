import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as R from 'ramda';
import { QueryParams, PageQueryParameter } from '../models';
import { PaginationService } from './pagination.service';


@Injectable({
  providedIn: 'root'
})
export class QueryParametersService {

  public constructor(
    private readonly paginationService: PaginationService
  ) { }

  public correctPaginatedParameters<T extends PageQueryParameter>(
    params: T | null,
    maximumPageSize?: number,
    defaultPageSize?: number
  ): QueryParams {
    const correctedPaginationParams = maximumPageSize && defaultPageSize
      ? this.paginationService.getPageQueryParameter(params, maximumPageSize, defaultPageSize)
      : {};
    return { ...params as Record<string, unknown>, ...correctedPaginationParams };
  }

  /**
   * Takes a query parameters object and returns an HttpParams
   * object after filtering null|undefined|blank parameters and
   * with all the values turned to string.
   *
   * @param {T} params A query parameters object
   * @returns {HttpParams}
   */
  public prepareParameters<T extends PageQueryParameter>(
    params?: T
  ): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (!params) {
      return httpParams;
    }

    this.objectEntries(params)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, val]) => val !== undefined && val !== null && val !== '')
      .forEach(([key, val]) => {
        let entries: string[];
        if (val instanceof Array) {
          entries = val
            .map(v => !R.isNil(v) ? v.toString() : '');
        } else {
          entries = [!R.isNil(val) ? val.toString() : ''];
        }
        entries.forEach((entry: string) => httpParams = httpParams.append(key, entry));
      });
    return httpParams;
  }

  private objectEntries(obj: any): any[] {
    if (!Object.entries) {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = new Array(i); // preallocate the Array
      while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
      }

      return resArray;
    } else {
      return Object.entries(obj);
    }
  }

}
