import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionPanelComponent } from './accordion-panel/accordion-panel.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionPanelComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    AccordionComponent,
    AccordionPanelComponent
  ]
})
export class AccordionModule { }
