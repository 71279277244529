<div class="modal-content">
    <div class="modal-container">
        <div class="modal-body">
            <div class="modal-body-header">
                <grid-ui-icon
                    data-hook="ie-warning-modal-close-cross"
                    type="button"
                    id="dismissButton"
                    class="close-icon"
                    aria-label="Dismiss"
                    (click)="dismiss()"
                    name="cross"
                    ngbTooltip="Close window"
                    [useInlineSVG]="true"
                >
                </grid-ui-icon>
            </div>

            <h3 class="pt-5">
                We've noticed that you are using a web browser that we don't currently support.
            </h3>
            <div class="mt-4">
                To get the most out of the GRiD please use one of our supported browsers:
            </div>
            <div class="mt-5 warning-browser">
                <div class="d-inline-block w-25">
                    <div class="browser-img chrome-img" role="img" aria-label="Chrome"></div>
                    <div class="pt-2">Chrome</div>
                </div>
                <div class="d-inline-block w-25">
                    <div class="browser-img edge-img" role="img" aria-label="Edge"></div>
                    <div class="browser-name pt-2">Edge</div>
                </div>
                <div class="d-inline-block w-25">
                    <div class="browser-img firefox-img" role="img" aria-label="Firefox"></div>
                    <div class="pt-2">Firefox</div>
                </div>
                <div class="d-inline-block w-25">
                    <div class="browser-img safari-img" role="img" aria-label="Safari"></div>
                    <div class="pt-2">Safari</div>
                </div>
            </div>
            <div class="warning__contact mt-5 mb-5">
                Please <a href='mailto:support@maplecroft.com' target='_blank'>contact us</a> if you
                need any further help or information.
            </div>
            <button data-hook="ie-warning-modal-close-btn" class="btn mc-btn-primary" (click)="dismiss()">
                Close
            </button>
        </div>
    </div>
</div>
