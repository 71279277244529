export type ListRequestDeleteSiteResponse = PaginatedV4Response<RequestDeleteSiteResponse>;

export interface PaginatedV4Response<T> {
  total: number;
  results: T[];
  links: {
    next: string | null;
    self: string;
    prev: string | null;
  };
}

export interface RequestDeleteSiteResponse {
  id: string;
  filter: RequestDeleteSiteFilter;
  status: `${RequestDeleteSiteStatus}`;
  site_count: number;
  url: string;
}

export interface RequestDeleteSiteFilter {
  location_id?: string[];
  type?: string[];
  location_name?: string[];
  location_name__icontain?: string;
  regions?: string[];
  customer_id?: string[];
  country_codes?: string[];
  country_name?: string[];
  exclude_ids?: string[];
  created__lte?: string;
  created__gte?: string;
  created__date__exact?: string;
  all?: boolean;
}

export enum RequestDeleteSiteStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
