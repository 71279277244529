import {
  ActionReducerMap, createFeatureSelector, createSelector
} from '@ngrx/store';
import { AppState } from '../../../../core';
import * as fromPolling from './polling.reducer';

export interface PollingState {
  pollList: fromPolling.State;
}

export interface State extends AppState {
  polling: PollingState;
  requestCancel: boolean;
}

export const reducers: ActionReducerMap<PollingState> = {
  pollList: fromPolling.pollsReducer
};


export const selectPollingState = createFeatureSelector<PollingState>('polling');

export const selectPollList = createSelector(
  selectPollingState,
  (state) => state.pollList
);

export const selectAllPolls = createSelector(
  selectPollList,
  fromPolling.selectAll
);

export const selectPollById = (pollId: number) =>
  createSelector(
    selectPollList,
    pollsState => pollsState.entities[pollId]
  );

