import { Injectable } from '@angular/core';
import { PortalHttpClient } from '@grid-ui/common';

import { CountryRiskViewsBaseTreesSearchService } from '../../country-risk-views-base-trees-search';

@Injectable({
  providedIn: 'root'
})
export class CountryRiskViewsIndicesTreesSearchService extends CountryRiskViewsBaseTreesSearchService {

  constructor(
    retryableHttpClient: PortalHttpClient
  ) {
    super(
      'index',
      retryableHttpClient
    );
  }
}
