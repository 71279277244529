/**
 * Data Loading Request Status Flag Enum.
 * Use in components or store slices, which track
 * the current status of a request to the backend for data.
 *
 * If the enum needs to be used in a template, include a reference
 * to it in the component:
 * readonly LoadingRequestStatus = LoadingRequestStatus;
 */
export enum LoadingRequestStatus {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
  initial = 'initial'
}

/**
 * Data Saving Request Status Flag Enum.
 * Use in components or store slices, which track
 * the current status of a request to the backend for data.
 *
 * If the enum needs to be used in a template, include a reference
 * to it in the component:
 * readonly SavingRequestStatusEnum = SavingRequestStatus;
 */
export enum SavingRequestStatus {
  saving = 'saving',
  saved = 'saved',
  error = 'error',
  initial = 'initial'
}
