<div *ngIf="value" class="mc-table-item">
  <h4 *ngIf="!!value!.title" class="mc-table-title">{{value!.title}}</h4>

  <table class="table">
    <thead>
      <tr *ngIf="rowHeaders" class="mc-header-row">
        <th *ngFor="let col of rowHeaders" class="mc-col-header-cell" [innerHTML]="col | safeHtml"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of innerData; let i = index" class="mc-data-row">
        <th *ngIf="columnHeaders" class="mc-row-header-cell" [innerHTML]="columnHeaders[i]"></th>
        <td *ngFor="let col of row" [innerHTML]="col | updateLink"></td>
      </tr>
    </tbody>
  </table>

  <span class="attribution" *ngIf="value!.source">
    Source: <span class="source" [innerHTML]="value!.source"></span>
  </span>
</div>
