import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PaginationService, PortalHttpClient, API_SERVICES_CONFIG } from '@grid-ui/common';

import { AnalyseRedirectedResponse } from '../../../shared-models';

import { CountryRiskViewsBaseTreeService } from '../../country-risk-views-base-tree';
import { TreeBulkAddLeavesParameter, TreeBulkLeafAdditionIndividualBody } from '../../models';

@Injectable({
  providedIn: 'root'
})
/**
 * Service for accessing the Country Risk Configurations (Views) Indices Trees API.
 * Supports getting the Indices Tree associated with a given configuration as well
 * as API transactions related to Indices Tree editing
 */
export class CountryRiskViewsIndicesTreeService extends CountryRiskViewsBaseTreeService {

  constructor(
    retryableHttpClient: PortalHttpClient,
    paginationService: PaginationService,
  ) {
    super(
      'index',
      retryableHttpClient,
      paginationService
    );
    this.initApiConfigs(
      API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.index,
      API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.indexTree
    );
  }

  public bulkAddLeaves(
    viewId: number,
    params: TreeBulkAddLeavesParameter,
    body: TreeBulkLeafAdditionIndividualBody
  ): Observable<AnalyseRedirectedResponse> {
    return super.bulkAddLeaves(viewId, params, body);
  }

}
