import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { expand, map, reduce } from 'rxjs/operators';
import {
  API_SERVICES_CONFIG,
  PortalHttpClient,
  PaginatedResourceConfig,
  HttpGETCustomOptions,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  QueryParams,
  NonPaginatedResourceConfig,
} from '@grid-ui/common';

import { UserWorkflow, PaginatedUserWorkflows, RegistrationResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private workflowsResourceConfig: PaginatedResourceConfig;
  private registrationResourceConfig: NonPaginatedResourceConfig;
  private verifyResourceConfig: NonPaginatedResourceConfig;
  private activateResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient
  ) {
    this.workflowsResourceConfig = API_SERVICES_CONFIG.feApi.userWorkflow._configuration;
    this.registrationResourceConfig = API_SERVICES_CONFIG.feApi.register._configuration;
    this.verifyResourceConfig = API_SERVICES_CONFIG.feApi.verifyUser._configuration;
    this.activateResourceConfig = API_SERVICES_CONFIG.feApi.activateUser._configuration;
  }

  public getWorkflows(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    queryParams: QueryParams = {},
  ): Observable<PaginatedUserWorkflows> {
    return this.http.get<PaginatedUserWorkflows>(
      this.workflowsResourceConfig,
      { ...options, queryParams, },
    );
  }

  getAllWorkflows(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    queryParams: QueryParams = {},
  ): Observable<UserWorkflow[]> {
    let page = 1;

    return this.getWorkflows(options, queryParams).pipe(
      expand(data => data?.links?.next
        ? this.getWorkflows(options, { ...queryParams, page: ++page })
        : EMPTY
      ),
      reduce((acc, data) => ({ ...data, results: acc.results.concat(data.results) })),
      map(data => data.results),
    );
  }

  registerUser(userData: FormData): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(
      this.registrationResourceConfig,
      { body: userData , retryOptions: { customRetryAttempts: 0 } },
    );
  }

  verifyUser(
    email: string,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<void> {
    return this.http.post<void>(
      this.verifyResourceConfig,
      { ...options, body: { email }, retryOptions: { customRetryAttempts: 0 } },
    );
  }

  activateUser(
    activationKey: string,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<void> {
    const pathParams = { activationKey };

    return this.http.put<void>(
      this.activateResourceConfig,
      { ...options, pathParams, body: {} },
    );
  }
}
