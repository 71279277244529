export enum LocationsValidatorsMaxLength {
  DEFAULT = 255,
  CITY = 255,
  CUSTOMER_ID = 64,
  POST_CODE = 16,
  SITE_NAME = 255,
  NUMBER = 17
}

export enum LocationsValidatorsMinLength {
  SITE_NAME = 1
}
