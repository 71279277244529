export enum AnalyseProfilesActionTypes {
  CHANGE_COUNTRY = '[Analyse Profiles] Change Country',
  CHANGE_PROFILE_SECTION = '[Analyse Profiles] Change Profile Section',
  CHANGE_REPORT = '[Analyse Profiles] Change Report',
  NEXT_PAGE = '[Analyse Profiles] Next Profile Page',
  PREVIOUS_PAGE = '[Analyse Profiles] Previous Profile Page',
  RELOAD_APP = '[Analyse Profiles] Reload App',
  RESET = '[Analyse Profiles] Reset Profiles Content',
  RETRY_LOAD = '[Analyse Profiles] Retry loading Profile Content',
}

export interface AnalyseProfilesChangeCountryPayload {
  /**
   * Two character country code for the country whose insights are to be shown,
   * e.g. 'DE', 'CA'.
   */
  countryCode: string;
}

export class AnalyseProfilesChangeCountry {
  public readonly type = AnalyseProfilesActionTypes.CHANGE_COUNTRY;
  constructor(public payload: AnalyseProfilesChangeCountryPayload) { }
}


export class AnalyseProfilesChangeProfileSection {
  public readonly type = AnalyseProfilesActionTypes.CHANGE_PROFILE_SECTION;
  constructor(public payload: { profileId: number }) { }
}

export class AnalyseProfilesChangeReport {
  public readonly type = AnalyseProfilesActionTypes.CHANGE_REPORT;
  constructor(public payload: { profileId: number }) { }
}

export class AnalyseProfilesNextPage {
  public readonly type = AnalyseProfilesActionTypes.NEXT_PAGE;
  constructor() { }
}

export class AnalyseProfilesPreviousPage {
  public readonly type = AnalyseProfilesActionTypes.PREVIOUS_PAGE;
  constructor() { }
}

export class AnalyseProfilesReloadApp {
  public readonly type = AnalyseProfilesActionTypes.RELOAD_APP;
  constructor() { }
}

export class AnalyseProfilesReset {
  public readonly type = AnalyseProfilesActionTypes.RESET;
  constructor() { }
}

export class AnalyseProfilesRetryLoad {
  public readonly type = AnalyseProfilesActionTypes.RETRY_LOAD;
  constructor() { }
}

export type AnalyseProfilesActionUnion =
AnalyseProfilesChangeCountry
| AnalyseProfilesChangeProfileSection
| AnalyseProfilesChangeReport
| AnalyseProfilesNextPage
| AnalyseProfilesPreviousPage
| AnalyseProfilesReloadApp
| AnalyseProfilesReset
| AnalyseProfilesRetryLoad;
