import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';

import { LocationAttributeMappingService } from '../../services';

import { DataWizardUploadSessionResponse } from '../models';
import { DataWizardUploadMetadataResponse } from '../models/data-wizard-upload-metadata-response.model';


@Injectable({
  providedIn: 'root'
})
export class DataWizardUploadSitesSpreadsheetService {

  private resourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly locationAttributeMappingService: LocationAttributeMappingService,
    private readonly http: PortalHttpClient
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.spreadsheet._configuration;
  }

  public uploadSpreadsheet(file: File): Observable<DataWizardUploadSessionResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<DataWizardUploadSessionResponse>(
      this.resourceConfig,
      { body: formData }
    ).pipe(
      map(response => {
        const available_attributes = this.locationAttributeMappingService.mapAttributeKeysToLocationKeys(response.available_attributes);
        return {
          ...response,
          available_attributes
        };
      })
    );
  }


}
