import { ChangeDetectionStrategy, Component, Input, } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { AuthorInformation } from '../../../shared-models';
import { AskAnAnalystModalService } from '../services';
import { AskAnAnalystMetaData } from '../models';
import { AskAnAnalystSourceEnum } from '../../../shared-services/ask-an-analyst';

@Component({
  selector: 'grid-ui-ask-an-analyst-button',
  templateUrl: './ask-an-analyst-button.component.html',
  styleUrls: ['./ask-an-analyst-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AskAnAnalystButtonComponent {

  @Input() public analystInfo: AuthorInformation | null = null;
  @Input() public metaData: AskAnAnalystMetaData | null = null;
  @Input() public disabled = false;
  @Input() public showButtonLabelText = true;
  @Input() public tooltipPlacement: PlacementArray = 'bottom';
  @Input() public tooltipDisabled = false;
  @Input() public source: AskAnAnalystSourceEnum | undefined;
  @Input() public primaryButton = true;

  public get tooltipText() {
    return this.analystInfo !== null
      ? `Contact ${this.analystInfo.name.split(' ')[0]} for index or functionality queries`
      : 'Contact support team for index or functionality queries';
  }

  public constructor(
    private readonly askAnAnalystModalService: AskAnAnalystModalService
  ) { }

  public showModal(): void {
    this.askAnAnalystModalService.show(
      this.metaData,
      this.analystInfo,
      this.source as AskAnAnalystSourceEnum
    );
  }


  public get label():string{
    const isAskTheAnalyst = this.source===AskAnAnalystSourceEnum.CountryRiskInsights || this.source === AskAnAnalystSourceEnum.CommodityRiskInsights;

    if(isAskTheAnalyst){
      return 'Ask the Analyst';
    }

    return 'Support';
  }

}
