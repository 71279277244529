<section class="section-announcements-dialog">
  <div class="modal-header">
    <input ngbAutofocus class="no-size">
    <grid-ui-icon type="button" class="close-icon" aria-label="Cancel" data-hook="announcement-detail-modal-cancel"
      (click)="dismiss()" id="announcement-detail-modal-close-btn" ngbTooltip="Close window" name="cross-black">
    </grid-ui-icon>
  </div>

  <div class="announcement-notification" *ngFor="let announcement of announcements; let i = index">
    <ng-container *ngIf="i === currentAnnouncementIndex">
      <div class="modal-body">
        <svg *ngIf="announcement.type === 'GLOBAL'" class="announcement-image">
          <use xlink:href="#global-announcement" />
        </svg>
        <svg *ngIf="announcement.type === 'MODULE'" class="announcement-image">
          <use xlink:href="#module-announcement" />
        </svg>

        <h3 class="modal-body-title" data-hook="announcement-detail-modal-body-title">{{ announcement.title }}</h3>
        <div class="modal-body-text" *ngIf="announcement.text !== null" [innerHtml]="announcement.text! | safeHtml"
          data-hook="announcement-detail-modal-body-text"></div>

        <div>
          <div class="modal-footer-block">
            <label>
              <input type="checkbox" [(ngModel)]="announcement.isDismissed" (click)="toggle(announcement)">
              <span> Don't show me this again </span>
            </label>
          </div>
          <div class="modal-footer-block">
            <button class="btn mc-btn-primary" (click)="dismiss()" id="announcement-detail-modal-accept-and-close-btn">
              Accept
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div>
    <button (click)="previousAnnouncement()" class="previous-announcement" *ngIf="hasPreviousAnnouncement()">
      <grid-ui-icon class="chevron-left-icon" name="left-arrow-caraousel"></grid-ui-icon>
    </button>
  </div>
  <div>
    <button (click)="nextAnnouncement()" class="next-announcement" *ngIf="hasNextAnnouncement()">
      <grid-ui-icon class="chevron-right-icon" name="right-arrow-caraousel"></grid-ui-icon>
    </button>
  </div>

  <div class="dot-indicators">
    <span *ngFor="let announcement of announcements; let i = index" (click)="currentAnnouncementIndex = i"
      [class.active]="i === currentAnnouncementIndex" class="dot"></span>
  </div>
</section>