import { SortChangedEvent } from '@ag-grid-community/core';
import { GridSortModel } from './grid-sort-model.interface';

export interface CustomGridSortChangedEvent extends SortChangedEvent {
  type: 'sortChanged';
  readonly customType: 'customSortChanged';
  sortModel: GridSortModel;
}

export function isCustomGridSortChangedEvent(
  event: CustomGridSortChangedEvent | SortChangedEvent
): event is CustomGridSortChangedEvent {
  return (event as CustomGridSortChangedEvent).customType === 'customSortChanged';
}
