import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoMatchingResultsComponent } from './no-matching-results.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NoMatchingResultsComponent
  ],
  exports: [
    NoMatchingResultsComponent
  ]
})
export class NoMatchingResultsModule { }
