
import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  PaginationService,
  PortalHttpClient,
  RiskInsightsConfig
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  RiskInsightDetail,
  RiskInsightsCollection,
  RiskInsightsQueryParams,
  WrittenContentContext
} from '../../../shared-models';
import { ApiWrittenContentContext } from '../../models';
import { ApiRiskInsightDetail, ApiRiskInsightsCollection } from '../models';

/**
 * Abstract Service for accessing the Risk Insights API
 */
@Injectable()
export class RiskInsightsService {
  // TODO: Switch to API_SERVICES_CONFIG.nessie.insights, and modify models for new nessie API
  private readonly riskInsightConfig: RiskInsightsConfig = API_SERVICES_CONFIG.v3.countryRisk.content.insights;

  constructor(
    private readonly http: PortalHttpClient,
    private readonly paginationService: PaginationService
  ) {}

  /**
   * Get recent visited risk insight.
   */
  public getInsightRecentVisited(): Observable<RiskInsightsCollection> {
    return this.http.get<RiskInsightsCollection>(this.riskInsightConfig.recent._configuration, { forceAPICall: true });
  }

  /**
   * Get the specified risk insight.
   *
   * @param id Id of risk insight to retrieve.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getInsightDetail(
    id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<RiskInsightDetail> {
    // TODO: Caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiRiskInsightDetail>(
      this.riskInsightConfig.detail._configuration,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  /**
   * Get the context of the specified risk insight. The returned small data structure
   * is suitable to e.g. build the partial breadcrumbs trail for the specified insight, or generate
   * download links.
   *
   * @param id Id of risk insight to retrieve.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getInsightDetailContext(
    id: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<WrittenContentContext> {
    // TODO: Caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiWrittenContentContext>(
      this.riskInsightConfig.detail.context._configuration,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  /**
   * Get a list of all risk insights which a user is entitled to and meet the specified criteria.
   *
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all insights within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getInsights(
    queryParams: RiskInsightsQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<RiskInsightsCollection> {
    // TODO: Caching will be implemented using ETag, once P2-132 is addressed
    return this.http.getPaginated<ApiRiskInsightsCollection>(
      this.riskInsightConfig._configuration,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map(apiResponseContext => {
        const riskInsightsCollection: RiskInsightsCollection = {
          total: apiResponseContext.response.total,
          results: apiResponseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(
            apiResponseContext.response.links,
            apiResponseContext.queryParams
          )
        };
        if (apiResponseContext.response.replaced_search) {
          riskInsightsCollection.replaced_search = apiResponseContext.response.replaced_search;
        }
        if (apiResponseContext.response.suggestions) {
          riskInsightsCollection.suggestions = apiResponseContext.response.suggestions;
        }
        return riskInsightsCollection;
      })
    );
  }
}
