import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from '@ag-grid-community/angular';

import { IconModule } from '../icon';
import { CustomSelectableHeaderComponent } from './custom-selectable-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    AgGridModule.withComponents([
      CustomSelectableHeaderComponent
    ]),
  ],
  declarations: [
    CustomSelectableHeaderComponent,
  ],
  exports: [
    CustomSelectableHeaderComponent,
  ]
})
export class CustomSelectableHeaderModule { }
