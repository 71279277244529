import { HttpErrorResponse } from '@angular/common/http';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  CustomFilteredTotalUpdatedEvent,
  CustomRowDataLoadingFailedEvent,
  CustomRowDataLoadingSuccessEvent,
  CustomRowLoadingStartedEvent,
  PaginatedCollection,
} from '../../..';


export interface DataSourceParams {
  pageSize: number;
  forceAPICall: boolean;
  customRetryAttempts: number;
  handleOverlay?: boolean;
}


export abstract class AgGridDataSource {
  public services: Record<string, any>;
  public gridParams!: IServerSideGetRowsParams;
  public extraParams?: Record<string, any>;
  public params: DataSourceParams;
  public abstract getQueryParams(): Record<string, any>;
  public abstract getSource$(): Observable<any>;

  public get requestOptions() {
    return {
      forceAPICall: this.params.forceAPICall,
      retryOptions: { customRetryAttempts: this.params.customRetryAttempts },
    };
  }

  public constructor(
    services: Record<string, any>,
    params: Partial<DataSourceParams>,
    extraParams?: Record<string, any>,
  ) {
    this.services = services;
    this.extraParams = extraParams;
    this.params = {
      pageSize: params.pageSize ?? 30,
      forceAPICall: params.forceAPICall ?? true,
      customRetryAttempts: params.customRetryAttempts ?? 3,
      handleOverlay: params.handleOverlay ?? false,
    };
  }

  private setStartEvent(): void {
    this.gridParams.api.dispatchEvent({
      type: 'rowDataChanged',
      customType: 'rowLoadingStarted',
      api: this.gridParams.api,
      columnApi: this.gridParams.columnApi,
    } as CustomRowLoadingStartedEvent);
  }

  public getRows(gridParams: IServerSideGetRowsParams): void {
    this.gridParams = gridParams;
    const queryParams = this.getQueryParams();
    this.setStartEvent();
    this.getSource$().pipe(
      take(1),
      tap((collection: PaginatedCollection<any, any>) => {
        if (collection.paginationContext.self.page === 1) {
          const firstPageEvent: CustomFilteredTotalUpdatedEvent = {
            type: 'rowDataChanged',
            customType: 'filteredTotalUpdated',
            api: gridParams.api,
            columnApi: gridParams.columnApi,
            filteredTotal: collection.total,
            noMatches: collection.total === 0 && gridParams.api.isAnyFilterPresent()
          };
          gridParams.api.dispatchEvent(firstPageEvent);
        }
        const event: CustomRowDataLoadingSuccessEvent = {
          type: 'rowDataChanged',
          customType: 'rowDataLoadingSuccess',
          api: gridParams.api,
          columnApi: gridParams.columnApi,
          requestStartRow: gridParams.request.startRow,
          requestEndRow: gridParams.request.endRow,
          total: collection.total,
        };
        gridParams.api.dispatchEvent(event);
        if (collection.total === 0 && this.params.handleOverlay) gridParams.api.showNoRowsOverlay();
      })
    ).subscribe(
      (collection: PaginatedCollection<any, any>) => {
        // HACK: We are only pass in collection.total with the success callback of the latest page, for
        // all other pages we are passing in -1. If we placed the total in with every callback, ag-Grid would
        // adjust the infinite scroll area to allow scrolling/jumping to pages other than the next page. While
        // ag-Grid would handle the pages appropriately and supports this mode, it creates problems for the
        // "select all" logic we had to custom implement, as ag-Grid does not support "select all" for the
        // server-side row model. The -1 prevents skipping over pages, which could through of out tracking of
        // selected and excluded sites.
        const rowCount = collection.paginationContext.next !== null ? -1 : collection.total;
        gridParams.success({
          rowData: collection.results,
          rowCount: rowCount
        });
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404 && queryParams.page && queryParams.page > 1) {
          // HACK: Starting on AgGrid v25 the infinite scroll page is not reset anymore after
          // changing the filter criteria. This means that if the filtered pages are fewer
          // than before and the user was in a page greater than the new maximum, AgGrid
          // will try to fetch pages that don't exist, and our API will return 404s, putting
          // the table in an error state. Here we catch those 404s and tell AgGrid to ignore
          // them with an empty call to the success callback.
          // We only do this for page > 1, this way we may still catch proper 404s due to
          // other attributes in the requesting returning no results or an incorrect path.
          // TODO: Verify if on future releases the current page is reset with filter changes
          // in order to prevent requests that will result in 404s.
          gridParams.success({
            rowData: [],
            rowCount: this.params.pageSize,
          });
        } else {
          gridParams.failCallback();
          const event: CustomRowDataLoadingFailedEvent = {
            type: 'rowDataChanged',
            customType: 'rowDataLoadingFailed',
            api: gridParams.api,
            columnApi: gridParams.columnApi,
            error: err,
            initialPageError: gridParams.request.endRow <= this.params.pageSize,
          };
          gridParams.api.dispatchEvent(event);
          if (this.params.handleOverlay) gridParams.api.showNoRowsOverlay();
        }
      }
    );
  }

  public getSource(): IServerSideDatasource {
    return {
      getRows: this.getRows.bind(this),
    };
  }
}
