import { NgModule } from '@angular/core';
import {
  CommonModule,
  DecimalPipe
} from '@angular/common';

import { ImageUrlPipe } from './image-url.pipe';
import { FixedUpPipe } from './fixed-up.pipe';

const components = [
  ImageUrlPipe,
  FixedUpPipe,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components
  ],
  providers: [
    DecimalPipe
  ]
})
export class GridSharedPipesModule { }
