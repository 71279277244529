import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments';
import { WhoAmIService } from '../../api-services';
import { WindowUtilitiesService } from '../../shared-utilities/window-services';
// eslint-disable-next-line import/no-unresolved


@Injectable()
export class SitePermissionGuard implements CanActivate {

  constructor(
    private whoAmIService: WhoAmIService,
    private windowUtilitiesService: WindowUtilitiesService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.whoAmIService.getPermissions().pipe(
      map(permissions => {
        if (environment.bypassNoAccessCheck || permissions['grid-application']) {
          return true;
        }
        this.windowUtilitiesService.setHref('/no-access.html');
        return false;
      })
    );
  }
}
