import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { PermissionSelector, PermissionsService } from '../../services';

@Directive({
  selector: '[mcPermission]'
})
export class PermissionDirective implements OnInit {

  @Input() mcPermission: PermissionSelector;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly permissionsService: PermissionsService
  ) {
  }

  public ngOnInit(): void {
    if (this.mcPermission) {
      this.permissionsService.isAllowed(this.mcPermission).then(allowed => {
        if (allowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
    }
  }
}
