export enum TopUserMenuActionTypes {
  LOGOUT = '[User Menu] Logout',
  ACCOUNT_SETTINGS = '[User Menu] Account Settings',
  SUBSCRIPTIONS = '[User Menu] Subscriptions'
}

export class TopUserMenuLogoutAction {
  public readonly type = TopUserMenuActionTypes.LOGOUT;
  constructor() {}
}

export class TopUserMenuAccountSettingsAction {
  public readonly type = TopUserMenuActionTypes.ACCOUNT_SETTINGS;
  constructor() {}
}

export class TopUserMenuSubscriptionsAction {
  public readonly type = TopUserMenuActionTypes.SUBSCRIPTIONS;
  constructor() {}
}

export type TopUserMenuActionsUnion =
TopUserMenuLogoutAction
| TopUserMenuAccountSettingsAction
| TopUserMenuSubscriptionsAction;
