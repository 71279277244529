import { ApiServicesConfigModel, format } from '@grid-ui/common';

/**
 * Function used to build the PDF download URLs for
 * CommodityRisk and CountryRisk modules and their Insights and Profiles submodules.
 * @param module
 * @param subModule
 * @param apiServiceConfig API service conflict object
 */
export function downloadPdfUrlBuilder(
  module: Extract<keyof ApiServicesConfigModel['v3'], 'countryRisk' | 'commodityRisk'>,
  subModule: Extract<
  keyof ApiServicesConfigModel['v3']['countryRisk']['content'] | ApiServicesConfigModel['v3']['commodityRisk']['content']
  , 'insights' | 'profiles'>,
  apiServiceConfig: ApiServicesConfigModel
): (id: string | number | null) => string {
  return (id: number | string | null) =>
    format(apiServiceConfig.v3[module].content[subModule].detail._configuration.path, { id }) + '?type=pdf';
}
