import { Component, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromNotificationBar from '../../store/reducers';
import {
  NotificationBarNotification,
  NotificationBarNotificationType,
  NotificationBarEmbeddedNotification
} from '../../models/notification.model';

import { NotificationBarService } from '../../services/notification-bar.service';


@Component({
  selector: 'mc-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent {
  @Output() isVisible = new EventEmitter<boolean>();

  public closeText = 'close';
  public notification$: Observable<NotificationBarNotification | null>;
  public NotificationBarNotificationType = NotificationBarNotificationType;
  public NotificationBarEmbeddedNotification = NotificationBarEmbeddedNotification;

  constructor(
    private readonly store: Store<fromNotificationBar.State>,
    private readonly notificationBarService: NotificationBarService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.notification$ = this.store.pipe(select(fromNotificationBar.getCurrentNotification))
      .pipe(tap(notification => {
        this.isVisible.emit(!!notification);
        this.cdr.detectChanges();
      }));
  }

  public close(): void {
    this.notificationBarService.closeCurrentNotification();
  }

}
