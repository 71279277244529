import { ContentBlock } from '../content-choice';
import { CommodityRiskProfile } from '../commodity-risk';

// TODO: This typeguard may not yield expected result given
// that ContentBlock covers both Commodity Risk and Country Risk
// Possibly needs to be revised.

export function isCommodityRiskProfile(block: ContentBlock): block is CommodityRiskProfile {
  return Object.prototype.hasOwnProperty.call(block,'grouped')
    && !Object.prototype.hasOwnProperty.call(block, 'region');
}
