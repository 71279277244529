import { IServerSideGetRowsParams } from '@ag-grid-community/core';

import { updateQueryParametersWithFields } from './update-query-parameters-with-fields.util';
import { mapGridSortModelToApiSortParam } from './map-grid-sort-model-to-api-sort-param.util';
import { updateQueryParametersWithFilters } from './update-query-parameters-with-filters.util';


interface RequestParams {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  page_size: number;
}

interface QueryParams extends RequestParams {
  page: number;
  sort?: string;
}

interface ModelParams {
  mandatoryFields?: string[];
  legacyFilter?: boolean;
}

export function mapGridParamsToQueryParams<T>(
  gridParams: IServerSideGetRowsParams,
  reqParams: T & RequestParams,
  modelParams?: ModelParams,
): T & QueryParams {
  const page = Math.floor((gridParams.request.endRow - 1) / reqParams.page_size) + 1;
  let queryParams: T & QueryParams = { page, ...reqParams };
  const sort = mapGridSortModelToApiSortParam(gridParams.request.sortModel);

  if (sort) {
    queryParams.sort = sort;
  }

  if (gridParams.request.filterModel) {
    queryParams = updateQueryParametersWithFilters(
      queryParams,
      gridParams.request.filterModel,
      undefined,
      modelParams?.legacyFilter,
    ) as T & QueryParams;
  }

  if (modelParams?.mandatoryFields?.length) {
    queryParams = updateQueryParametersWithFields(
      queryParams,
      modelParams.mandatoryFields,
      gridParams.columnApi.getAllDisplayedColumns().map(col => col.getColId())
    );
  }

  return queryParams;
}
