import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '@grid-ui/common';
import { LoadingModule } from '../loading';
import { ErrorModule } from '../error';
import { NoMatchingResultsModule } from '../no-matching-results';
import { NoDataAvailableModule } from '../no-data-available';
import { SearchInputModule } from '../search-input';

import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';

@NgModule({
  declarations: [SearchableDropdownComponent],
  exports: [SearchableDropdownComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    ErrorModule,
    LoadingModule,
    NoDataAvailableModule,
    NoMatchingResultsModule,
    SearchInputModule,
    IconModule
  ]
})
export class SearchableDropdownModule { }
