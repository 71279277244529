import { RetryOrReload } from '../retry-or-reload.enum';
import { ItemInformationListingItemDatatype, ItemInformationListingActionItemPayload } from '../item-information-listing';
import { NoViewsActions } from '../no-views-actions.enum';

/** Action types supported by the Risk View Selection Dropdown */
export enum RiskViewSelectionDropdownActionTypes {
  CHANGE_SEARCH_TERM = '[Risk View Selection Dropdown] Change Search Term for list filtering',
  CHANGE_TAB = '[Risk View Selection Dropdown] Change Active Tab',
  HANDLE_OPEN = '[Risk View Selection Dropdown] Handle open/close of Risk View Selection Dropdown',
  LOAD_MORE = '[Risk View Selection Dropdown] Load more items for active, paginated tab',
  NO_VIEWS_ACTION = '[Risk View Selection Dropdown] Perform "no views" action for current tab',
  RETRY_OR_RELOAD = '[Risk View Selection Dropdown] Retry load of items for current tab or Reload App',
  SELECT_ITEM = '[Risk View Selection Dropdown] Select item (view, country, risk index, site)',
  TOGGLE_SHOW_INFO = '[Risk View Selection Dropdown] Toggle information row for an item'
}

/** Action emitted when the Risk View Selection Dropdown search term should change. */
export class RiskViewSelectionDropdownChangeSearchTerm {
  public readonly type = RiskViewSelectionDropdownActionTypes.CHANGE_SEARCH_TERM;
  constructor(public payload: { searchTerm: string }) { }
}

/** Action emitted when the Risk View Selection Dropdown active tab should change. */
export class RiskViewSelectionDropdownChangeTab {
  public readonly type = RiskViewSelectionDropdownActionTypes.CHANGE_TAB;
  constructor(public payload: { tabIndex: number }) { }
}

/** Action emitted when the Risk View Selection Dropdown is opened or closed. */
export class RiskViewSelectionDropdownHandleOpen {
  public readonly type = RiskViewSelectionDropdownActionTypes.HANDLE_OPEN;
  constructor(public payload: { opened: boolean }) { }
}

/** Action emitted when the active Risk View Selection Dropdown tab should load more items (next page). */
export class RiskViewSelectionDropdownLoadMore {
  public readonly type = RiskViewSelectionDropdownActionTypes.LOAD_MORE;
  constructor() { }
}

/**
 * Action emitted when the Risk View Selection Dropdown active tab has no available items and the user requested
 * the performance of the applicabe "no views action".
 */
export class RiskViewSelectionDropdownNoViewsAction {
  public readonly type = RiskViewSelectionDropdownActionTypes.NO_VIEWS_ACTION;
  constructor(public payload: NoViewsActions) { }
}

/**
 * Action emitted when the Risk View Selection Dropdown experienced an error and the user requested
 * a retry of the item load for the active tab, or asked for a reload.
 */
export class RiskViewSelectionDropdownRetryOrReload {
  public readonly type = RiskViewSelectionDropdownActionTypes.RETRY_OR_RELOAD;
  constructor(public payload: RetryOrReload) { }
}

/** Action emitted when an item (view, index, country, site) has been selected in the Risk View Selection Dropdown. */
export class RiskViewSelectionDropdownSelectItem<
  T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype
> {
  public readonly type = RiskViewSelectionDropdownActionTypes.SELECT_ITEM;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/** Action emitted when the information row for an item in the Risk View Selection Dropdown should be toggled on/off. */
export class RiskViewSelectionDropdownToggleShowInfo<
  T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype
> {
  public readonly type = RiskViewSelectionDropdownActionTypes.TOGGLE_SHOW_INFO;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/** Union type of actions supported by the Analyse Toolbar */
export type RiskViewSelectionDropdownActionsUnion<
  T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype
> =
    RiskViewSelectionDropdownChangeSearchTerm
    | RiskViewSelectionDropdownChangeTab
    | RiskViewSelectionDropdownHandleOpen
    | RiskViewSelectionDropdownLoadMore
    | RiskViewSelectionDropdownNoViewsAction
    | RiskViewSelectionDropdownRetryOrReload
    | RiskViewSelectionDropdownSelectItem<T>
    | RiskViewSelectionDropdownToggleShowInfo<T>;
