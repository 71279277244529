import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  NonPaginatedResourceConfig,
  API_SERVICES_CONFIG,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions
} from '@grid-ui/common';

import { CountryChoice, ApiCountryChoice } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AllCountriesService {

  private resourceConfig: NonPaginatedResourceConfig;
  private feResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly httpClient: PortalHttpClient
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.feApi.allCountries._configuration;
    this.feResourceConfig = API_SERVICES_CONFIG.feApi.allCountries._configuration;
  }

  /**
   * Get a complete list of all country choices.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getAllCountries(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<CountryChoice[]> {
    return this.httpClient.get<ApiCountryChoice[]>(this.resourceConfig, options);
  }

  public getAllCountriesFE(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<CountryChoice[]> {
    return this.httpClient.get<ApiCountryChoice[]>(this.feResourceConfig, options);
  }
}
