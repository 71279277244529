import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon';
import { ButtonBackToTopComponent } from './button-back-to-top.component';

@NgModule({
  providers: [],
  imports: [CommonModule, IconModule],
  declarations: [ButtonBackToTopComponent],
  exports: [ButtonBackToTopComponent],
})
export class ButtonBackToTopModule {}
