import { ItemInformationListingItemDatatype } from '../item-information-listing';
import { CountryRiskProfilesSubscriptionItem } from '../written-content';

/**
 * Typeguard to determine whether the specified data for an Item Information Listing
 * support unique identification by "profile_id" property.
 *
 * @param data An Item Information Listing item.
 */
export function isProfileIdDatatype(data: ItemInformationListingItemDatatype): data is CountryRiskProfilesSubscriptionItem {
  return (data as any).profile_id !== undefined;
}
