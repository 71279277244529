import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';

import { IconModule, LoadingModule } from '@grid-ui/common';
import { GridSharedPipesModule } from 'src/app/shared-ui-components/shared-pipes';
import { DataWizardExtractModalsModule } from '../../shared-ui-components/data-wizard-extract-modals';

import { reducers } from './store/reducers';

import {
  NotificationBarComponent,
  ExtractNotificationComponent,
  DataTableExtractNotificationComponent
} from './components';

import {
  CountryRiskDataTableExtractNotificationService,
  CountryRiskDataTableExtractProcessHandlerService,
  DataWizardExtractNotificationService,
  DataWizardExtractProcessHandlerService,
  DataWizardRootModalManagerService,
  NotificationBarService
} from './services';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('notificationBar', reducers),
    DataWizardExtractModalsModule,
    GridSharedPipesModule,
    LoadingModule,
    IconModule
  ],
  declarations: [
    NotificationBarComponent,
    ExtractNotificationComponent,
    DataTableExtractNotificationComponent
  ],
  exports: [
    NotificationBarComponent,
  ],
  providers: [
    DataWizardExtractNotificationService,
    CountryRiskDataTableExtractNotificationService,
    CountryRiskDataTableExtractProcessHandlerService,
    DataWizardExtractProcessHandlerService,
    DataWizardRootModalManagerService,
    NotificationBarService
  ]
})
export class ExtractManagementModule {
}
