import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchInputComponent } from './search-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    SearchInputComponent

  ],
  exports: [
    SearchInputComponent

  ]
})
export class SearchInputModule {
}
