import * as R from 'ramda';


export function updateQueryParametersWithFields<T>(
  queryParams: T,
  mandatoryFields: string[],
  visibleColumns: string[]
) {
  const newQueryParam = R.clone(queryParams) as T & { fields: string[] };
  newQueryParam.fields = visibleColumns.length
    ? visibleColumns
    : mandatoryFields;
  return newQueryParam;
}
