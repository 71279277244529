import { ActionReducerMap, createFeatureSelector, createSelector, } from '@ngrx/store';


import { AppState } from '../../../../core';
import * as fromNotifications from './notifications';

export interface NotificationBarState {
  notifications: fromNotifications.State;
}

export interface State extends AppState {
  notificationBar: NotificationBarState;
}

export const reducers: ActionReducerMap<NotificationBarState> = {
  notifications: fromNotifications.reducer
};

// Get whole feature store state

export const getFeatureState = createFeatureSelector<NotificationBarState>('notificationBar');

// Get slices

export const getNotificationBarSlice = createSelector(
  getFeatureState,
  (state) => state.notifications
);

// Get parts of state

export const getQueue = createSelector(
  getNotificationBarSlice,
  fromNotifications.getQueue
);

export const getCurrentNotification = createSelector(
  getQueue,
  (queue) => queue && queue.length > 0 ? queue[0] : null
);

export const getDownloadedExtractUrl = createSelector(
  getNotificationBarSlice,
  fromNotifications.getDownloadedExtractUrl
);

