import { createAction, props } from '@ngrx/store';
import { AnnouncementVM } from './global-module-announcement.reducer';

export enum GlobalModuleAnnouncementActions {
    LoadAnnouncements = '[GlobalModuleAnnouncement] Load Announcements',
    LoadAnnouncementsSucceeded = '[GlobalModuleAnnouncement] Announcements Loaded Successfully',
    LoadAnnouncementsFailed = '[GlobalModuleAnnouncement] Announcements Load Failed',
    DismissAnnouncements = '[GlobalModuleAnnouncement] Dismiss Announcement',
}

const load = createAction(GlobalModuleAnnouncementActions.LoadAnnouncements);

const loadSucceeded = createAction(
    GlobalModuleAnnouncementActions.LoadAnnouncementsSucceeded,
    props<{ payload: { globalAnnouncements: AnnouncementVM[]; moduleAnnouncements: AnnouncementVM[]; dismissedItems: string[] | null } }>()
);

const loadFailed = createAction(
    GlobalModuleAnnouncementActions.LoadAnnouncementsFailed,
    props<{ payload: { error: Error } }>()
);

const dismiss = createAction(
    GlobalModuleAnnouncementActions.DismissAnnouncements,
    props<{ payload: string[] }>()
);

export const GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS = {
    load,
    loadSucceeded,
    loadFailed,
    dismiss
};
