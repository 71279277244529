<mc-notification-bar (isVisible)="handleNotificationBarUpate($event)"></mc-notification-bar>

<div [class.notification-bar-open]="notificationBarOpen">
  <ng-container *ngIf="showIEWarning">
    <div class="mc-ie-warning-overlay w-100"></div>
    <mc-ie-warning (warningDismissed)="dismissWarning()"> </mc-ie-warning>
  </ng-container>
  <ng-container *ngIf="isPreviewMode">
    <mc-preview-mode-watermark></mc-preview-mode-watermark>
  </ng-container>
  <mc-app-top-area (actionEmit)="handleTopMenuAction($event)">
  </mc-app-top-area>
  <div class="mc-main-area-container" [class.with-notification-bar]="notificationBarOpen">
    <mc-app-side-area [class.mc-side-navigation-shown]="!isSplashScreen"
      [showSideNavigation]="!isSplashScreen" [isNotificationBarOpen]="notificationBarOpen">
    </mc-app-side-area>
    <div class="mc-main-area" [class.mc-side-navigation-shown]="!isSplashScreen">
      <div *ngIf="loading">
        <div class="
            loading-wrapper
            d-flex
            justify-content-center
            align-items-center
          ">
          <mc-loading
            [size]="'large'"
          ></mc-loading>
        </div>
      </div>
      <div [hidden]="loading" class="mc-main-area-loading-container"
        [class.announcement-active]="announcementBarActive">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<grid-ui-toast-container></grid-ui-toast-container>
<mc-cookies-notification-banner *ngIf="privacySettingsStorageService.showCookiesBannerChanges | async"
(action)="handleCookiesNotificationBannerAction($event)">
</mc-cookies-notification-banner>
