import { Injectable } from '@angular/core';

import {
  AutoSize,
  Axis,
  Data,
  Mark,
  Scale,
  ScaleType,
  Spec,
  AxisOrient,
  Encode,
  EncodeEntry
} from 'vega';
import { VegaVersion2Spec } from '../../../shared-models';

type OldScale = any;
type OldAxis = any;
type OldAxisProperties = any;
type OldMark = any;
type OldMarkProperties = any;
interface AxisEncode {
  ticks?: any;
  domain?: any;
  grid?: any;
}
interface MarkEncode {
  x?: any;
  y?: any;
}

@Injectable({
  providedIn: 'root'
})
export class VegaSchemaUtilsService {

  public getV3Schema(v2Schema: VegaVersion2Spec): Spec {
    if (v2Schema) {
      const scales = this.getScales(v2Schema);
      const axes = this.getAxes(v2Schema);
      const autosize = this.getAutosize(v2Schema);
      const marks = this.getMarks(v2Schema);
      const data = this.getData(v2Schema);
      const width = v2Schema.width;
      const height = v2Schema.height;

      return {
        scales,
        axes,
        autosize,
        marks,
        data,
        width,
        height
      };
    }
    return {};
  }

  private getAutosize(v2Schema: VegaVersion2Spec): AutoSize {
    const padding: string = v2Schema.padding || null;
    if (padding === 'strict') {
      return 'fit';
    }
    if (padding === 'auto') {
      return 'pad';
    }
    return 'none';
  }

  private getAxes(v2Schema: VegaVersion2Spec): Axis[] {
    return v2Schema.axes ? v2Schema.axes.map(this.toAxis) : [];
  }

  private getAxisEncode(oldAxisProperties: OldAxisProperties): AxisEncode {
    return {
      ...(oldAxisProperties.ticks !== undefined ? { ticks: { update: oldAxisProperties.ticks } } : {}),
      ...(oldAxisProperties.axis !== undefined ? { domain: { update: oldAxisProperties.axis } } : {}),
      ...(oldAxisProperties.grid !== undefined ? { grid: { update: oldAxisProperties.grid } } : {}),
    };
  }

  private getAxisOrient(axis: OldAxis): AxisOrient {
    if (axis.type === 'x') {
      return 'bottom';
    }
    return 'left';
  }

  private getData(v2Schema: VegaVersion2Spec): Data[] {
    return v2Schema.data;
  }

  private getMarks(v2Schema: VegaVersion2Spec): Mark[] {
    return v2Schema.marks ? v2Schema.marks.map(this.toMark) : [];
  }

  private getMarkEncode(oldMarkProperties: OldMarkProperties): Encode<EncodeEntry> {
    const markY: { value: number } | any = this.getMarkVPos(oldMarkProperties);
    return Object.keys(oldMarkProperties).length > 0 && oldMarkProperties.constructor === Object ?
      { ...oldMarkProperties, ...markY } : {};
  }

  private getMarkVPos(oldMarkProperties: OldMarkProperties): Encode<EncodeEntry> {
    return {
      ...(oldMarkProperties.enter !== undefined ? {
        enter: {
          ...oldMarkProperties.enter, ...{
            ...(oldMarkProperties.enter.y !== undefined ? {
              y: {
                ...oldMarkProperties.enter.y, ...(oldMarkProperties.enter.y['value'] !== undefined ? {
                  ...oldMarkProperties.enter.y, ...{ offset: 10 }
                } : oldMarkProperties.enter.y)
              }
            } : {})
          }
        }
      } : {}),
    };
  }

  private getScales(v2Schema: VegaVersion2Spec): Scale[] {
    return v2Schema.scales ? v2Schema.scales.map(this.toScale) : [];
  }

  /** Some Scale types were break up */
  private getScaleType(oldScale: OldScale): ScaleType {
    if (oldScale.type === 'ordinal') {
      if (oldScale.points === true) {
        return 'point';
      }
      // TODO: Match another patterns for other types
      return oldScale.type;
    }
    return oldScale.type;
  }

  private toAxis = (oldAxis: OldAxis): Axis => ({
    scale: oldAxis.scale,
    orient: this.getAxisOrient(oldAxis),
    format: oldAxis.format,
    ...(oldAxis.values !== undefined ? { values: oldAxis.values } : {}),
    ...(oldAxis.offset !== undefined ? { offset: oldAxis.offset } : {}),
    ...(oldAxis.grid !== undefined ? { grid: oldAxis.grid } : {}),
    ...(oldAxis.properties !== undefined ? { encode: this.getAxisEncode(oldAxis.properties) } : {})
  });

  private toMark = (oldMark: OldMark): Mark => ({
    type: oldMark.type,
    ...(oldMark.from !== undefined ? { from: oldMark.from } : {}),
    ...(oldMark.name !== undefined ? { name: oldMark.name } : {}),
    ...(oldMark.properties !== undefined ? { encode: this.getMarkEncode(oldMark.properties) } : {})
  });

  private toScale = (oldScale: OldScale): Scale => {
    const scaleType: ScaleType = this.getScaleType(oldScale);
    return {
      range: oldScale.range,
      type: scaleType as any,
      name: oldScale.name,
      domain: oldScale.domain,
      padding: oldScale.padding
    };
  };

}
