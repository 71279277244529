import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe to prefix the argument string with a specified prefix string
 */
@Pipe({
  name: 'prefix'
})
export class PrefixPipe implements PipeTransform {

  /**
   * Prefix the value string with the specified prefix by concatenation
   *
   * @param value The string to prefix
   * @param prefix Prefix string to use
   */
  public transform(value: string, prefix: string = ''): string {
    return `${prefix}${value}`;
  }

}
