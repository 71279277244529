import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { IconModule } from '@grid-ui/common';
import { ErrorModule } from '../shared-ui-components/error';
import { LoadingModule } from '../shared-ui-components/loading';
import { GridSharedPipesModule } from '../shared-ui-components/shared-pipes/shared-pipes.module';
import { HomeComponent, HomeServiceOfferingCardComponent } from './home';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ErrorModule,
    LoadingModule,
    GridSharedPipesModule,
    IconModule
  ],
  declarations: [
    HomeComponent,
    HomeServiceOfferingCardComponent
  ],
  exports: [
    HomeComponent
  ],
  providers: []
})
export class HomeModule { }
