import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

import * as R from 'ramda';

import { WindowUtilitiesService, BrowserStorageService } from '../../shared-utilities/window-services';

import { CookiesBannerAcknowledgementState, PrivacySettings } from '../models';

import {
  INITIAL_PRIVACY_SETTINGS_COOKIE,
  PRIVACY_SETTINGS_LOCAL_STORAGE_KEY
} from '../constants';


@Injectable({
  providedIn: 'root'
})
export class PrivacySettingsStorageService {

  public get showCookiesBannerChanges(): Observable<boolean> {
    return this.showCookiesBannerSubject;
  }

  private showCookiesBannerSubject = new BehaviorSubject<boolean>(false);

  private privacySettings: PrivacySettings | null = null;

  constructor(
    private readonly windowUtilitiesService: WindowUtilitiesService,
    private readonly browserStorageService: BrowserStorageService
  ) {

    this.privacySettings = this.getPrivacySettings();

    if (this.privacySettings !== null) {
      this.showCookiesBannerSubject.next(
        this.privacySettings.cookiesBannerState === CookiesBannerAcknowledgementState.ACCEPTED
          ? false
          : true
      );
    } else {
      this.setPrivacySettings(INITIAL_PRIVACY_SETTINGS_COOKIE);
    }

  }

  public acceptCookies(): void {
    const settings: PrivacySettings = this.privacySettings !== null
      ? R.clone(this.privacySettings)
      : INITIAL_PRIVACY_SETTINGS_COOKIE;
    settings.cookiesBannerState = CookiesBannerAcknowledgementState.ACCEPTED;
    this.setPrivacySettings(settings);
  }

  public getPrivacySettings(): PrivacySettings | null {
    if (!this.windowUtilitiesService.supportsLocalStorage()) {
      return null;
    }
    return this.browserStorageService.getLocal<PrivacySettings>(PRIVACY_SETTINGS_LOCAL_STORAGE_KEY);
  }

  public setPrivacySettings(settings: PrivacySettings): void {
    this.privacySettings = settings;
    if (this.windowUtilitiesService.supportsLocalStorage()) {
      this.browserStorageService.setLocal(PRIVACY_SETTINGS_LOCAL_STORAGE_KEY, settings);
    }
    this.showCookiesBannerSubject.next(
      settings.cookiesBannerState === CookiesBannerAcknowledgementState.NO_ACTION
    );
  }

}
