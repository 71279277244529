import { Country, GeoLocation } from '@grid-ui/common';
import { ItemInformationListingItemDatatype } from '../item-information-listing';

/**
 * Typeguard to determine whether the specified data for an Item Information Listing
 * supports unique identification by "geo_id" property.
 *
 * @param data An Item Information Listing item.
 */
export function isGeoIdDatatype(data: ItemInformationListingItemDatatype): data is Country | GeoLocation {
  return (data as any).geo_id !== undefined;
}
