<div class="video-embed-container">
  <grid-ui-loading></grid-ui-loading>
  <iframe id="{{value?value.id:''}}"
          [src]="url"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen>
  </iframe>
</div>
