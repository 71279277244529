import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NgbDropdownModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../icon';
import { GlobalLegendComponent } from './global-legend/global-legend.component';
import { GlobalLegendExpandedComponent } from './global-legend-expanded/global-legend-expanded.component';


@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    IconModule
  ],
  declarations: [
    GlobalLegendComponent,
    GlobalLegendExpandedComponent
  ],
  exports: [
    GlobalLegendComponent,
    GlobalLegendExpandedComponent
  ]
})
export class GlobalLegendModule { }
