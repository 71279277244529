import { AnalyseDataTableFlatTreeNode, AnalyseDataTableFlatTreeGroupNode } from './analyse-data-table-flat-tree-node.interface';
import { AnalyseDataTableTreeViewNode, AnalyseDataTableTreeViewGroupNode } from './analyse-data-table-tree-view-node.interface';
import {
  AnalyseDataTableHistoricalTreeViewNode,
  AnalyseDataTableHistoricalTreeViewGroupNode
} from './analyse-data-table-historical-tree-view-node.interface';
import { AnalyseDataTableHistoricalFlatTreeNode } from './analyse-data-table-historical-flat-tree-node.interface';

/**
 * Typeguard to check whether a data table related node is a "tree view" node
 * or a "flat node"
 */
export function isAnalyseDataTableTreeViewNode(
  node: AnalyseDataTableTreeViewNode | AnalyseDataTableHistoricalTreeViewNode
  | AnalyseDataTableFlatTreeNode | AnalyseDataTableHistoricalFlatTreeNode
): node is AnalyseDataTableTreeViewNode | AnalyseDataTableHistoricalTreeViewNode {
  return (node as any).data !== undefined;
}

export function isAnalyseDataTableFlatTreeGroupNode(
  node: AnalyseDataTableFlatTreeNode
): node is AnalyseDataTableFlatTreeGroupNode {
  return node.type === 'group';
}

export function isExpandableDataTableTreeViewNode(
  node: AnalyseDataTableTreeViewNode | AnalyseDataTableHistoricalTreeViewNode
): node is AnalyseDataTableTreeViewGroupNode | AnalyseDataTableHistoricalTreeViewGroupNode {
  return node.data.type === 'group';
}
