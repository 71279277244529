<div class="mc-error-box"
  [class.mc-full]="size === 'full'"
  [class.mc-reduced]="size === 'reduced'"
>
  <div class="mc-left-container">
    <h1 *ngIf="errorCode"><span>{{errorCode}}</span></h1>
    <h1 *ngIf="!errorCode"><span>Sorry</span></h1>
  </div>
  <div class="mc-right-container">
    <div>
      <p *ngIf="errorCode">
        Sorry, something went wrong.
      </p>
      <p *ngIf="!errorCode">
        Something went wrong.
      </p>
      <p *ngIf="showContact && contactEmail">
          If you continue to encounter this problem, please contact <a href="mailto:{{contactEmail}}">{{contactEmail}}</a>
      </p>
    </div>
    <div>
      <p class="caption">
        {{errorMessage}}
      </p>
    </div>
    <div>
      <button
        *ngIf="errorPrimaryAction?.length > 0"
        class="btn mc-btn-primary"
        (click)="triggerPrimaryAction($event)"
      >
        {{errorPrimaryAction! | uppercase}}
      </button>
      <button
        *ngIf="errorSecondaryAction?.length > 0"
        class="btn mc-btn-secondary"
        (click)="triggerSecondaryAction($event)"
      >
        {{errorSecondaryAction! | uppercase}}
      </button>
    </div>
  </div>
</div>
