import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@grid-ui/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IEWarningComponent } from './ie-warning.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    NgbTooltipModule
  ],
  declarations: [
    IEWarningComponent
  ],
  exports: [
    IEWarningComponent
  ]
})
export class IEWarningModule { }
