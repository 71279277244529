import { NotificationActions, NotificationActionTypes } from '../actions/notifications';
import { NotificationBarNotificationInternal } from '../../models';

export interface State {
  queue: NotificationBarNotificationInternal[];
  downloadedExtractUrl: string | null;
}

export const initialState: State = {
  queue: [],
  downloadedExtractUrl: null
};

export function reducer(
  state = initialState,
  action: NotificationActions
): State {
  switch (action.type) {
    case NotificationActionTypes.AddNotification: {
      return {
        ...state,
        queue: [...state.queue, action.payload]
      };
    }

    case NotificationActionTypes.RemoveCurrentNotification: {
      return {
        ...state,
        queue: state.queue.slice(1)
      };
    }

    case NotificationActionTypes.RemoveNotification: {
      return {
        ...state,
        queue: state.queue.filter(x => x._id !== action.payload)
      };
    }

    case NotificationActionTypes.Reset: {
      return initialState;
    }

    case NotificationActionTypes.ExtractDownloadedFromNotificationBar: {
      return {
        ...state,
        downloadedExtractUrl: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getQueue = (state: State) => state.queue;
export const getDownloadedExtractUrl = (state: State) => state.downloadedExtractUrl;
