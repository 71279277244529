import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../icon/icon.module';
import { GlobalLegendModule } from '../global-legend';
import { ScorePanelImageDownloadButtonComponent } from './score-panel-image-download-button.component';

@NgModule({
    declarations: [
        ScorePanelImageDownloadButtonComponent
    ],
    exports: [
        ScorePanelImageDownloadButtonComponent
    ],
    imports: [
        CommonModule,
        NgbModalModule,
        NgbTooltipModule,
        GlobalLegendModule,
        IconModule,
    ]
})
export class ScorePanelImageDownloadButtonModule { }

