/* eslint-disable @typescript-eslint/naming-convention */
import { ApiServicesConfigModel } from './models';

export const GLOBAL_DEFAULT_RETRY_ATTEMPTS = 3;

export const V3_API_PREFIX = '/api/v3/';
export const V4_API_PREFIX = '/api/v4/';
export const GRID_FE_API_PREFIX = '/fe-api/';
// TODO: Verify what this endpoint will actually be in production/staging environments
export const NESSIE_API_PREFIX = '/nessie/';

export const API_SERVICES_CONFIG: ApiServicesConfigModel = {
  feApi: {
    allCountries: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'all-countries/',
        expiration: 60
      }
    },
    locations:{
      events:{
        _configuration: {
          maxPageSize: 50,
          defaultPageSize: 50,
          path: GRID_FE_API_PREFIX + 'location/',
        }
      }
    },
    analytics: {
      events: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'analytics/events/',
        }
      }
    },
    sharedLinks: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'shared-link/',
        maxPageSize: 50,
        defaultPageSize: 50,
      },
      detail: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'shared-link/{linkId}/',
          pathParams: { linkId: 'any' },
        },
        accept: {
          _configuration: {
            path: GRID_FE_API_PREFIX + 'shared-link/{linkId}/accept/',
            pathParams: { linkId: 'any' },
          },
        },
      },
      bulkDelete: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'shared-link/bulkdelete/',
        },
      }
    },
    moduleAnnouncements: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'module-announcement/',
        maxPageSize: 50,
        defaultPageSize: 50,
      },
      detail: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'module-announcement/{announcementId}/',
          pathParams: { announcementId: 'any' },
        },
      },
    },
    releaseNotes: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'release-note/',
        maxPageSize: 50,
        defaultPageSize: 50,
      },
      detail: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'release-note/{noteId}/',
          pathParams: { noteId: 'any' },
        },
      },
    },
    indexViews: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'index-views-map/',
      },
    },
    userWorkflow: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'user-workflow/',
        maxPageSize: 50,
        defaultPageSize: 50,
      },
      detail: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'user-workflow/{workflowId}/',
          pathParams: { workflowId: 'any' },
        },
      }
    },
    register: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'register/',
      }
    },
    verifyUser: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'verify-user/',
      },
    },
    activateUser: {
      _configuration: {
        path: GRID_FE_API_PREFIX + 'activate/{activationKey}/',
        pathParams: { activationKey: 'any' },
      },
    },
    dataAlerts: {
      trigger: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'data-alerts/trigger/',
          maxPageSize: 600,
          defaultPageSize: 30,
        },
        detail: {
          _configuration: {
            path: GRID_FE_API_PREFIX + 'data-alerts/trigger/{triggerId}/',
            pathParams: { triggerId: 'any' },
          },
          duplicate: {
            _configuration: {
              path: GRID_FE_API_PREFIX + 'data-alerts/trigger/{triggerId}/duplicate/',
              pathParams: { triggerId: 'any' },
            }
          },
        }
      },
      notification: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'data-alerts/notification/',
          maxPageSize: 600,
          defaultPageSize: 30,
        },
        detail: {
          _configuration: {
            path: GRID_FE_API_PREFIX + 'data-alerts/notification/{notificationId}/',
            pathParams: { notificationId: 'any' },
          },
        },
      },
      alert: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'data-alerts/alert/',
          maxPageSize: 600,
          defaultPageSize: 30,
        },
      },
      downloadRequest: {
        _configuration: {
          path: GRID_FE_API_PREFIX + 'data-alerts/download-request/',
          maxPageSize: 600,
          defaultPageSize: 30,
        },
        detail: {
          _configuration: {
            path: GRID_FE_API_PREFIX + 'data-alerts/download-request/{requestId}/',
            pathParams: { requestId: 'any' },
          },
          download: {
            _configuration: {
              path: GRID_FE_API_PREFIX + 'data-alerts/notification/{notificationId}/download/',
              pathParams: { notificationId: 'any' },
            },
          }
        }
      }
    },
  },
  v3: {
    askAnAnalyst: {
      _configuration: {
        path: V3_API_PREFIX + 'ask-an-analyst/'
      }
    },
    commodityRisk: {
      _configuration: {
        path: V3_API_PREFIX + 'commodity-risk/',
        expiration: 60
      },
      announcements: {
        _configuration: {
          path: V3_API_PREFIX + 'commodity-risk/announcements/',
          maxPageSize: 50,
          defaultPageSize: 50
        }
      },
      configurations: {
        _configuration: {
          path: V3_API_PREFIX + 'commodity-risk/configuration/'
        },
        detail: {
          _configuration: {
            path: V3_API_PREFIX + 'commodity-risk/configuration/{viewId}/',
            pathParams: { viewId: 'any' }
          }
        }
      },
      countries: {
        _configuration: {
          path: V3_API_PREFIX + 'commodity-risk/country/',
          expiration: 60
        }
      },
      content: {
        _configuration: {
          path: V3_API_PREFIX + 'commodity-risk/content/',
          queryable: false
        },
        featured: {
          _configuration: {
            path: V3_API_PREFIX + 'commodity-risk/content/featured/',
            expiration: 60
          },
          detail: {
            _configuration: {
              path: V3_API_PREFIX + 'commodity-risk/content/featured/{id}/',
              pathParams: { id: 'any' },
              expiration: 60
            }
          }
        },
        insights: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/content/insights/',
            maxPageSize: 100,
            defaultPageSize: 25
          },
          detail: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/insights/{id}/',
              pathParams: { id: 'any' },
              expiration: 60
            },
            context: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/content/insights/{id}/context/',
                pathParams: { id: 'any' },
                expiration: 60
              }
            }
          },
          recent: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/insights/recent/',
              expiration: 60
            }
          }
        },
        profiles: {
          _configuration: {
            path: V3_API_PREFIX + 'commodity-risk/content/profiles/',
            maxPageSize: 100,
            defaultPageSize: 25
          },
          detail: {
            _configuration: {
              path: V3_API_PREFIX + 'commodity-risk/content/profiles/{id}/',
              pathParams: { id: 'any' },
              expiration: 60
            },
            context: {
              _configuration: {
                path: V3_API_PREFIX + 'commodity-risk/content/profiles/{id}/context/',
                pathParams: { id: 'any' },
                expiration: 60
              }
            }
          },
          recent: {
            _configuration: {
              path: V3_API_PREFIX + 'commodity-risk/content/profiles/recent/',
              expiration: 60
            }
          }
        }
      },
    },
    countries: {
      // All, includes un-scored and both entitled & un-entitled
      all: {
        _configuration: {
          path: V3_API_PREFIX + 'country/?unentitled=-1',
          expiration: 300
        }
      },
      scored: {
        _configuration: {
          path: V3_API_PREFIX + 'country/?scored=1',
          expiration: 300
        }
      },
      country: {
        _configuration: {
          path: V3_API_PREFIX + 'country/{id}/',
          pathParams: { id: 'any' },
          expiration: 60
        }
      }
    },
    countryRisk: {
      _configuration: {
        path: V3_API_PREFIX + 'country-risk/',
        queryable: false
      },
      announcements: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/announcements/',
          maxPageSize: 50,
          defaultPageSize: 50
        }
      },
      configurations: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/configuration/',
          defaultPageSize: 300,
          maxPageSize: 300
        },
        configuration: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/configuration/{id}/',
            pathParams: { id: 'any' },
            expiration: 2
          },
          index: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/configuration/{id}/index/',
              queryable: false
            },
            bulkDelete: {
              _configuration: {
                path: V3_API_PREFIX
                  + 'country-risk/configuration/{viewId}/index/bulkdelete/',
                pathParams: { viewId: 'any' },
              }
            },
            groupNode: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/{groupNodeId}/',
                pathParams: { viewId: 'any', groupNodeId: 'any' },
                retryConfig: { PATCH: [{ responseCode: 'xxx', retryAttempts: 0 }] }
              },
              leafNodes: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/{groupNodeId}/reference/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                },
                leafNode: {
                  _configuration: {
                    path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/{groupNodeId}/reference/{leafNodeId}/',
                    pathParams: { viewId: 'any', groupNodeId: 'any', leafNodeId: 'any' },
                    retryConfig: { PATCH: [{ responseCode: 'xxx', retryAttempts: 0 }] }
                  }
                }
              },
              bulkAdd: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/{groupNodeId}/bulk/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                }
              },
              bulkCopy: {
                _configuration: {
                  path: V3_API_PREFIX
                    + 'country-risk/configuration/{viewId}/index/{groupNodeId}/copy/{viewToCopyFrom}/{groupToCopy}/',
                  pathParams: { viewId: 'any', groupNodeId: 'any', viewToCopyFrom: 'number', groupToCopy: 'any' },
                }
              },
              flatTree: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/{groupNodeId}/flat/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                  defaultPageSize: 300,
                  maxPageSize: 300
                }
              }
            },
            rootNodeFlatTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/index/flat/',
                pathParams: { viewId: 'any' },
                defaultPageSize: 300,
                maxPageSize: 300
              }
            }
          },
          indexTree: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/indextree/',
              queryable: false
            },
            groupNodeScoredTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/indextree/{group}/{grouped}/score/',
                pathParams: { viewId: 'any', group: 'any', grouped: 'string' },
                defaultPageSize: 300,
                maxPageSize: 300
              }
            },
            rootNodeScoredTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/indextree/{grouped}/score/',
                pathParams: { viewId: 'any', grouped: 'string' },
                defaultPageSize: 300,
                maxPageSize: 300
              }
            },
            siteScore: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/indextree/{indexGroupOrKey}/grouped/score/',
                pathParams: { viewId: 'any', indexGroupOrKey: 'any' }
              }
            }
          },
          location: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/configuration/{id}/location/',
              queryable: false
            },
            bulkDelete: {
              _configuration: {
                path: V3_API_PREFIX
                  + 'country-risk/configuration/{viewId}/location/bulkdelete/',
                pathParams: { viewId: 'any' },
              }
            },
            groupNode: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/{groupNodeId}/',
                pathParams: { viewId: 'any', groupNodeId: 'any' },
              },
              leafNodes: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/{groupNodeId}/reference/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                },
                leafNode: {
                  _configuration: {
                    path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/{groupNodeId}/reference/{leafNodeId}/',
                    pathParams: { viewId: 'any', groupNodeId: 'any', leafNodeId: 'any' },
                  }
                }
              },
              bulkAdd: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/{groupNodeId}/bulk/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                }
              },
              bulkCopy: {
                _configuration: {
                  path: V3_API_PREFIX +
                    'country-risk/configuration/{viewId}/location/{groupNodeId}/copy/{viewToCopyFrom}/{groupToCopy}/',
                  pathParams: { viewId: 'any', groupNodeId: 'any', viewToCopyFrom: 'number', groupToCopy: 'any' },
                }
              },
              flatTree: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/{groupNodeId}/flat/',
                  pathParams: { viewId: 'any', groupNodeId: 'any' },
                  defaultPageSize: 300,
                  maxPageSize: 300
                }
              }
            },
            rootNodeFlatTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/location/flat/',
                pathParams: { viewId: 'any' },
                defaultPageSize: 300,
                maxPageSize: 300
              }
            }
          },
          locationTree: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/configuration/{id}/locationtree/',
              queryable: false
            },
            groupScoreMap: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/{group}/map/score/',
                pathParams: { viewId: 'any', group: 'any' }
              }
            },
            groupNodeScoredTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/{group}/{grouped}/score/',
                pathParams: { viewId: 'any', group: 'any', grouped: 'string' },
                defaultPageSize: 300,
                maxPageSize: 300
              },
              scoreTable: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/{group}/grouped/score/table/',
                  pathParams: { viewId: 'any', group: 'any' },
                  defaultPageSize: 100,
                  maxPageSize: 100
                }
              }
            },
            rootNodeScoredTree: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/{grouped}/score/',
                pathParams: { viewId: 'any', grouped: 'string' },
                defaultPageSize: 300,
                maxPageSize: 300
              },
              scoreTable: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/grouped/score/table/',
                  pathParams: { viewId: 'any' },
                  defaultPageSize: 300,
                  maxPageSize: 300
                }
              },
              scoreHistory: {
                _configuration: {
                  path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/{group}/grouped/score/history/',
                  pathParams: { viewId: 'any' },
                  defaultPageSize: 100,
                  maxPageSize: 100
                }
              }
            },
            scoreMap: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/locationtree/map/score/',
                pathParams: { viewId: 'any' },
              }
            }
          }
        },
        save: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/replacewith/{draftId}/',
            pathParams: { id: 'viewId', draftId: 'any' }
          },
        },
        createDraft: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/configuration/{viewId}/draft/',
            pathParams: { viewId: 'any' }
          },
        }
      },
      configurationTreesSearch: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/configuration-search/{treeType}/',
          pathParams: { treeType: 'string' },
          maxPageSize: 100,
          defaultPageSize: 100
        },
        byView: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/configuration-search/{treeType}/{viewId}/',
            pathParams: { treeType: 'string', viewId: 'any' },
            maxPageSize: 100,
            defaultPageSize: 100
          }
        }
      },
      content: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/content/',
          queryable: false
        },
        featuredList: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/content/featured/'
          },
          featuredGet: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/featured/{id}/',
              pathParams: { id: 'any' }
            }
          }
        },
        insights: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/content/insights/',
            maxPageSize: 100,
            defaultPageSize: 25
          },
          detail: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/insights/{id}/',
              pathParams: { id: 'any' },
              expiration: 60
            },
            context: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/content/insights/{id}/context/',
                pathParams: { id: 'any' },
                expiration: 60
              }
            }
          },
          recent: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/insights/recent/',
              expiration: 60
            }
          }
        },
        profiles: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/content/profiles/',
            maxPageSize: 100,
            defaultPageSize: 25
          },
          detail: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/profiles/{id}/',
              pathParams: { id: 'any' },
              expiration: 60
            },
            context: {
              _configuration: {
                path: V3_API_PREFIX + 'country-risk/content/profiles/{id}/context/',
                pathParams: { id: 'any' },
                expiration: 60
              }
            }
          },
          recent: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/content/profiles/recent/',
              expiration: 60
            }
          }
        },
        autocomplete: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/content/{section}/autocomplete/',
            pathParams: { section: 'any' }
          },
        }
      },
      country: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/country/',
          queryable: false
        },
        country: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/country/{country_code}/',
            pathParams: { country_code: 'string' },
          }
        }
      },
      explain: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/explain/',
          queryable: false
        },
        options: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/explain/options/',
            expiration: 60
          }
        },
        detail: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/explain/{country_code}/{index_id}/',
            pathParams: {
              country_code: 'string',
              index_id: 'string'
            },
            expiration: 60
          }
        }
      },
      extract: {
        _configuration: {
          pollingEndpoint: {
            path: V3_API_PREFIX + 'country-risk/extract/{id}/',
            pathParams: { id: 'string' }
          },
          path: V3_API_PREFIX + 'country-risk/extract/',
          // set to a very high limit of 10 hours, see: P2-3384
          pollLimit: 12000,
          pollingInterval: 3000
        },
        detail: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/extract/{id}/',
            pathParams: { id: 'string' }
          }
        },
        table: {
          _configuration: {
            pollingEndpoint: {
              path: V3_API_PREFIX + 'country-risk/extract/table/{id}/',
              pathParams: { id: 'string' }
            },
            path: V3_API_PREFIX + 'country-risk/extract/table/',
            pollLimit: 12000,
            pollingInterval: 3000
          }
        }
      },
      indices: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/index/',
          maxPageSize: 600,
          defaultPageSize: 300,
        },
        index: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/index/{id}/{edition}/',
            pathParams: { id: 'any', edition: 'string' },
            expiration: 60
          },
          narrative: {
            _configuration: {
              path: V3_API_PREFIX + 'country-risk/index/{id}/narrative/',
              pathParams: { id: 'any' },
              expiration: 60
            },
          }
        },
        dynamicConfigurations: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/index/dynamic-configuration/'
          }
        }
      },
      locations: {
        dynamicConfigurations: {
          _configuration: {
            path: V3_API_PREFIX + 'country-risk/location/dynamic-configuration/'
          }
        }
      },
      lastViewed: {
        _configuration: {
          path: V3_API_PREFIX + 'country-risk/last-viewed/'
        }
      }
    },
    currentEdition: {
      _configuration: {
        path: V3_API_PREFIX + 'current-edition/',
        expiration: 3600
      }
    },
    documentLibrary: {
      documentList: {
        _configuration: {
          path: V3_API_PREFIX + 'document-library/document/',
          maxPageSize: 200,
          defaultPageSize: 50
        }
      },
      indexCatalogue: {
        _configuration: {
          path: V3_API_PREFIX + 'document-library/document/index-catalogue-latest/',
          expiration: 60
        }
      },
      document: {
        _configuration: {
          path: V3_API_PREFIX + 'document-library/document/{id}/',
          pathParams: { id: 'any' },
          expiration: 60
        }
      },
      extractList: {
        _configuration: {
          path: V3_API_PREFIX + 'document-library/extract/',
          maxPageSize: 200,
          defaultPageSize: 50
        }
      },
      extract: {
        _configuration: {
          path: V3_API_PREFIX + 'document-library/extract/{id}/',
          pathParams: { id: 'any' },
          expiration: 60
        }
      }
    },
    faq: {
      _configuration: {
        path: V3_API_PREFIX + '{module}/faq/',
        pathParams: { module: 'string' },
        expiration: 60
      }
    },
    learning: {
      _configuration: {
        path: V3_API_PREFIX + '{module}/learn/',
        pathParams: { module: 'string' }
      }
    },
    locations: {
      _configuration: {
        path: V3_API_PREFIX + 'location/',
        maxPageSize: 100,
        defaultPageSize: 50
      },
      location: {
        _configuration: {
          path: V3_API_PREFIX + 'location/{id}/',
          pathParams: { id: 'any' },
          retryConfig: { DELETE: [{ responseCode: 'xxx', retryAttempts: 0 }] }
        }
      },
      bulkDelete: {
        _configuration: {
          path: V3_API_PREFIX + 'location/bulkdelete/',
          retryConfig: { DELETE: [{ responseCode: 'xxx', retryAttempts: 0 }] }
        }
      },
      attributesMapping: {
        _configuration: {
          path: V3_API_PREFIX + 'location/attributes_mapping/',
          expiration: 60
        },
        attributeMapping: {
          _configuration: {
            path: V3_API_PREFIX + 'location/attributes_mapping/{id}/',
            pathParams: { id: 'any' }
          }
        }
      },
      attributes: {
        _configuration: {
          path: V3_API_PREFIX + 'location/attributes/',
          expiration: 60
        },
        attribute: {
          _configuration: {
            path: V3_API_PREFIX + 'location/attributes/{id}/',
            pathParams: { id: 'any' },
            maxPageSize: 250,
            defaultPageSize: 250
          }
        }
      },
      uploads: {
        _configuration: {
          path: V3_API_PREFIX + 'location/upload/',
          maxPageSize: 50,
          defaultPageSize: 50
        },
        upload: {
          _configuration: {
            path: V3_API_PREFIX + 'location/upload/{id}/',
            pathParams: { id: 'any' }
          },
          failures: {
            _configuration: {
              path: V3_API_PREFIX + 'location/upload/{id}/failures/',
              pathParams: { id: 'any' },
              maxPageSize: 100,
              defaultPageSize: 100
            }
          }
        },
        spreadsheet: {
          _configuration: {
            path: V3_API_PREFIX + 'location/upload/spreadsheet/',
          }
        },
        sessions: {
          _configuration: {
            path: V3_API_PREFIX + 'location/upload/session/',
          },
          session: {
            _configuration: {
              path: V3_API_PREFIX + 'location/upload/session/{id}/',
              pathParams: { id: 'any' }
            }
          }
        }
      }
    },
    subscription: {
      _configuration: {
        path: V3_API_PREFIX + 'salesrequest/'
      }
    },
    savedSearches: {
      _configuration: {
        path: V3_API_PREFIX + 'saved-search/'
      },
      detail: {
        _configuration: {
          path: V3_API_PREFIX + 'saved-search/{id}/',
          pathParams: { id: 'any' }
        }
      }
    },
    whoAmI: {
      _configuration: {
        path: V3_API_PREFIX + 'auth/whoami/',
        expiration: 60
      },
      user: {
        _configuration: {
          path: V3_API_PREFIX + 'auth/whoami/user/',
          expiration: 60
        }
      }
    },
    announcementBar: {
      _configuration: {
        path: V3_API_PREFIX + 'announcements/'
      },
      detail: {
        _configuration: {
          path: V3_API_PREFIX + 'announcements/{id}/',
          pathParams: { id: 'string' }
        }
      },
    },
  },
  v4: {
    location: {
      _configuration: {
        path: V4_API_PREFIX + 'location/',
        maxPageSize: 500,
        defaultPageSize: 50,
      },
      attribute: {
        _configuration: {
          path: V4_API_PREFIX + 'location/attribute/',
          maxPageSize: 500,
          defaultPageSize: 50,
        },
        detail: {
          _configuration: {
            path: V4_API_PREFIX + 'location/attribute/{id}/values/',
            maxPageSize: 500,
            defaultPageSize: 50,
            pathParams: { id: 'any'  },
          }
        }
      },
      deleteRequest: {
        _configuration: {
          path: V4_API_PREFIX + 'location/delete-request/',
        },
        detail: {
          _configuration: {
            path: V4_API_PREFIX + 'location/delete-request/{id}/',
            pathParams: { id: 'any'  },
          }
        }
      },
      downloadRequest: {
        _configuration: {
          path: V4_API_PREFIX + 'location/download-request/',
        },
        detail: {
          _configuration: {
            path: V4_API_PREFIX + 'location/download-request/{id}/',
            pathParams: { id: 'any' },
          },
        },
        download: {
          _configuration: {
            path: V4_API_PREFIX + 'location/download-request/{id}/download/',
            pathParams: { id: 'any' },
          },
        },
      },
    },
    country: {
      _configuration: {
        path: V4_API_PREFIX + 'country/',
        maxPageSize: 500,
        defaultPageSize: 50,
      },
    }
  },
  nessie: {
    insights: {
      _configuration: {
        path: NESSIE_API_PREFIX + 'insights/',
        maxPageSize: 100,
        defaultPageSize: 25
      },
      detail: {
        _configuration: {
          path: NESSIE_API_PREFIX + 'insights/{id}/',
          pathParams: { id: 'any' },
          expiration: 60
        },
        context: {
          _configuration: {
            path: NESSIE_API_PREFIX + 'insights/{id}/context/',
            pathParams: { id: 'any' },
            expiration: 60
          }
        }
      },
      recent: {
        _configuration: {
          path: NESSIE_API_PREFIX + 'insights/recent/',
          expiration: 60
        }
      }
    }
  },
  globalHttpErrorConfig: {
    'default': {
      title: 'Sorry, something went wrong',
      message: 'An error occurred while communicating with the server.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    '404': {
      title: 'Sorry, something went wrong',
      message: 'The requested data could not be found.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    // TODO: remove once API no longer uses this error code. It is only being returned by /v3/country-risk/configuration/ endpoints
    // so the error message is specific to that use case. See P2-3369
    '426': {
      title: 'View too big',
      message: 'View exceeds supported size for Analyse. Please use Data Wizard Extract or API directly to get scores.',
      contactEmail: 'support@maplecroft.com',
      showContact: false
    },
    '500': {
      title: 'Sorry, something went wrong',
      message: 'The server encountered an internal error.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    '501': {
      title: 'Sorry, something went wrong',
      message: 'There was a 501 error on server.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    '502': {
      title: 'Sorry, something went wrong',
      message: 'There was a 502 error on server.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    '503': {
      title: 'Sorry, something went wrong',
      message: 'There was a 503 error on server.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    '504': {
      title: 'Sorry, something went wrong',
      message: 'Unable to get a response from the server.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    },
    // This is the default error which will be shown when error code does not match above
    '0': {
      title: 'Sorry, something went wrong',
      message: 'There was an unexpected error. Please make sure your internet is connected and try again.',
      contactEmail: 'support@maplecroft.com',
      showContact: true
    }
  },
  globalRetryConfig: {
    default: 3,
    DELETE: [{ responseCode: '403', retryAttempts: 0 }, { responseCode: '404', retryAttempts: 0 }],
    GET: [{ responseCode: '403', retryAttempts: 0 }, { responseCode: '404', retryAttempts: 0 }, { responseCode: '426', retryAttempts: 0 }],
    PATCH: [{ responseCode: '403', retryAttempts: 0 }, { responseCode: '404', retryAttempts: 0 }],
    POST: [{ responseCode: '403', retryAttempts: 0 }, { responseCode: '404', retryAttempts: 0 }],
    PUT: [{ responseCode: '403', retryAttempts: 0 }, { responseCode: '404', retryAttempts: 0 }],
  }
};
