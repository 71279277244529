import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * Use this component when the user has specified a non-empty search term in a search
 * for entities (e.g. Risk Views, Documents or Extracts), but no matching results could be found.
 *
 * When the search term is empty and the user has no data available due to missing entitlements or none
 * have been created yet, use the tag/component `<mc-no-data-available>` instead.
 */
@Component({
  selector: 'mc-no-matching-results',
  templateUrl: './no-matching-results.component.html',
  styleUrls: ['./no-matching-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoMatchingResultsComponent {
  @Input() message = 'There are no results matching your search criteria.';

}
