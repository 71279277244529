import { ServiceOffering, ServiceOfferingText } from '../../home/models';
import { PermissionSelector } from './permissions.service';

export const AllServiceOfferings: ServiceOffering[] = [
  {
    card: {
      order: 1,
      description: 'Understand the primary risks affecting your markets',
      imageHref: 'shape-country-risk',
      link: '/country-risk',
      label: ServiceOfferingText.CountryRisk,
      permissionSelector: PermissionSelector.COUNTRY_RISK.MODULE,
      moduleId: 'country-risk',
      elementId: 'country-risk',
      menu: [
        { label: 'Home', link: '/country-risk/home', permissionSelector: PermissionSelector.COUNTRY_RISK.HOME },
        { label: 'Views', link: '/country-risk/view', permissionSelector: PermissionSelector.COUNTRY_RISK.VIEWS },
        { label: 'Analyse', link: '/country-risk/analyse', permissionSelector: PermissionSelector.COUNTRY_RISK.ANALYSE },
        { label: 'Alerts', link: '/country-risk/alerts', permissionSelector: PermissionSelector.COUNTRY_RISK.ALERTS },
        { label: 'Insights', link: '/country-risk/insights', permissionSelector: PermissionSelector.COUNTRY_RISK.INSIGHTS },
        { label: 'Profiles', link: '/country-risk/profiles', permissionSelector: PermissionSelector.COUNTRY_RISK.PROFILES }
      ]
    }
  },
  {
    card: {
      order: 2,
      description: 'View our learn videos to help you get started using GRiD or troubleshoot issues',
      imageHref: 'shape-video',
      link: '/learn/videos',
      moduleId: 'learn',
      label: ServiceOfferingText.Learn,
      permissionSelector: PermissionSelector.ALWAYS.ALLOW,
      elementId: 'learn'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      link: '/risk-assessment',
      moduleId: 'risk-assessment',
      key: '99ae4107-15bb-44ca-8514-1b1d9b925e07'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: '8e4bed36-65b8-4e47-9d66-b2b2cb0b5e4c'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: '9a9405ca-6381-4959-b0c9-55944416d5c9'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: 'bec00f89-6c59-42dd-a878-673cc303c9af'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: '84b85c1f-44f2-4f35-94f5-d46d94d0072d'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: '5e6482a9-f217-4b50-852e-2113d845b770'
    }
  },
  {
    card: {
      order: 3,
      imageHref: 'shape-risk-assessment',
      moduleId: 'risk-assessment',
      key: 'a40fab3b-531c-4576-8973-e59828ff3491'
    }
  },
  {
    card: {
      order: 4,
      description: 'Benchmark ESG risks across locations and industries',
      imageHref: 'shape-industry-risk',
      moduleId: 'industry',
      link: '/industry',
      label: 'Industry Risk',
      permissionSelector: PermissionSelector.INDUSTRY.MODULE,
      elementId: 'industry',
      menu: [
        { label: 'Analyse', link: '/industry/analyse', permissionSelector: PermissionSelector.INDUSTRY.MODULE },
        { label: 'Table', link: '/industry/data-table', permissionSelector: PermissionSelector.INDUSTRY.MODULE },
        { label: 'Best / Worst', link: '/industry/best-worst', permissionSelector: PermissionSelector.INDUSTRY.BEST_WORST },
        { label: 'Methodology', link: '/industry/methodology', permissionSelector: PermissionSelector.INDUSTRY.MODULE },
        { label: 'Definitions', link: '/industry/definitions', permissionSelector: PermissionSelector.INDUSTRY.MODULE },
      ]
    }
  },
  {
    card: {
      order: 4,
      description: 'Assess ESG and political risk exposure for 150\+ commodities',
      imageHref: 'shape-commodity-risk',
      link: '/commodity-risk/dashboard',
      label: ServiceOfferingText.CommodityRisk,
      featureToggle: 'commodityRisk',
      permissionSelector: PermissionSelector.COMMODITY_RISK.MODULE,
      moduleId: 'commodity-risk',
      elementId: 'commodity-risk',
      menu: [
        { label: 'Insights', link: '/commodity-risk/insights', permissionSelector: PermissionSelector.COMMODITY_RISK.INSIGHTS },
        {
          label: 'Commodity Risk Dashboard', link: '/commodity-risk/dashboard',
          permissionSelector: PermissionSelector.COMMODITY_RISK.DASHBOARD
        }
      ]
    }
  },
  {
    card: {
      order: 5,
      imageHref: 'shape-industry-scoring',
      label: 'Industry Scoring',
      slug: 'industry-scoring',
      key: '40a372f4-6473-4f8a-a2b0-de3a91f04a5a'
    }
  },
  {
    card: {
      order: 6,
      imageHref: 'shape-corporate-exposure',
      link: '/corporate-exposure',
      label: 'Corportate Exposure Tool',
      slug: 'corporate-exposure-tool',
      key: 'f70a263f-9e4e-42ee-a246-c971a9c074f3'
    }
  },
  {
    card: {
      order: 7,
      description: 'Upload your data or extract our analytics for more tailored insight',
      imageHref: 'shape-data-wizard',
      label: ServiceOfferingText.DataWizard,
      link: '/data-wizard',
      moduleId: 'data-wizard',
      permissionSelector: PermissionSelector.DATA_WIZARD.MODULE,
      elementId: 'data-wizard',
      menu: [
        { label: 'Extract', link: '/data-wizard/extract', permissionSelector: PermissionSelector.DATA_WIZARD.EXTRACT },
        { label: 'Site Management', link: '/data-wizard/site-management', permissionSelector: PermissionSelector.DATA_WIZARD.UPLOAD },
        { label: 'Upload Sites', link: '/data-wizard/upload/upload-sites', permissionSelector: PermissionSelector.DATA_WIZARD.UPLOAD },
        { label: 'Upload History', link: '/data-wizard/upload/upload-history', permissionSelector: PermissionSelector.DATA_WIZARD.UPLOAD }
      ]
    }
  },
  {
    card: {
      order: 8,
      description: 'Access your securely stored documents',
      imageHref: 'shape-document-library',
      link: '/document-library',
      label: ServiceOfferingText.DocumentLibrary,
      permissionSelector: PermissionSelector.DOCUMENT_LIBRARY.MODULE,
      moduleId: 'document-library',
      elementId: 'document-library',
      menu: [
        { label: 'Documents', link: '/document-library/documents', permissionSelector: PermissionSelector.DOCUMENT_LIBRARY.MODULE },
        { label: 'Extracts', link: '/document-library/extracts', permissionSelector: PermissionSelector.DOCUMENT_LIBRARY.EXTRACTS },
      ]
    }
  },
  {
    card: {
      order: 10,
      description: 'Get the full rundown of our webinars, events and company news',
      imageHref: 'shape-news-and-events',
      link: '/maplecroft-news-and-events',
      label: ServiceOfferingText.MaplecroftNewsAndEvents,
      moduleId: '',
      key: 'c5711b9d-ef9d-4163-8fae-097a102b065d'
    }
  },
  {
    card: {
      order: 11,
      imageHref: 'shape-forecasting',
      label: ServiceOfferingText.Forecasting,
      moduleId: 'forecasting',
      elementId: 'forecasting',
    }
  },
  {
    card: {
      order: 11,
      description: 'Lorem ipsum dolor sit amet, consectetur',
      imageHref: 'shape-dynamic-indices',
      link: '/dynamic-indices',
      label: ServiceOfferingText.DynamicIndices,
      moduleId: 'dynamic-indices',
      elementId: 'dynamic-indices'
    }
  },
  {
    card: {
      order: 11,
      imageHref: 'shape-coronavirus',
      moduleId: 'risk-assessment',
      key: '6a01342a-689b-4fa5-bcb5-88407cb5fcb5'
    }
  }
];
