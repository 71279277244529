<ul class="app-side-area-links">
  <li *ngFor="let item of navItems; trackBy: trackByIndex"
      [ngClass]="{'active': item.moduleId && item.moduleId === currentModule}"
      (click)="navigate(item)"
      routerLinkActive="active"
      placement="right"
      [container]="'body'"
      ngbTooltip="{{item.label}}"
      tabindex="{{trackByIndex}}"
      class="app-side-area-links__list-item"
  >
    <ng-container *ngIf="item.moduleId === 'data-wizard'">
      <mc-loading
        id="loading-icon"
        *ngIf="extractActive$ | async" class="app-side-area-links__notification-icon"
        [loadingMessage]="''"
      >
      </mc-loading>
      <grid-ui-icon id="complete-icon" *ngIf="extractComplete$ | async" class="app-side-area-links__notification-icon" name="tick-circle"></grid-ui-icon>
    </ng-container>

    <!-- Here we want the icon color to change when another element is hovered, so we need to use inlineSVGs  -->
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-country-risk'" name="country-risk" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-video'" name="video" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-sovereign-esg-ratings'" name="sovereign" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-commodity-risk'" name="commodity-risk" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-industry-risk'" name="industry-chart" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-corporate-exposure'" name="corporate-exposure" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-data-wizard'" name="data-wizard" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-document-library'" name="document-library" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-email-alerts'" name="email" [useInlineSVG]="true"></grid-ui-icon>
    <grid-ui-icon class="app-side-area-links__icon" *ngIf="item.imageHref === 'shape-news-and-events'" name="calendar" [useInlineSVG]="true"></grid-ui-icon>
  </li>
</ul>
