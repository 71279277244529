/**
 * Simulate generation of a backend location attribute key from a column name.
 * Used to perform pre-checks preventing attribute addition requests with duplicate
 * key error
 *
 * @param columnName A column name to generate a key from.
 */
export function generateSyntheticBackendColumnKey(columnName: string): string {
  return columnName.trim().replace(/ /g, '_').toLowerCase();
}
