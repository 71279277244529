import { ILoadingCellRendererAngularComp } from '@ag-grid-community/angular';
import { ILoadingCellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  selector: 'grid-ui-loading-renderer',
  templateUrl: './loading-renderer.component.html',
  styleUrls: ['./loading-renderer.component.scss']
})
export class LoadingRendererComponent implements ILoadingCellRendererAngularComp {

  public params!: ILoadingCellRendererParams & { loadingMessage: string };

  public constructor() { }

  public agInit(params: ILoadingCellRendererParams & { loadingMessage: string }): void {
      this.params = params;
  }
}
