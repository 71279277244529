import { Injectable } from '@angular/core';
import { PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';

import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PaginatedResourceConfig } from '@grid-ui/common';
import { AnnouncementsHttpResponse, ModuleAnnouncementsHttpResponse } from '../models/announcements-http-response.model';
import { ModuleAnnouncementQueryParams } from '../models/module-annoucements-query-params.model';

@Injectable()
export class AnnouncementHttpService {

    private ResourceConfig: NonPaginatedResourceConfig;
    private moduleAnnouncementResourceConfig: PaginatedResourceConfig;

    constructor(
        private readonly http: PortalHttpClient,
    ) {
        this.ResourceConfig = API_SERVICES_CONFIG.v3.announcementBar._configuration;
        this.moduleAnnouncementResourceConfig = API_SERVICES_CONFIG.feApi.moduleAnnouncements._configuration;
    }

    public getAnnouncements(): Observable<AnnouncementsHttpResponse> {
        return this.http.get<AnnouncementsHttpResponse>(
            this.ResourceConfig,
            { queryParams: { detail: true } }
        );
    }

    public getModuleAnnouncements(queryParams?: ModuleAnnouncementQueryParams): Observable<ModuleAnnouncementsHttpResponse> {
        return this.http.get<ModuleAnnouncementsHttpResponse>(
            this.moduleAnnouncementResourceConfig,
            queryParams ? { queryParams } : {}
        );
    }
}
