<div class="form-control search-input">
    <div class="icon search-input__search-icon">
    </div>
    <input type="text" class="search-input__input" #searchTermInput
            [formControl]="searchTerm"
            [placeholder]="placeholder"
            (keyup)="checkForEnter($event)"
    />
    <div role="button" class="icon search-input__cross-icon" *ngIf="searchTerm.value && searchTerm.value.length" (click)="clearSearchTerm()">
    </div>
</div>
