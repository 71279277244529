<div class="focusbox-container" *ngIf="value"
  mcWrittenContentAnchorClickListener
  (anchorClick)="emitWrittenContentLinkAction($event)"
>
  <h4 class="focusbox-header"><span>Focus box</span></h4>
  <h4 class="focusbox-title">{{value!.title}}</h4>
  <span
    class="focusbox-body"
    [innerHtml]="value!.body | updateLink | safeHtml">
    </span>
<div>
