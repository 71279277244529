import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VideoItemValue } from '../../../shared-models';
import { BLANK_VIDEO_ITEM } from './body-item-video-player';

@Component({
  selector: 'grid-ui-body-item-video',
  templateUrl: './body-item-video.component.html',
  styleUrls: ['./body-item-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemVideoComponent implements OnChanges {

  @Input() public value: VideoItemValue | null = null;

  public ngOnChanges(changes: SimpleChanges): void {
    this.value = changes.value ? changes.value.currentValue as VideoItemValue : BLANK_VIDEO_ITEM;
  }

}
