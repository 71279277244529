import { AnalyseRedirectedResponse } from '../../../shared-models';
import { ApiAnalyseRedirectedResponse } from '../../models';

export function mapAnalyseRedirectedResponseFromApiToApp(
  apiResponse: ApiAnalyseRedirectedResponse
): AnalyseRedirectedResponse {
  const mappedResponse: AnalyseRedirectedResponse = {
    ...apiResponse,
    id: `${apiResponse.id}`
  };
  return mappedResponse;
}
