export enum AskAnAnalystSourceEnum {
  None = 'None',
  CountryRiskInsights = 'Country Risk Insights',
  CountryRiskProfiles = 'Country Risk Profiles',
  CountryRiskAnalyse = 'Country Risk Analyse',
  IndustryRisk = 'Industry Risk',
  CountryRiskExplainTheIndex = 'Country Risk Explain the Index Drop-Down',
  CommodityRiskInsights = 'Commodity Risk Insights',
};

export interface AskAnAnalystRequest {

  // Subject of the message being sent to the analyst
  subject: string;

  // Question for the analyst
  message: string;

  // Url relating to the question
  requesting_url: string;

  // Email to contact the analyst
  target_analyst: string;

  // Extra optional details to be kept as part of the request
  meta?: any;

  // Source is the area of the application that the user is in
  source: AskAnAnalystSourceEnum;
}
