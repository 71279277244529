import { Pipe, PipeTransform } from '@angular/core';
import {
  LegacyAnchorLinkAttributeModule,
  LegacyAnchorLinkAttributes,
  LegacyAnchorLinkAttributeSection
} from '../../../shared-models';


@Pipe({
  name: 'updateLink'
})
export class UpdateLinkPipe implements PipeTransform {
  private readonly anchorLinkPattern: RegExp = /<a[\s]+[^>]+module[s]?=[^>]+>/g;
  private readonly indexPattern: RegExp = /index="(\S+)"/;
  private readonly modulePattern: RegExp = /module="(\S+)"/;
  private readonly pageIdPattern: RegExp = /page_id="(\d+)"/;
  private readonly sectionPattern: RegExp = /section="(\S+)"/;
  private readonly validModules = ['country-risk', 'country_risk', 'commodity-risk', 'commodity_risk'];
  private readonly validSections = ['insights', 'profiles', 'analyse'];

  private readonly noLinkAnchorLink = '<a class="navigate-newtab" target="_blank" rel="noopener noreferrer">';
  private readonly hrefPortal2AnchorTemplate = '<a class="navigate-newtab" target="_blank" rel="noopener noreferrer" href="${href}">';
  private readonly indexPathPortal2AnchorTemplate =
  '<a class="navigate-newtab written-content-link" target="_blank" rel="noopener noreferrer" indexPath="${indexPath}">';

  private isValidModule = (x: string): x is LegacyAnchorLinkAttributeModule => this.validModules.includes(x);
  private isValidSection = (x: string): x is LegacyAnchorLinkAttributeSection => this.validSections.includes(x);

  public transform(html: string): string {

    let anchorLinkMatch: RegExpExecArray | null;
    let updatedHtml: string | null = null;

    // Find all the anchor links that match the regular expression
    // eslint-disable-next-line no-cond-assign
    while (anchorLinkMatch = this.anchorLinkPattern.exec(html)) {
      // Get the actual anchor link
      const anchorLink = anchorLinkMatch[0];

      let anchorLinkReplacement: string | null = null;

      if (anchorLink.includes('modules="[')) {
        anchorLinkReplacement = this.noLinkAnchorLink;
      } else {
        const anchorLinkAttributes = this.fetchAnchorLinkAttributes(anchorLink);

        if (this.isValidInsightsOrProfilesLink(anchorLinkAttributes)) {
          // TODO: temporary workaround for P2-3261, put in place when commodity risk was disabled for all users
          // when it is enabled this needs reviewing and likely to use permissions to determine if change of module is required
          anchorLinkAttributes.module = 'country-risk';

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, max-len
          const newHref = `/${anchorLinkAttributes.module!.replace('_', '-')}/${anchorLinkAttributes.section}/${anchorLinkAttributes.page_id}/`;
          anchorLinkReplacement = this.hrefPortal2AnchorTemplate.replace('${href}', newHref);
        } else if (this.isValidAnalyseLink(anchorLinkAttributes)) {
          const indexPath = `/country-risk/analyse;index=${anchorLinkAttributes.index}`;
          anchorLinkReplacement = this.indexPathPortal2AnchorTemplate.replace('${indexPath}', indexPath);
        }
      }

      if (anchorLinkReplacement != null) {
        if (!updatedHtml) {
          updatedHtml = html.slice(0);
        }
        updatedHtml = updatedHtml.replace(
          anchorLink,
          anchorLinkReplacement
        );
      }
    }
    return updatedHtml || html;
  }

  private fetchAnchorLinkAttributes(anchorLink: string): LegacyAnchorLinkAttributes {
    const anchorLinkAttributes: LegacyAnchorLinkAttributes = {};

    const indexMatch = this.getMatchedAttribute(anchorLink, this.indexPattern);
    if (indexMatch) {
      anchorLinkAttributes.index = indexMatch;
    }

    const moduleMatch = this.getMatchedAttribute(anchorLink, this.modulePattern);
    if (moduleMatch && this.isValidModule(moduleMatch)) {
      anchorLinkAttributes.module = moduleMatch;
    }

    const pageIdMatch = this.getMatchedAttribute(anchorLink, this.pageIdPattern);
    if (pageIdMatch) {
      const pageId = parseInt(pageIdMatch, 10);
      if (!isNaN(pageId)) {
        anchorLinkAttributes.page_id = pageId;
      }
    }

    const sectionMatch = this.getMatchedAttribute(anchorLink, this.sectionPattern);
    if (sectionMatch && this.isValidSection(sectionMatch)) {
      anchorLinkAttributes.section = sectionMatch;
    }

    return anchorLinkAttributes;
  }

  private getMatchedAttribute(anchorLink: string, pattern: RegExp): string | null {
    const match = anchorLink.match(pattern);
    return match && match.length > 1 ? match[1] : null;
  }

  private isValidAnalyseLink(anchorLinkAttributes: LegacyAnchorLinkAttributes): boolean {
    return anchorLinkAttributes.module !== undefined
      && (anchorLinkAttributes.module === 'country-risk' || anchorLinkAttributes.module === 'country_risk')
      && anchorLinkAttributes.section === 'analyse'
      && anchorLinkAttributes.index !== undefined;
  }

  private isValidInsightsOrProfilesLink(
    anchorLinkAttributes: LegacyAnchorLinkAttributes
  ): boolean {
    return anchorLinkAttributes.module !== undefined
      && (anchorLinkAttributes.module === 'country-risk'
        || anchorLinkAttributes.module === 'country_risk'
        || anchorLinkAttributes.module === 'commodity-risk'
        || anchorLinkAttributes.module === 'commodity_risk')
      && anchorLinkAttributes.section !== undefined
      && (anchorLinkAttributes.section === 'insights' || anchorLinkAttributes.section === 'profiles')
      && anchorLinkAttributes.page_id !== undefined;
  }
}

