import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { WhoAmIService } from '../../../api-services';
import {
  getWhoAmI,
  getWhoAmIFailed,
  getWhoAmISuccess,
  whoAmILoadUser,
  whoAmILoadUserFailed,
  whoAmILoadUserSucceeded,
  whoAmISaveUser,
  whoAmISaveUserFailed,
  whoAmISaveUserSucceeded,
} from './who-am-i.actions';

@Injectable()
export class WhoAmIEffects {
  public getWhoAmI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWhoAmI),
      switchMap(() =>
        this.whoAmIService.getWhoAmI().pipe(
          map((whoAmI) => getWhoAmISuccess({ whoAmI })),
          catchError((error) => of(getWhoAmIFailed({ error })))
        )
      )
    )
  );

  public getWhoAmISuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWhoAmISuccess),
      switchMap(() => of(whoAmILoadUser()))
    )
  );

  public loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(whoAmILoadUser),
      switchMap(() =>
        this.whoAmIService.getUserPersonalInformation().pipe(
          map((user) => whoAmILoadUserSucceeded({ payload: user })),
          catchError((error) => of(whoAmILoadUserFailed({ payload: error })))
        )
      )
    )
  );

  public saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(whoAmISaveUser),
      mergeMap((action) =>
        this.whoAmIService.changeUserPersonalInformation(action.payload).pipe(
          map((user) => whoAmISaveUserSucceeded({ payload: user })),
          catchError((error) => of(whoAmISaveUserFailed({ payload: error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly whoAmIService: WhoAmIService
  ) {}
}
