<div class="download-image" ngbTooltip="Export Image" placement="top">
  <a (click)="downloadImage($event)">
    <span class="download-button">
      <grid-ui-icon class="icon" name="download"></grid-ui-icon>
    </span>
  </a>
</div>

<div class="offscreen">
  <div #legend class="legend-container">
    <grid-ui-global-legend-expanded></grid-ui-global-legend-expanded>
  </div>
</div>
<div #offscreenclone class="offscreen" [ngStyle]="optionalStyle"></div>
