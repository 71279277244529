import { RetryConfiguration } from './retry-configuration.model';

export interface BaseResourceConfig {
  path: string;
  queryable?: boolean;
  retryConfig?: RetryConfiguration;
  pathParams?: any;
}

export interface NonPaginatedResourceConfig extends BaseResourceConfig {
  /** Number of seconds the response will be cached locally */
  expiration?: number;
}

export function isNonPaginatedResourceConfig(config: BaseResourceConfig): config is NonPaginatedResourceConfig {
  const paginatedConfig = config as PaginatedResourceConfig;
  return paginatedConfig.maxPageSize === undefined
    && (paginatedConfig.queryable === undefined || paginatedConfig.queryable === true);
}


export function isPaginatedResourceConfig(config: BaseResourceConfig): config is PaginatedResourceConfig {
  const paginatedConfig = config as PaginatedResourceConfig;
  return paginatedConfig.maxPageSize !== undefined && paginatedConfig.defaultPageSize !== undefined
    && (paginatedConfig.queryable === undefined || paginatedConfig.queryable === true);
}

export interface PaginatedResourceConfig extends BaseResourceConfig {
  maxPageSize: number;
  defaultPageSize: number;
}

export interface NotQueryableResourceConfig extends BaseResourceConfig {
  queryable: false;
}

export interface PollingResourceConfig extends BaseResourceConfig {
  pollingEndpoint: BaseResourceConfig;
  pollingInterval: number;
  pollLimit: number;
}
