import { Directive, HostListener, Input } from '@angular/core';

import { Placement, CitationInfo } from '../../../shared-models';

interface RelativeCoordinates {
  x: number;
  y: number;
  placement: Placement;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mcCitationPopover]'
})
export class CitationPopoverDirective {
  @Input() public host?: {data: CitationInfo};

  private lastElementClicked: HTMLElement | null = null;

  public isShown(): boolean {
    return !!(this.host && this.host.data && this.host.data.show);
  }

  /**
   * Get coordinates of a popover relative to the event target.
   *
   * @param event the mouse event triggering the citation.
   */
  private getRelativeCoordinates(event: MouseEvent): RelativeCoordinates {
    const target = event.target as HTMLElement;
    // The citation is styled to have max-width: 50%, so we need to get the
    // width of the relevant container.
    const container =
      (target.parentElement as HTMLElement).parentElement as HTMLElement;
    const containerWidth = container.offsetWidth;
    const boxWidth = Math.ceil(containerWidth / 2);
    const arrowWidth = 24;
    const left = target.offsetLeft;

    // Attempt to place the citation popover to the right.
    const y = target.offsetTop - 126;
    if (left + boxWidth + arrowWidth < containerWidth) {
      return {
        x: left + arrowWidth,
        y,
        placement: 'right',
      };
    }

    // As placement to the right isn't possible, consider placing to the left.
    if (left > boxWidth + arrowWidth) {
      return {
        x: left - boxWidth - 2 * arrowWidth,
        y,
        placement: 'left',
      };
    }

    // Otherwise, attempt to place it below.
    return {
      x: left - Math.ceil(boxWidth / 2),
      y: target.offsetTop + arrowWidth,
      placement: 'bottom',
    };
  }

  /** Handle a click event, opening the popover if appropriate. */
  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target || target.tagName !== 'SUP') {
      return;
    }

    const parent = target.parentElement as HTMLElement || null;
    if (
      !parent ||
      !parent.classList.contains('citation') ||
      !this.host ||
      !this.host.data
    ) {
      return;
    }

    const dataset = parent.dataset;
    const { x, y, placement } = this.getRelativeCoordinates(event);
    const data = this.host.data;
    data.location = {
      top: `${y}px`,
      left: `${x}px`,
    };
    data.placement = placement;
    data.type = parent.getAttribute('type') || undefined;
    data.title = dataset.title;
    data.source = dataset.source;
    data.url = dataset.url;
    data.author = dataset.author;
    data.interviewee = dataset.interviewee;
    data.jobTitle = dataset.jobTitle;
    data.interviewType = dataset.interviewType;

    // Display the element
    data.show = true;

    // Save the last clicked element (used for closing on outside click)
    this.lastElementClicked = target;
  }

  /**
   * Hide the popover on clicks outside the popover.
   *
   * @param target the click event target.
   */
  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(target: HTMLElement): void {
    if (
      this.lastElementClicked !== target &&
      this.host && this.host.data && this.host.data.show
    ) {
      this.host.data.show = false;
    }
  }

}
