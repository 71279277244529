import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  API_SERVICES_CONFIG,
  PaginatedResourceConfig,
  NonPaginatedResourceConfig,
  HttpGETCustomOptions,
  QueryParams,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  IndexWithMaplecroftRiskViews
} from '@grid-ui/common';

import {
  CountryRiskIndex,
  CountryRiskIndexNarrative,
  CountryRiskIndexQueryParams,
  CountryRiskIndexIndicatorProperties,
} from '../../../shared-models';

import {
  ApiCountryRiskIndexNarrative,
  ApiCountryRiskIndicesCollection,
  ApiCountryRiskIndexIndicatorsList,
  ApiCountryRiskIndexIndicator
} from '../models';


// TODO: Implement caching once ETags are implemented server-side

/**
 * Service for accessing the Country Risk Indices API
 */
@Injectable()
export class CountryRiskIndicesService {

  private indicesResourcesConfig: PaginatedResourceConfig;
  private indexResourcesConfig: NonPaginatedResourceConfig;
  private indexNarrativeresourcesConfig: NonPaginatedResourceConfig;
  private indexViewsResourcesConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.indicesResourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.indices._configuration;
    this.indexResourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.indices.index._configuration;
    this.indexNarrativeresourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.indices.index.narrative._configuration;
    this.indexViewsResourcesConfig = API_SERVICES_CONFIG.feApi.indexViews._configuration;
  }

  // TODO: Support arguments to match API parameters (name, theme, updatedSince, configuration)
  /**
   * Get a list of Country Risk Indices which are within the users entitlement.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskIndices(
    riskIndexQueryParams?: CountryRiskIndexQueryParams | null,
    defaultPageSize = 600,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskIndex[]> {
    // TODO: Implement caching once ETags are supported server-side
    return this.http.get<ApiCountryRiskIndicesCollection>(
      this.indicesResourcesConfig,
      {
        ...options,
        queryParams: { page_size: defaultPageSize, ...(riskIndexQueryParams || {}) },
      }
    ).pipe(
      map(responseWithContext => responseWithContext.results)
    );
  }

  /**
   * Get a list of all Country Risk Indices and maplecroft risk views they are contained within.
   */
  public getRiskIndicesWithMaplecroftRiskViews() {
    return this.http.get<IndexWithMaplecroftRiskViews[]>(
      this.indexViewsResourcesConfig
    );
  }

  /**
   * Get the risk index methodology narrative for the specified risk index.
   *
   * @param id Unique ID of risk index
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskIndexNarrative(
    id: string,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskIndexNarrative> {
    return this.http.get<ApiCountryRiskIndexNarrative>(
      this.indexNarrativeresourcesConfig,
      {
        ...options,
        pathParams: { id }
      }
    );
  }

  /**
   * Get a list of indicator properties for all descendent indicator groups and
   * indicators of the specified risk index.
   *
   * @param id Risk index ID
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskIndexIndicatorProperties(
    id: string,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<Map<string, CountryRiskIndexIndicatorProperties>> {
    const edition = 'indicators';
    return this.http.get<ApiCountryRiskIndexIndicatorsList>(
      this.indexResourcesConfig,
      {
        ...options,
        pathParams: { id, edition }
      }
    ).pipe(
      map(list => this.flattenRiskIndexIndicatorPropertiesList(list))
    );
  }

  /**
   * Create a map from indicator/indicator group ID to indicator/indicator group properties
   * from the specified nested indicator subtree properties list.
   *
   * @param list A nested list of indicator/indicator group properties or a given risk index.
   */
  private flattenRiskIndexIndicatorPropertiesList(
    list: ApiCountryRiskIndexIndicatorsList
  ): Map<string, CountryRiskIndexIndicatorProperties> {
    const flattenedMap = new Map<string, CountryRiskIndexIndicatorProperties>();
    return this.addChildIndicatorProperties(list.children, flattenedMap);
  }

  /**
   * Recursively add the indicator/indicator group children to the specified
   * map and return an updated map.
   *
   * @param children An array of indicator/indicator group children properties
   * @param flattenedMap The map to be added to.
   */
  private addChildIndicatorProperties(
    children: ApiCountryRiskIndexIndicator[],
    flattenedMap: Map<string, CountryRiskIndexIndicatorProperties>
  ): Map<string, CountryRiskIndexIndicatorProperties> {
    children.forEach(child => {
      flattenedMap.set(child.id, child);
      if (child.children.length) {
        flattenedMap = this.addChildIndicatorProperties(child.children, flattenedMap);
      }
    });
    return flattenedMap;
  }
}
