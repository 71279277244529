import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

/**
 * Use this component when the user does not have _any_ data
 * available to display for a specific entity (e.g. Risk Views, Documents or Extracts).
 *
 * I.e. there are no data available at all, due to missing entitlements or none
 * have been created yet, without any specific search filter having been applied.
 *
 * When there are no results matching a _specific_ search term, use the tag/component
 * `<mc-no-matching-results>` instead.
 */
@Component({
  selector: 'mc-no-data-available',
  templateUrl: './no-data-available.component.html',
  styleUrls: ['./no-data-available.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataAvailableComponent {

  @Input() actionButtonLabel: string | undefined;
  @Input() message = 'You don\'t have any data available';
  @Input() topMargin = 130;

  @Output() actionButtonClicked = new EventEmitter();

  public performAction(): void {
    this.actionButtonClicked.emit();
  }
}
