import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedPipesModule } from '../../shared-pipes';
import { IconModule } from '../icon';
import { IndexInfoIconComponent } from './index-info-icon.component';

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    SharedPipesModule,
    IconModule
  ],
  declarations: [
    IndexInfoIconComponent
  ],
  exports: [
    IndexInfoIconComponent
  ]
})
export class IndexInfoIconModule { }

