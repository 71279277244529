import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { SearchInputModule } from '../search-input';
import { LoadingModule } from '../loading';
import { ErrorModule } from '../error';
import { IconModule } from '../icon';
import { AdditionalCountriesOfferModule } from '../additional-countries-offer';
import { QuickSearchDropdownComponent } from './quick-search-dropdown/quick-search-dropdown.component';
import { QuickSearchDropdownResultComponent } from './quick-search-dropdown-result/quick-search-dropdown-result.component';
import { QuickSearchDropdownResultsComponent } from './quick-search-dropdown-results/quick-search-dropdown-results.component';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    SearchInputModule,
    LoadingModule,
    ErrorModule,
    IconModule,
    AdditionalCountriesOfferModule
  ],
  declarations: [
    QuickSearchDropdownComponent,
    QuickSearchDropdownResultComponent,
    QuickSearchDropdownResultsComponent
  ],
  exports: [
    QuickSearchDropdownComponent
  ]
})
export class QuickSearchDropdownModule { }
