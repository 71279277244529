import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { API_SERVICES_CONFIG } from '@grid-ui/common';
import { HttpErrorConfigItems } from '@grid-ui/common';

@Component({
  selector: 'mc-routed-error',
  templateUrl: './routed-error.component.html',
  styleUrls: ['./routed-error.component.scss']
})
export class RoutedErrorComponent implements OnInit {

  public code: string;
  public contactEmail: string | undefined;
  public message: string;
  public showContact = false;
  public title: string;

  private globalErrorConfig: HttpErrorConfigItems;

  constructor(
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.globalErrorConfig = API_SERVICES_CONFIG.globalHttpErrorConfig;

    this.route.paramMap.pipe(
      map((paramMap: ParamMap) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.code = paramMap.has('code') ? paramMap.get('code')! : 'default';

        if (!(this.code in this.globalErrorConfig)) {
          this.code = 'default';
        }

        // TODO: @Gunjan We need to address the below: this.globalErrorConfig[this.code].title
        // and this.globalErrorConfig[this.code].message can be undefined as per their type,
        // whereas this.title and this.message assume to always receive a string

        this.title = paramMap.has('title')
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ? paramMap.get('title')!
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          : this.globalErrorConfig[this.code].title || this.globalErrorConfig['default'].title!;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.message = paramMap.has('message')
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ? paramMap.get('message')!
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          : this.globalErrorConfig[this.code].message || this.globalErrorConfig['default'].message!;

        return paramMap;
      })
    ).subscribe(() => {
      this.contactEmail = this.globalErrorConfig[this.code].contactEmail;
      this.showContact = this.globalErrorConfig[this.code].showContact || false;
    });
  }
}
