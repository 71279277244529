import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import {
  API_SERVICES_CONFIG,
  NonPaginatedResourceConfig,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS, HttpGETCustomOptions
} from '../../../api';

import { AlertFrequency, ApiSavedSearch, SavedSearch, SavedSearchConfig } from '../models';

/** Convert an {@link AlertFrequency} to a 'frequency in days' number. */
const convertFrequencyToApi: { [key: string]: null | 0 | 1 | 7 } = {
  off: null,
  instantly: 0,
  daily: 1,
  weekly: 7,
};

const retryRequest = <T>(obs: Observable<T>, retries: number) =>
  retries > 0 ? obs.pipe(retry(retries)) : obs;

@Injectable()
export class SavedSearchesService {

  private resourceConfig: NonPaginatedResourceConfig;
  private detailResourceConfig: NonPaginatedResourceConfig;

  public constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.savedSearches._configuration;
    this.detailResourceConfig = API_SERVICES_CONFIG.v3.savedSearches.detail._configuration;
  }

  public getSearch(id: number | string, options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<SavedSearch> {
    return this.http.get<ApiSavedSearch>(
      this.detailResourceConfig,
      {
        ...options,
        pathParams: { id }
      }
    ).pipe(map(ass => this.convertFromApiSearch(ass)));
  }

  public getAllSearches(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<SavedSearch[]> {
    return this.http.get<{ results: ApiSavedSearch[] }>(
      this.resourceConfig,
      options
    ).pipe(map(({ results }) => results.map(ss => this.convertFromApiSearch(ss))));
  }

  public createSearch(
    search: SavedSearchConfig,
    customRetryAttempts?: number
  ): Observable<SavedSearch> {
    const apiSearch = this.convertToApiSearch(search);
    return this.http.post<ApiSavedSearch>(
      this.resourceConfig,
      {
        body: apiSearch,
        retryOptions: { customRetryAttempts }
      }
    ).pipe(map(ass => this.convertFromApiSearch(ass)));
  }

  public updateSearch(
    id: number | string,
    search: Partial<SavedSearch>,
    customRetryAttempts?: number
  ): Observable<SavedSearch> {
    const apiSearch = this.convertToApiSearch(search);
    return this.http.patch<ApiSavedSearch>(
      this.detailResourceConfig,
      {
        body: apiSearch,
        pathParams: { id },
        retryOptions: { customRetryAttempts }
      }).pipe(map(ass => this.convertFromApiSearch(ass)));
  }

  public deleteSearch(id: number | string, customRetryAttempts?: number): Observable<null> {
    return this.http.delete<void>(
      this.detailResourceConfig,
      {
        pathParams: { id },
        retryOptions: { customRetryAttempts }
      }
    ).pipe(
      map(() => null)
    );
  }

  /** Convert a SavedSearch to an ApiSavedSearch. */
  public convertToApiSearch(ss: SavedSearch): ApiSavedSearch;
  public convertToApiSearch(ss: Partial<SavedSearch>): Partial<ApiSavedSearch>;
  public convertToApiSearch(ss: Partial<SavedSearch>): Partial<ApiSavedSearch> {
    const ass: Partial<ApiSavedSearch> = {};
    if (ss.id) {
      ass.id = ss.id;
    }
    // TODO: when the API can handle new-style searches, replace with:
    // if (ss.search) { ass.search = ss.search; }
    if (ss.search !== undefined) {
      ass.search = this.convertToApiSearchString(ss.search);
    }
    if (ss.name) {
      ass.name = ss.name;
    }
    if (ss.date) {
      ass.date = ss.date;
    }
    if (ss.frequency) {
      ass.email_frequency_in_days = convertFrequencyToApi[ss.frequency];
    }
    if (ss.isReference !== undefined) {
      ass.is_reference = ss.isReference;
    }
    return ass;
  }

  /**
   * Convert a possibly old-style search string to a new-style one.
   *
   * @example
   * savedSearchesService.normaliseSearchString(
   *   '?search=search%3D%22bananas%22%26search%3D%22coconuts%22')
   * // '"bananas" "coconuts"'
   *
   * @example
   * savedSearchesService.normaliseSearchString('"bananas" "coconuts"');
   * // '"bananas" "coconuts"'
   */
  public normaliseSearchString(str: string): string {
    if (!str.startsWith('?search=')) {
      return str;
    }
    return decodeURIComponent(str.substr(8))
      .replace('&search=', ' ')
      .replace(/^search=/, '');
  }

  /** Convert a search string to an old-style API search. */
  public convertToApiSearchString(searchStr: string): string {
    return '?search=' + encodeURIComponent(searchStr);
  }

  /** Tokenize a search string input. */
  public tokenizeSearchString(searchStr: string): string[] {
    const tokens: string[] = [];
    const len: number = searchStr.length;
    let tokenStart = 0;
    for (let i = 0; i < len; ++i) {
      const ch = searchStr[i];
      // If open-quote, consume all text until the next quote mark (or the end
      // of the string).
      if (ch === '"') {
        tokenStart = ++i;
        while (i < len && searchStr[i] !== '"') {
          ++i;
        }
        tokens.push(searchStr.substring(tokenStart, i));
        continue;
      }

      if (/\w/.test(ch)) {
        tokenStart = i;
        while (i < len && /\w/.test(searchStr[i])) {
          ++i;
        }
        tokens.push(searchStr.substring(tokenStart, i));
        continue;
      }
    }

    return tokens;
  }

  /** Convert an ApiSavedSearch to a SavedSearch. */
  private convertFromApiSearch(ass: ApiSavedSearch): SavedSearch {
    let frequency: AlertFrequency = 'off';
    switch (ass.email_frequency_in_days) {
      case 0: frequency = 'instantly'; break;
      case 1: frequency = 'daily'; break;
      case 7: frequency = 'weekly'; break;
      default: break;
    }

    return {
      id: ass.id,
      search: this.normaliseSearchString(ass.search),
      name: ass.name,
      date: ass.date,
      frequency,
      isReference: ass.is_reference,
    };
  }
}
