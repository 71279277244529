import { ActionReducerMap } from '@ngrx/store';
import { WhoAmIState, whoAmIReducer } from './who-am-i';

export interface AppState {
  whoAmI: WhoAmIState;
}

export const coreReducers: ActionReducerMap<AppState> = {
  whoAmI: whoAmIReducer
};
