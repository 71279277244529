import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureToggleService } from '../services/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleGuard implements CanLoad , CanActivate {
  public constructor(
    private readonly featureToggleService: FeatureToggleService,
    private router: Router
  ) { }

  public canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.handleRouteLoad(route);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.handleRouteLoad(route);
  }

  private handleRouteLoad(route: Route | ActivatedRouteSnapshot) {
    if (!route.data || !Object.prototype.hasOwnProperty.call(route.data, 'featureToggleName')) {
      return this.redirectHome();
    }
    const featureToggles = this.featureToggleService.getFeatureToggles();

    if (featureToggles[route.data['featureToggleName']]) {
      return true;
    } else {
      return this.redirectHome(route.data['featureToggleName']);
    }
  }

  private redirectHome(featureKey?: string): boolean {
    this.router.navigate(['/home']);
    return false;
  }
}
