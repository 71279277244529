export interface IndexInfoPopoverOpenEvent {
  state: 'open';
  /** Risk Index ID to fetch narrative from */
  riskIndexId: string;
}

export interface IndexInfoPopoverCloseEvent {
  state: 'close';
}

export type IndexInfoPopoverToggleEvent = IndexInfoPopoverOpenEvent | IndexInfoPopoverCloseEvent;

export function isIndexInfoPopoverOpenEvent(event: IndexInfoPopoverToggleEvent): event is IndexInfoPopoverOpenEvent {
  return event.state === 'open';
}
