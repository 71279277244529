import { PollStatusEnum } from '../polling';

export enum DataWizardExtractErrorType {
  EXTRACT_FAILED = 'EXTRACT_FAILED',
  EXTRACT_FAILED_SCORING_IN_PROGRESS = 'EXTRACT_FAILED_SCORING_IN_PROGRESS',
  EXTRACT_TIMEOUT = 'EXTRACT_TIMEOUT',
  CANCEL_EXTRACT_FAILED = 'CANCEL_EXTRACT_FAILED',
  EXTRACT_FAILED_DUPLICATE = 'EXTRACT_ALREADY_RUNNING',
  EXTRACT_PARAMETERS_REQUIRED = 'EXTRACT_PARAMETERS_REQUIRED',
}

export function mapPollStatusToDataWizardExtractErrorType(pollStatus: PollStatusEnum): DataWizardExtractErrorType | null {
  switch (pollStatus) {
    case PollStatusEnum.Failed:
      return DataWizardExtractErrorType.EXTRACT_FAILED;
    case PollStatusEnum.FailedDuplicate:
      return DataWizardExtractErrorType.EXTRACT_FAILED_DUPLICATE;
    case PollStatusEnum.FailedScoringInProgress:
      return DataWizardExtractErrorType.EXTRACT_FAILED_SCORING_IN_PROGRESS;
    case PollStatusEnum.TimedOut:
      return DataWizardExtractErrorType.EXTRACT_TIMEOUT;
    case PollStatusEnum.ParameterRequired:
      return DataWizardExtractErrorType.EXTRACT_PARAMETERS_REQUIRED;
  }
  return null;
}
