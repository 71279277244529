import { GroupNodeExpandCollapse } from './group-node-expand-collapse.enum';

/** Analyse tree editing related action types */
export enum AnalyseTreeEditActionTypes {
  ADD_GROUP = '[Tree Edit] Add Group Node',
  ADD_LEAF = '[Tree Edit] Add Leaf Node',
  BULK_ADD_LEAF = '[Tree Edit] Bulk Add Leaf Nodes',
  BULK_COPY_GROUP = '[Tree Edit] Bulk Add Nodes by Copying a Group from another view',
  CHANGE_GROUP_NAME = '[Tree Edit] Change Group Node Name',
  CHANGE_GROUP_WEIGHT = '[Tree Edit] Change Group Node Weight',
  CHANGE_GROUP_WEIGHT_AS_PERCENTAGE = '[Tree Edit] Change Group Node Weight As Percentage',
  TRANSIENT_CHANGE_GROUP_WEIGHT_AS_PERCENTAGE = '[Tree Edit] Transient Change Group Node Weight As Percentage',
  CHANGE_LEAF_WEIGHT_AS_PERCENTAGE = '[Tree Edit] Change Leaf Node Weight As Percentage',
  TRANSIENT_CHANGE_LEAF_WEIGHT_AS_PERCENTAGE = '[Tree Edit] Transient Change Leaf Node Weight As Percentage',
  CHANGE_LEAF_WEIGHT = '[Tree Edit] Change Leaf Node Weight',
  CHANGE_VIEW_NAME = '[Tree Edit] Change View Name',
  MOVE_GROUP = '[Tree Edit] Move Group Node',
  MOVE_LEAF = '[Tree Edit] Move Leaf Node',
  RELOAD_APP = '[Tree Edit] Reload App',
  REMOVE_GROUP = '[Tree Edit] Remove Group Node',
  REMOVE_LEAF = '[Tree Edit] Remove Leaf Node',
  RETRY_TREE_LOAD = '[Tree Edit] Retry Tree Load',
  SELECT_GROUP_NODE = '[Tree Edit] Select Group Node',
  SELECT_LEAF_NODE = '[Tree Edit] Select Leaf Node',
  SHOW_ALL = '[Tree Edit] Show All',
  TOGGLE_GROUP = '[Tree Edit] Toggle Group Node Expansion',
  TOGGLE_LEAF_SELECTION = '[Tree Edit] Toggle Leaf Node Selection state',
  TOGGLE_GROUP_SELECTION = '[Tree Edit] Toggle Group Node Selection state',
  BULK_DELETE_SELECTED_NODES = '[Tree Edit] Bulk Delete Selected Nodes',
  TOGGLE_SHOW_WEIGHTS = '[Tree Edit] Toggle show weights'
}

/** Basic payload for a tree edit action on a Group Node */
export interface TreeEditGroupPayload {
  /** Unique Id of group node */
  nodeId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}

/** Basic payload for a tree edit action on a Leaf Node */
export interface TreeEditLeafPayload {
  /** Unique Id of leaf node (i.e. Risk Index Id, Site geo_id or Country geo_id) */
  nodeId: string;
  /** Unique Id of parent group node */
  parentGroupId: string;
  /** Whether the index is subnational */
  isSubnational?: boolean;
}

/** Payload for adding a group node */
export interface TreeEditAddGroupPayload {
  /** Unique Id of parent group node to which group should be added */
  parentGroupId: string;
  /** Name of new group */
  name: string;
  /** Weight of new group */
  weight: number;
}

/** Payload for adding a leaf node or changing its weight */
export interface TreeEditChangeLeafWeightPayload extends TreeEditLeafPayload {
  /** New weight of leaf node */
  weight: number;
}

/** Payload for changing the name of a group node */
export interface TreeEditChangeGroupNamePayload extends TreeEditGroupPayload {
  /** New name of group node */
  name: string;
}
/** Payload for changing the weight of a group node */
export interface TreeEditChangeGroupWeightPayload extends TreeEditGroupPayload {
  /** New weight of group node */
  weight: number;
}

/** Payload for changing the weight percentage of a group node */
export interface TreeEditChangeGroupWeightAsPercentagePayload extends TreeEditGroupPayload {
  /** New weight percentage of group node */
  weightAsPercentage: number;
}

/** Payload for changing the weight percentage of a group node */
export interface TreeEditTransientChangeGroupWeightAsPercentagePayload extends TreeEditGroupPayload {
  /** New weight percentage of group node */
  weightAsPercentage: number;
  /** Unique Id of parent group node */
  parentGroupId: string;
}

/** Payload for changing the weight percentage of a group node */
export interface TreeEditChangeLeafWeightAsPercentagePayload extends TreeEditLeafPayload {
  /** New weight percentage of leaf node */
  weightAsPercentage: number;
}

/** Payload for moving a group node */
export interface TreeEditMoveGroupPayload extends TreeEditGroupPayload {
  // TODO: Decide, if the current parent group is needed to optimize tree reloading
  // It is not required by the API for a group node.
  /** Unique Id of pre-move parent group node */
  currentParentGroupId: string;
  /**
   * Unique Id of post-move parent group node. For intra-group moves, this
   * id is the same as the currentParentGroupId.
   */
  newParentGroupId: string;
  /**
   * Unique Id of a child group node in the post-move parent group after which
   * the moved group node should be inserted. If omitted, by default, the
   * group will be inserted as the first group its post-move parent group
   */
  after?: string;
}

/** Payload for moving a leaf node */
export interface TreeEditMoveLeafPayload {
  /** Unique Id of leaf node (i.e. Risk Index Id, Site geo_id or Country geo_id) */
  nodeId: string;
  /** Unique Id of pre-move parent group node */
  currentParentGroupId: string;
  /**
   * Unique Id of post-move parent group node. For intra-group moves, this
   * id is the same as the currentParentGroupId.
   */
  newParentGroupId: string;
  /**
   * Unique Id of a child leaf node in the post-move parent group after which
   * the moved leaf node should be inserted. If null, the
   * leaf will be inserted as the first leaf node following any child group nodes in
   * its post-move parent group
   */
  after: string | null;
}

/** Payload for selecting a new group node */
export interface TreeEditSelectGroupPayload extends TreeEditGroupPayload {
  /** Node type */
  type: 'group';
}

/** Payload for selecting a new leaf node */
export interface TreeEditSelectLeafPayload extends TreeEditLeafPayload {
  /** Node type */
  type: 'leaf';
}

/** Payload for expand/collapse toggling of a group node */
export interface TreeEditToggleGroupPayload extends TreeEditGroupPayload {
  /** Expand or collapse group node */
  change: GroupNodeExpandCollapse;
}

/** Payload for selection toggling of a group node */
export interface TreeEditSelectionToggleGroupPayload extends TreeEditGroupPayload {
  selected: boolean;
}

/** Payload for selection toggling of a leaf node */
export interface TreeEditSelectionToggleLeafPayload extends TreeEditLeafPayload {
  selected: boolean;
}

/** Add group node action */
export class TreeEditAddGroup {
  public readonly type = AnalyseTreeEditActionTypes.ADD_GROUP;
  constructor(public payload: TreeEditAddGroupPayload) { }
}

/** Add leaf node action */
export class TreeEditAddLeaf {
  public readonly type = AnalyseTreeEditActionTypes.ADD_LEAF;
  constructor(public payload: TreeEditChangeLeafWeightPayload) { }
}

/** Change group node name action */
export class TreeEditChangeGroupName {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_GROUP_NAME;
  constructor(public payload: TreeEditChangeGroupNamePayload) { }
}

/** Change group node weight */
export class TreeEditChangeGroupWeight {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_GROUP_WEIGHT;
  constructor(public payload: TreeEditChangeGroupWeightPayload) { }
}

/** Change group node weight percentage */
export class TreeEditChangeGroupWeightAsPercentage {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_GROUP_WEIGHT_AS_PERCENTAGE;
  constructor(public payload: TreeEditChangeGroupWeightAsPercentagePayload) { }
}

/** Transient change group node weight percentage */
export class TreeEditTransientChangeGroupWeightAsPercentage {
  public readonly type = AnalyseTreeEditActionTypes.TRANSIENT_CHANGE_GROUP_WEIGHT_AS_PERCENTAGE;
  constructor(public payload: TreeEditTransientChangeGroupWeightAsPercentagePayload) { }
}

/** Transient change group node weight percentage */
export class TreeEditChangeLeafWeightAsPercentage {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_LEAF_WEIGHT_AS_PERCENTAGE;
  constructor(public payload: TreeEditChangeLeafWeightAsPercentagePayload) { }
}

/** Change group node weight percentage */
export class TreeEditTransientChangeLeafWeightAsPercentage {
  public readonly type = AnalyseTreeEditActionTypes.TRANSIENT_CHANGE_LEAF_WEIGHT_AS_PERCENTAGE;
  constructor(public payload: TreeEditChangeLeafWeightAsPercentagePayload) { }
}

/** Change leaf node weight */
export class TreeEditChangeLeafWeight {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_LEAF_WEIGHT;
  constructor(public payload: TreeEditChangeLeafWeightPayload) { }
}

/** Move (Drag & Drop) group node action */
export class TreeEditMoveGroup {
  public readonly type = AnalyseTreeEditActionTypes.MOVE_GROUP;
  constructor(public payload: TreeEditMoveGroupPayload) { }
}

/** Move (Drag & Drop) leaf node action */
export class TreeEditMoveLeaf {
  public readonly type = AnalyseTreeEditActionTypes.MOVE_LEAF;
  constructor(public payload: TreeEditMoveLeafPayload) { }
}

/** Remove group node action */
export class TreeEditReloadApp {
  public readonly type = AnalyseTreeEditActionTypes.RELOAD_APP;
  constructor() { }
}

/** Remove group node action */
export class TreeEditRemoveGroup {
  public readonly type = AnalyseTreeEditActionTypes.REMOVE_GROUP;
  constructor(public payload: TreeEditGroupPayload) { }
}

/** Remove leaf node action */
export class TreeEditRemoveLeaf {
  public readonly type = AnalyseTreeEditActionTypes.REMOVE_LEAF;
  constructor(public payload: TreeEditLeafPayload) { }
}

/** Remove group node action */
export class TreeEditRetryTreeLoad {
  public readonly type = AnalyseTreeEditActionTypes.RETRY_TREE_LOAD;
  constructor() { }
}

/** Select group node action */
export class TreeEditSelectGroup {
  public readonly type = AnalyseTreeEditActionTypes.SELECT_GROUP_NODE;
  constructor(public payload: TreeEditSelectGroupPayload) { }
}

/** Remove leaf node action */
export class TreeEditSelectLeaf {
  public readonly type = AnalyseTreeEditActionTypes.SELECT_LEAF_NODE;
  constructor(public payload: TreeEditSelectLeafPayload) { }
}

/** Open or Close Show All popout */
export class TreeEditShowAll {
  public readonly type = AnalyseTreeEditActionTypes.SHOW_ALL;
  constructor(public payload: { open: boolean }) {}
}

/** Expand/collapse toggling of a group node action */
export class TreeEditToggleGroup {
  public readonly type = AnalyseTreeEditActionTypes.TOGGLE_GROUP;
  constructor(public payload: TreeEditToggleGroupPayload) { }
}

/** Selection toggling of a group node action */
export class TreeEditSelectionToggleGroup {
  public readonly type = AnalyseTreeEditActionTypes.TOGGLE_GROUP_SELECTION;
  constructor(public payload: TreeEditGroupPayload) { }
}

/** Selection toggling of a group node action */
export class TreeEditSelectionToggleLeaf {
  public readonly type = AnalyseTreeEditActionTypes.TOGGLE_LEAF_SELECTION;
  constructor(public payload: TreeEditLeafPayload) { }
}

/** Change view name action */
export class TreeEditChangeViewName {
  public readonly type = AnalyseTreeEditActionTypes.CHANGE_VIEW_NAME;
  constructor(public payload: {name: string }) { }
}


/** Remove group node action */
export class TreeEditBulkDeleteSelectedNodes {
  public readonly type = AnalyseTreeEditActionTypes.BULK_DELETE_SELECTED_NODES;
  constructor() { }
}

/** Remove group node action */
export class TreeEditToggleShowWeights {
  public readonly type = AnalyseTreeEditActionTypes.TOGGLE_SHOW_WEIGHTS;
  constructor(public payload: {showWeights: boolean }) { }
}

/** Analyse tree editting related actions */
export type AnalyseTreeEditActionsUnion =
TreeEditAddGroup
| TreeEditAddLeaf
| TreeEditChangeGroupName
| TreeEditChangeGroupWeight
| TreeEditChangeGroupWeightAsPercentage
| TreeEditTransientChangeGroupWeightAsPercentage
| TreeEditChangeLeafWeightAsPercentage
| TreeEditTransientChangeLeafWeightAsPercentage
| TreeEditChangeLeafWeight
| TreeEditMoveGroup
| TreeEditMoveLeaf
| TreeEditReloadApp
| TreeEditRemoveGroup
| TreeEditRemoveLeaf
| TreeEditRetryTreeLoad
| TreeEditSelectGroup
| TreeEditSelectLeaf
| TreeEditShowAll
| TreeEditToggleGroup
| TreeEditSelectionToggleGroup
| TreeEditSelectionToggleLeaf
| TreeEditChangeViewName
| TreeEditBulkDeleteSelectedNodes
| TreeEditToggleShowWeights;

