import { RowDataChangedEvent } from '@ag-grid-community/core';
import { HttpErrorResponse } from '@angular/common/http';

export interface CustomRowLoadingStartedEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'rowLoadingStarted';
}

export interface CustomFilteredTotalUpdatedEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'filteredTotalUpdated';
  filteredTotal: number | null;
  noMatches: boolean;
}

export interface CustomRowDataLoadingFailedEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'rowDataLoadingFailed';
  error: HttpErrorResponse;
  initialPageError: boolean;
}

export interface CustomRowDataLoadingSuccessEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'rowDataLoadingSuccess';
  requestStartRow: number;
  requestEndRow: number;
}

export interface CustomRowDataPinnedRowsAddedEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'rowDataPinnedRowsAdded';
  rowsAdded: number;
}

export interface CustomRetryRowLoadingEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'rowRetryLoading';
}

export interface CustomReloadAppEvent extends RowDataChangedEvent {
  type: 'rowDataChanged';
  readonly customType: 'reloadApp';
}

export type CustomRowDataChangedEvent =
  CustomRowLoadingStartedEvent
  | CustomFilteredTotalUpdatedEvent
  | CustomRowDataLoadingFailedEvent
  | CustomRowDataLoadingSuccessEvent
  | CustomRetryRowLoadingEvent
  | CustomRowDataPinnedRowsAddedEvent
  | CustomReloadAppEvent;

export function isCustomRowDataChangedEvent(
  event: CustomRowDataChangedEvent | RowDataChangedEvent
): event is CustomRowDataChangedEvent {
  return typeof (<CustomRowDataChangedEvent>event).customType === 'string';
}
