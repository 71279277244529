import { Injectable } from '@angular/core';
import { UserPermissions, ApiExternalToolPermission } from '@grid-ui/api-models';
import { FeatureToggleService } from '@grid-ui/common';
import { WhoAmIService } from '../../api-services';
import { ServiceOfferingCard, ServiceOffering } from '../../home/models';
import { AllServiceOfferings } from './service-offerings.const';
import { PermissionSelector, PermissionsService } from './permissions.service';

const DEFAULT_ORDER = 3;

@Injectable()
export class NavService {

  private readonly serviceOfferings: ServiceOffering[] = AllServiceOfferings;

  constructor(
    private readonly whoAmIService: WhoAmIService,
    private readonly permissionsService: PermissionsService,
    private readonly featureToggleService: FeatureToggleService
  ) { }

  public async getPermissions(): Promise<ServiceOfferingCard[]> {
    const internalOfferings = this.serviceOfferings
      .filter(x => !x.card.featureToggle || this.featureToggleService.getFeatureToggles()[x.card.featureToggle])
      .filter(x => x.card.permissionSelector)
      .map(y => this.permissionsService.isAllowed(y.card.permissionSelector as PermissionSelector)
        .then(allowed => ({ allowed, serviceOfferings: y.card })));

    const externalOfferings = await this.whoAmIService.getPermissions().toPromise()
      .then(permissions => this.getExternalServiceOfferings(permissions));

    const allowedInternal = await Promise.all(internalOfferings).then(x => x.filter(y => y.allowed).map(z => z.serviceOfferings));
    let orderedOfferings = [...allowedInternal, ...externalOfferings].sort((a, b) => a.order > b.order ? 1 : -1);
    orderedOfferings = orderedOfferings.map(offering => ({ ...offering, elementId: offering.key ? offering.key : offering.elementId }));

    const allMenusUpdated: Promise<void>[] = [];
    orderedOfferings.forEach(async offering => {
      if (offering.menu) {
        const menuItems = offering.menu.map(
          async menuItem => this.permissionsService.isAllowed(menuItem.permissionSelector as PermissionSelector
          ).then(
            allowed => ({ allowed, menuItem })
          ));
        allMenusUpdated.push(
          Promise.all(menuItems).then(x => x.filter(y => y.allowed).map(z => z.menuItem)).then(x => {
            offering.menu = x;
          }));
      }
    });
    await Promise.all(allMenusUpdated);
    return orderedOfferings;
  }

  private getExternalServiceOfferings(permissions: UserPermissions): ServiceOfferingCard[] {
    const externalPermissions = permissions['external-tools'] as ApiExternalToolPermission[];
    if (!externalPermissions) {
      return [];
    }

    return externalPermissions.map(x => {
      const definedService = this.serviceOfferings.find(y => y.card.key === x.key);
      const defaultImageHref = x.slug.includes('shape') ? x.slug : 'shape-' + x.slug;
      const defaultOrder = DEFAULT_ORDER;

      return definedService
        ? { ...x, order: definedService.card.order, imageHref: definedService.card.imageHref, label: x.name } as ServiceOfferingCard
        : { ...x, order: defaultOrder, imageHref: defaultImageHref, label: x.name } as ServiceOfferingCard;
    });
  }

}
