export class AnalyseViewNameChangeProgress {
  public readonly status = 'progress';
  constructor() { }
}

export class AnalyseViewNameChangeFailure {
  public readonly status = 'failure';
  constructor() { }
}

export class AnalyseViewNameChangeSuccess {
  public readonly status = 'success';
  constructor(public readonly name: string) { }
}

export type AnalyseViewNameChangeStatus =
  AnalyseViewNameChangeProgress
  | AnalyseViewNameChangeFailure
  | AnalyseViewNameChangeSuccess;
