import { Environment } from './environment.interface';

export const industryEnvironment: Environment = {
  production: true,
  api: '/industry-risk/api/v1/',
  frontendApi: '/industry-risk/fe-api/v1/',
  environment: 'production',
  hotjarTrackingCode: '2768721',
  googleTagManagerId: 'GTM-T853VQM',
  csrfToken: 'csrftoken',
};
