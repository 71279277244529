import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterLinkClickListenerDirective } from './routerlink-click-listener.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    RouterLinkClickListenerDirective
  ],
  exports: [
    RouterLinkClickListenerDirective
  ]
})
export class RouterLinkClickListenerModule { }
