import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, retryWhen, tap } from 'rxjs/operators';


import {
  BaseResourceConfig,
  isNonPaginatedResourceConfig,
  NonPaginatedResourceConfig,
  PaginatedResourceConfig,
} from '../models';

import {
  ApiResponseWithQueryContext,
  BasePortalHttpClientRequestParameters,
  PathParams,
  PortalHttpClientRequestParametersWithBody, RetryConfigurationMethod,
  RetryWhenCb,
} from '../models';

import { format } from '../util';
import { ApiLocalCacheService } from './api-local-cache.service';
import { QueryParametersService } from './query-parameters.service';
import { RetryAttemptsMapperService } from './retry-attempts-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class PortalHttpClient {

  public constructor(
    private readonly http: HttpClient,
    private readonly localCache: ApiLocalCacheService,
    private readonly queryParamsService: QueryParametersService,
    private readonly retryAttemptsMapper: RetryAttemptsMapperService
  ) { }

  public delete<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: BasePortalHttpClientRequestParameters
  ): Observable<T> {
    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams);
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.http
      .delete<T>(uri, requestOptions.httpOptions)
      .pipe(
        retryWhen(this.getRetryWhenNotifier('DELETE', resourceConfig, requestOptions))
      );
  }

  public deleteWithBody<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: PortalHttpClientRequestParametersWithBody
  ): Observable<T> {
    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams);
    const options: Record<string, unknown> = {
      body: requestOptions.body,
      headers: requestOptions.httpOptions?.headers,
      params: requestOptions.httpOptions?.params
    };
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.http
      .request<T>('delete', uri, options)
      .pipe(
        retryWhen(this.getRetryWhenNotifier('DELETE', resourceConfig, requestOptions))
      );
  }

  public patch<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: PortalHttpClientRequestParametersWithBody
  ): Observable<T> {
    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams);
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.http
      .patch<T>(uri, requestOptions.body, requestOptions.httpOptions)
      .pipe(
        retryWhen(this.getRetryWhenNotifier('PATCH', resourceConfig, requestOptions))
      );
  }

  public post<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: PortalHttpClientRequestParametersWithBody
  ): Observable<T> {
    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams);
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.http
      .post<T>(uri, requestOptions.body, requestOptions.httpOptions)
      .pipe(
        retryWhen(this.getRetryWhenNotifier('POST', resourceConfig, requestOptions))
      );
  }

  public put<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: PortalHttpClientRequestParametersWithBody
  ): Observable<T> {
    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams);
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.http
      .put<T>(uri, requestOptions.body, requestOptions.httpOptions)
      .pipe(
        retryWhen(this.getRetryWhenNotifier('PUT', resourceConfig, requestOptions))
      );
  }

  private internalGet<T>(
    resourceConfig: BaseResourceConfig,
    requestOptions: BasePortalHttpClientRequestParameters
  ): Observable<T> {
    const expiration: number | null =
      resourceConfig && isNonPaginatedResourceConfig(resourceConfig) && resourceConfig.expiration !== undefined
        ? resourceConfig.expiration
        : null;

    const uri = this.getResourceFormattedPath(resourceConfig, requestOptions.pathParams).toString();
    const httpParams: HttpParams = this.queryParamsService.prepareParameters(requestOptions.queryParams);

    const queryString = httpParams.toString();
    if (!requestOptions.forceAPICall && expiration !== null) {
      const cachedResponse: T | null = this.localCache.getValidCachedResponse(uri, queryString);
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }

    requestOptions = this.setRequestHeaders(requestOptions);

    return this.http.get<T>(uri, { params: httpParams, headers: requestOptions.httpOptions?.headers }).pipe(
      retryWhen(this.getRetryWhenNotifier('GET', resourceConfig, requestOptions)),
      tap((response: T) => {
        if (resourceConfig && expiration !== null) {
          this.localCache.cacheResponse(uri, expiration, queryString, response);
        }
      })
    );
  }

  public get<T>(
    resourceConfig: NonPaginatedResourceConfig,
    requestOptions: BasePortalHttpClientRequestParameters = {}
  ): Observable<T> {
    return this.internalGet<T>(resourceConfig, requestOptions);
  }

  public getPaginated<T>(
    resourceConfig: PaginatedResourceConfig,
    requestOptions: BasePortalHttpClientRequestParameters
  ): Observable<ApiResponseWithQueryContext<T>> {
    const queryParams = requestOptions.queryParams
      ? this.queryParamsService.correctPaginatedParameters(
        requestOptions.queryParams,
        resourceConfig.maxPageSize,
        resourceConfig.defaultPageSize
      )
      : requestOptions.queryParams;
    requestOptions = this.setRequestHeaders(requestOptions);
    return this.internalGet<T>(resourceConfig, { ...requestOptions, queryParams })
      .pipe(
        map((response: T) => ({
          response,
          queryParams: queryParams || null
        }))
      );
  }

  private getResourceFormattedPath(
    resourceConfig: BaseResourceConfig,
    pathParams?: PathParams
  ): string {
    if (resourceConfig.pathParams && pathParams) {
      return format(resourceConfig.path, pathParams);
    } else {
      return resourceConfig.path;
    }
  }

  private getRetryWhenNotifier(
    method: RetryConfigurationMethod,
    resourceConfig: BaseResourceConfig,
    requestOptions: BasePortalHttpClientRequestParameters
  ): (errors: Observable<any>) => Observable<any> {
    const customRetryAttempts = requestOptions.retryOptions ? requestOptions.retryOptions.customRetryAttempts : undefined;
    const retryWhenCb: RetryWhenCb | undefined = requestOptions.retryOptions ? requestOptions.retryOptions.retryWhenCb : undefined;
    return (errors: Observable<any>) =>
      errors.pipe(
        mergeMap((error, index) =>
          (index >= this.retryAttemptsMapper.getRetryAttempts(method, error, resourceConfig.retryConfig, customRetryAttempts)
            || (retryWhenCb && !retryWhenCb(error)))
            ? throwError(error)
            : of(error)
        )
      );
  }

  private setRequestHeaders(requestOptions: BasePortalHttpClientRequestParameters | PortalHttpClientRequestParametersWithBody): any {
    return {
      ...requestOptions,
      httpOptions: {
        ...requestOptions.httpOptions,
        headers: {
          ...requestOptions.httpOptions?.headers,
          'x-client': 'grid-frontend',
        }
      }
    };
  }
}

export interface HttpOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  params?: HttpParams | {
    [param: string]: string | string[];
  };
}

