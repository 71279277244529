<div id="error-box" class="alert">
    <div class="left-container">
        <h1>404</h1>
    </div>
    <div class="right-container">
        <span>The page you requested could not be found.<br>
            You can click here to go to <a routerLink="/home">GRiD / Home</a>
        </span>
    </div>
</div>
