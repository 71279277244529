import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ServiceOfferingCard } from '../../models';

@Component({
  selector: 'mc-home-service-offering-card',
  templateUrl: './home-service-offering-card.component.html',
  styleUrls: ['./home-service-offering-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeServiceOfferingCardComponent {
  @Input() card: ServiceOfferingCard;
  @Input() imageHref: string;

  @Output() navClick = new EventEmitter<string | ServiceOfferingCard>();

  public showMenu = false;

  public toggleMenu(event: Event): void {
    event.stopPropagation();
    this.showMenu = !this.showMenu;
  }

  public linkClick(link: string) {
    this.navClick.emit(link);
  }

  public containerClick(): void {
    if (!this.showMenu) {
      this.navClick.emit(this.card);
    }
  }
}
