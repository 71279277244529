import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../../shared-utilities/window-services';

@Injectable()
export class SvgService {

  private svgIds: { [key: string]: string } = {};

  constructor(
    @Inject(WINDOW) private readonly window: Window | any) {
    if (this.window instanceof Window) {
      // Find all svg loaded on the page and create hash map
      const symbolSvgSprite: NodeListOf<SVGSymbolElement> = this.window.document.querySelectorAll('symbol');
      const svgIds: SVGSymbolElement[] = Array.prototype.slice.call(symbolSvgSprite);
      this.svgIds = svgIds.reduce((acc, item: SVGSymbolElement) => ({ ...acc, ...{ [item.id]: `#${item.id}` } }), {});
    }
  }

  public getNavigationIcon(svgId: string): string {
    const defaultImage = '#shape-verisk';
    return `${this.svgIds[svgId] || defaultImage}`;
  }
}
