<ng-container *ngIf="notification$ | async as notification">
  <div class="wrapper">
    <ng-container *ngIf="notification.type === NotificationBarNotificationType.embedded; else dynamicMessage">
      <ng-container [ngSwitch]="notification.embedded">
        <mc-extract-notification *ngSwitchCase="NotificationBarEmbeddedNotification.dataExtract" (close)="close()">
        </mc-extract-notification>
        <mc-data-table-extract-notification *ngSwitchCase="NotificationBarEmbeddedNotification.dataTableExtract" (close)="close()">
        </mc-data-table-extract-notification>
      </ng-container>

    </ng-container>
    <ng-template #dynamicMessage>
      <div class="dynamic-content row d-flex align-content-center">
        <div class="col-8 offset-2 text-center">
          {{ notification.message }}
        </div>
        <div class="col-2 text-right pr-5 close-text">
          <span (click)="close()">{{ closeText }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
