import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SimpleTabNavBarComponent } from './simple-tab-nav-bar.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SimpleTabNavBarComponent
  ],
  exports: [
    SimpleTabNavBarComponent
  ]
})
export class SimpleTabNavBarModule { }
