import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { SharedPipesModule } from '@grid-ui/common';
import { RouterLinkClickListenerModule } from '../listeners/routerlink-click-listener';
import { NoDataAvailableComponent } from './no-data-available.component';



@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule,
    RouterLinkClickListenerModule
  ],
  declarations: [
    NoDataAvailableComponent
  ],
  exports: [
    NoDataAvailableComponent
  ]
})
export class NoDataAvailableModule { }
