import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CookiesNotificationBannerComponent } from './cookies-notification-banner';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CookiesNotificationBannerComponent
  ],
  exports: [
    CookiesNotificationBannerComponent
  ]
})
export class PrivacySettingsModule { }
