import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class FrontendAssertsService {
  public constructor(
    private readonly http: HttpClient,
  ) {}

  public getAssetAsBase64(urL: string): Observable<string> {
    return this.http.get(urL, {
      observe: 'body',
      responseType: 'arraybuffer',
    })
      .pipe(
        take(1),
        map((buffer) => 'data:image/png;base64,' + btoa(
          Array.from(new Uint8Array(buffer))
            .map(b => String.fromCharCode(b))
            .join('')
        )),
      );
  }
}
