<div *ngIf="value"
  mcWrittenContentAnchorClickListener
  mcCitationPopover
  [host]="citationID"
  (anchorClick)="emitWrittenContentLinkAction($event)"
  class="mc-body-item-paragraph-container"
>
    <div class="item-paragraph" 
      [innerHtml]="value | updateLink | safeHtml"
    ></div>
    <grid-ui-citation #citationID data="citationData"></grid-ui-citation>
</div>
