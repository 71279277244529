import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';

import {
  API_SERVICES_CONFIG,
  PortalHttpClient,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  PaginatedResourceConfig,
  PaginatedCountryV4,
  CountryQueryParamsV4,
} from '../../../api';


@Injectable()
export class CountryServiceV4 {

  private resourceConfig: PaginatedResourceConfig;

  public constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v4.country._configuration;
  }

  public getCountries(
    queryParams: CountryQueryParamsV4 = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    page_size = 250,
    page = 1,
  ): Observable<PaginatedCountryV4> {
    return this.http.get<PaginatedCountryV4>(
      this.resourceConfig,
      { ...options, queryParams: { ...queryParams, page, page_size } },
    );
  }

  public getAllCountries(
    queryParams: CountryQueryParamsV4 = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    page_size = 250,
    page = 1,
  ): Observable<PaginatedCountryV4> {
    const endpointArgs = [ queryParams, options, page_size ] as const;

    return this.getCountries(...endpointArgs, page).pipe(
      expand(data => data?.links?.next
        ? this.getCountries(...endpointArgs, ++page)
        : EMPTY
      ),
      reduce((acc, data) => ({
        ...data,
        results: acc.results.concat(data.results),
      })),
    );
  }
}
