import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../icon';
import { ActionSuccessModalComponent } from './action-success-modal.component';


@NgModule({
  imports: [
    CommonModule,
    NgbModalModule,
    NgbTooltipModule,
    IconModule,
  ],
  declarations: [
    ActionSuccessModalComponent
  ],
  exports: [
    ActionSuccessModalComponent
  ]
})
export class ActionSuccessModalModule { }
