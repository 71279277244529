import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  API_SERVICES_CONFIG,
  NonPaginatedResourceConfig,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  PortalHttpClient,
  HttpGETCustomOptions
} from '@grid-ui/common';

@Injectable()
export class CurrentEditionService {

  private currentEditionResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient
  ) {
    this.currentEditionResourceConfig = API_SERVICES_CONFIG.v3.currentEdition._configuration;
  }

  public getCurrentEdition(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<string> {
    return this.http.get<string>(this.currentEditionResourceConfig, options);
  }
}
