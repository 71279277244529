import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import * as R from 'ramda';
import { PortalHttpClient } from '@grid-ui/common';
import { Environment, environment, EnvironmentAppConfig } from '../../../environments';

export interface FeatureToggles {
  [key: string]: boolean | undefined;
  industry?: boolean;
};

const CONFIG_URL = '/api/v3/feature-flags/';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private featureToggles: FeatureToggles;
  private featureTogglesLoaded = false;
  private url: string = CONFIG_URL;

  constructor(
    private readonly http: PortalHttpClient
  ) { }

  public getConfig(): EnvironmentAppConfig {
    return R.clone(environment.appConfig);
  }

  public getEnvironmentName(): string {
    return environment.environment;
  }

  public getEnvironment(): Environment {
    return R.clone(environment);
  }

  public init() {
    return this.http.get({ path: this.url }, {}).pipe(
      catchError(error => {
        // we want to swallow 403 errors at this stage to allow rest of app to load properly and handle users
        // with auth issues correctly
        if (error.status === 403) {
          return of({});
        }
        return throwError(error);
      }),
      tap((featureToggles: FeatureToggles) => {
        this.featureToggles = featureToggles;
        this.featureTogglesLoaded = true;
      })
    ).toPromise();
  }

}
