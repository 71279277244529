/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpParams } from '@angular/common/http';
import {
  CountryRiskConfigurationDetailIndexLocationResourcesConfig,
  CountryRiskConfigurationDetailIndexTreeResourcesConfig,
  CountryRiskConfigurationDetailLocationTreeResourcesConfig,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  HttpOptions,
  PaginatedResourceConfig,
  PaginationService,
  PathParams,
  PortalHttpClient,
} from '@grid-ui/common';
import * as R from 'ramda';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AnalyseFlatTreeGroupNode,
  AnalyseFlatTreePage,
  AnalyseFlatTreeReferenceNode,
  AnalyseRedirectedResponse,
  AnalyseScorecardFlatTreeNode,
  AnalyseScorecardFlatTreePage,
  AnalyseTreeMultiPageQueryParams,
  AnalyseTreeQueryParams,
  CountryRiskViewsScoredParameters,
  TreeEditAddGroupPayload,
  TreeEditChangeGroupNamePayload,
  TreeEditChangeGroupWeightPayload,
  TreeEditChangeLeafWeightPayload,
  TreeEditGroupPayload,
  TreeEditLeafPayload,
  TreeEditMoveGroupPayload,
  TreeEditMoveLeafPayload
} from '../../../shared-models';
import {
  ApiAnalyseFlatTreeResponse,
  ApiAnalyseRedirectedResponse,
  ApiAnalyseScorecardFlatTreeNode,
  ApiAnalyseScorecardFlatTreeResponse,
  TreeBulkAddLeavesParameter,
  TreeBulkCopyGroupParameter,
  TreeBulkDeleteRequest,
  TreeBulkLeafAdditionIndividualBody,
  TreeBulkLeafAdditionSelectAllBody,
  TreeGroupNodeMoveRequestBody,
  TreeLeafNodeMoveRequestBody
} from '../../models';
import { mapAnalyseFlatTreeNodeFromApiToApp, mapAnalyseRedirectedResponseFromApiToApp } from '../util';

export class CountryRiskViewsBaseTreeService {

  protected indexLocationResourceConfig: CountryRiskConfigurationDetailIndexLocationResourcesConfig;
  protected indexLocationTreeResourceConfig:
  CountryRiskConfigurationDetailIndexTreeResourcesConfig | CountryRiskConfigurationDetailLocationTreeResourcesConfig;

  /** HACK: Use these parameters to obtain a flat node type response to a tree edit transaction */
  private flatNodeEditParams = new HttpParams({ fromObject: { structure: 'flat', page_size: '1' } });

  constructor(
    private readonly treeType: 'index' | 'location',
    private readonly http: PortalHttpClient,
    protected readonly paginationService: PaginationService
  ) {
  }

  public addGroup(viewId: number, data: TreeEditAddGroupPayload): Observable<AnalyseFlatTreeGroupNode> {
    return this.http.post<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode._configuration,
      {
        body: { name: data.name, weight: data.weight },
        pathParams: { viewId, groupNodeId: data.parentGroupId },
        retryOptions: { customRetryAttempts: 0 },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeGroupNode)
    );
  }

  public addLeaf(viewId: number, data: TreeEditChangeLeafWeightPayload): Observable<AnalyseFlatTreeReferenceNode> {
    return this.http.post<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode.leafNodes._configuration,
      {
        body: { id: data.nodeId, weight: data.weight },
        pathParams: { viewId, groupNodeId: data.parentGroupId },
        retryOptions: { customRetryAttempts: 0 },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeReferenceNode)
    );
  }

  /**
   * Bulk copy leaf nodes to the specified view. The leaves may be copied in
   * ungrouped form, or can be newly grouped based on the specified parameters.
   *
   * @param viewId Unique ID of the view to copy nodes to.
   * @param params Parameters specifying details of how to copy the nodes.
   * @param body An object specifiying the request body for the bulk add leaves request.
   */
  protected bulkAddLeaves(
    viewId: number,
    params: TreeBulkAddLeavesParameter,
    body: TreeBulkLeafAdditionIndividualBody | TreeBulkLeafAdditionSelectAllBody
  ): Observable<AnalyseRedirectedResponse> {
    const queryOptions: HttpOptions = {};
    if (params.grouping && params.grouping.length > 0) {
      let parameters = new HttpParams();
      params.grouping.forEach(level => parameters = parameters.append('grouping', level));
      queryOptions.params = parameters;
    }
    // TODO: Confirm response data structure for ungrouped leaves bulk copy.
    return this.http.post<ApiAnalyseRedirectedResponse>(
      this.indexLocationResourceConfig.groupNode.bulkAdd._configuration,
      {
        body,
        pathParams: { viewId, groupNodeId: params.group },
        retryOptions: { customRetryAttempts: 0 },
        httpOptions: queryOptions
      }
    ).pipe(
      map(apiResponse => mapAnalyseRedirectedResponseFromApiToApp(apiResponse))
    );
  }

  /**
   * Bulk copy a group of tree nodes from another tree to the specified tree.
   *
   * @param viewId Unique ID of the view to copy nodes to.
   * @param params Parameters specifying details of how to copy the nodes.
   */
  public bulkCopyGroup(viewId: number, params: TreeBulkCopyGroupParameter): Observable<AnalyseRedirectedResponse> {
    let queryOptions: HttpOptions | undefined;
    if (params.grouped) {
      queryOptions = { params: new HttpParams({ fromObject: { grouped: 'true' } }) };
    }
    return this.http.post<ApiAnalyseRedirectedResponse>(
      this.indexLocationResourceConfig.groupNode.bulkCopy._configuration,
      {
        body: null,
        pathParams: { viewId, groupNodeId: params.group, viewToCopyFrom: params.viewToCopyFrom, groupToCopy: params.groupToCopy },
        retryOptions: { customRetryAttempts: 0 },
        httpOptions: queryOptions
      }
    ).pipe(
      map(apiResponse => mapAnalyseRedirectedResponseFromApiToApp(apiResponse))
    );
  }

  public bulkDelete(viewId: number, body: TreeBulkDeleteRequest): Observable<null> {
    return this.http.deleteWithBody<TreeBulkDeleteRequest>(
      this.indexLocationResourceConfig.bulkDelete._configuration,
      {
        body,
        pathParams: { viewId }
      }
    ).pipe(map(() => null));
  }

  public changeGroupName(viewId: number, data: TreeEditChangeGroupNamePayload): Observable<AnalyseFlatTreeGroupNode> {
    return this.http.patch<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode._configuration,
      {
        body: { name: data.name },
        pathParams: { viewId, groupNodeId: data.nodeId },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeGroupNode)
    );
  }

  public changeGroupWeight(viewId: number, data: TreeEditChangeGroupWeightPayload): Observable<AnalyseFlatTreeGroupNode> {
    return this.http.patch<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode._configuration,
      {
        body: { weight: data.weight },
        pathParams: { viewId, groupNodeId: data.nodeId },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeGroupNode)
    );
  }

  public changeLeafWeight(viewId: number, data: TreeEditChangeLeafWeightPayload): Observable<AnalyseFlatTreeReferenceNode> {
    return this.http.patch<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode.leafNodes.leafNode._configuration,
      {
        body: { weight: data.weight },
        pathParams: { viewId, groupNodeId: data.parentGroupId, leafNodeId: data.nodeId },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeReferenceNode)
    );
  }

  public moveGroup(viewId: number, data: TreeEditMoveGroupPayload): Observable<AnalyseFlatTreeGroupNode> {
    const body: TreeGroupNodeMoveRequestBody = {
      parent: data.newParentGroupId
    };
    if (data.after) {
      body.after = data.after;
    }
    return this.http.patch<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode._configuration,
      {
        body,
        pathParams: { viewId, groupNodeId: data.nodeId },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeGroupNode)
    );
  }

  public moveLeaf(viewId: number, data: TreeEditMoveLeafPayload): Observable<AnalyseFlatTreeReferenceNode> {
    const body: TreeLeafNodeMoveRequestBody = {
      parent: data.newParentGroupId,
      after: data.after
    };
    return this.http.patch<ApiAnalyseFlatTreeResponse>(
      this.indexLocationResourceConfig.groupNode.leafNodes.leafNode._configuration,
      {
        body,
        pathParams: { viewId, groupNodeId: data.currentParentGroupId, leafNodeId: data.nodeId },
        httpOptions: { params: this.flatNodeEditParams }
      }
    ).pipe(
      // HACK: First node is edited node
      map(({ results }) => mapAnalyseFlatTreeNodeFromApiToApp(results[0], results) as AnalyseFlatTreeReferenceNode)
    );
  }

  public removeGroup(viewId: number, data: TreeEditGroupPayload): Observable<null> {
    return this.http.delete(
      this.indexLocationResourceConfig.groupNode._configuration,
      {
        pathParams: { viewId, groupNodeId: data.nodeId }
      }
    )
      .pipe(map(() => null));
  }

  public removeLeaf(viewId: number, data: TreeEditLeafPayload): Observable<null> {
    return this.http.delete(
      this.indexLocationResourceConfig.groupNode.leafNodes.leafNode._configuration,
      {
        pathParams: { viewId, groupNodeId: data.parentGroupId, leafNodeId: data.nodeId }
      }
    ).pipe(map(() => null));
  }

  /**
   * Get one page of the pagintated tree for the specified Country Risk View. The tree is returned
   * in adjacency list form. The returned tree starts with the root node, unless an optional group node id
   * is provided, in which case the subtree is returend.
   *
   * If the query parameters specify any group node ids in the "expanded" array, these will restrict which nodes
   * are to be expanded in the paginated response. Omitting the "expanded" array will return a fully expanded (depth
   * first) tree.
   *
   * @param viewId Unique ID of country risk view or which the tree should be returned
   * @param queryParams Query parameters to use
   * @param groupId Optional parameter for a Group Node ID, if omitted or null, the tree will be returned
   * starting with the tree root.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getFlattenedTree(
    viewId: number,
    queryParams: AnalyseTreeQueryParams,
    groupId?: string | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseFlatTreePage> {
    let resourceConfig: PaginatedResourceConfig;
    let pathParams: PathParams;
    if (groupId) {
      resourceConfig = this.indexLocationResourceConfig.groupNode.flatTree._configuration;
      pathParams = { viewId, groupNodeId: groupId };
    } else {
      resourceConfig = this.indexLocationResourceConfig.rootNodeFlatTree._configuration;
      pathParams = { viewId };
    }

    return this.http.getPaginated<ApiAnalyseFlatTreeResponse>(
      resourceConfig,
      {
        ...options,
        pathParams,
        queryParams
      }
    )
      .pipe(
        map(({ response, queryParams }) => {
          const { total, results, links } = response;
          const flatTreeResponse: AnalyseFlatTreePage = {
            total,
            results:results.map(apiNode => mapAnalyseFlatTreeNodeFromApiToApp(apiNode, results)),
            paginationContext: this.paginationService.getNewPaginationContext(links, queryParams)
          };

          return flatTreeResponse;
        })
      );
  }

  /**
   * Get multiple pages of the pagintated tree for the specified Country Risk View. The tree is returned
   * in adjacency list form. The returned tree starts with the root node, unless an optional group node id
   * is provided, in which case the subtree is returend.
   *
   * If the query parameters specify a start page and an end page, all pages from start to end are loaded concurrently,
   * consolidated and returned as one flat tree response. The flat tree response will contain the ordered, concatenated node
   * array of all pages and the pagintation context of the specified end page.
   *
   * If no end date is specified, the method will load all pages from the start page to the last available page, before
   * consolidating and returning the flat tree response as described above.
   *
   * If the query parameters specify any group node ids in the "expanded" array, these will restrict which nodes
   * are to be expanded in the paginated response. Omitting the "expanded" array will return a fully expanded (depth
   * first) tree.
   *
   * @param id Unique ID of country risk view or which the tree should be returned
   * @param queryParams Query parameters to use
   * @param groupId Optional parameter for a Group Node ID, if omitted or null, the tree will be returned
   * starting with the tree root.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getMultipleFlattenedTreePages(
    id: number,
    queryParams: AnalyseTreeMultiPageQueryParams,
    groupId?: string | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseFlatTreePage> {
    if (queryParams.page_end) {

      const pageObservables: Observable<AnalyseFlatTreePage>[] = [];

      for (let i = queryParams.page_start; i <= queryParams.page_end; i++) {
        const param: AnalyseTreeQueryParams = {
          page: i,
          page_size: queryParams.page_size
        };
        if (queryParams.expand) {
          param.expand = queryParams.expand;
        }
        pageObservables.push(this.getFlattenedTree(id, param, groupId, options));
      }

      return this.forkJoinMultiplePages(pageObservables);

    } else {

      const startPageParam: AnalyseTreeQueryParams = {
        page: queryParams.page_start,
        page_size: queryParams.page_size
      };
      if (queryParams.expand) {
        startPageParam.expand = queryParams.expand;
      }

      return this.getFlattenedTree(id, startPageParam, groupId, options).pipe(
        switchMap(startPage => {
          const page_end = Math.ceil(startPage.total / queryParams.page_size);
          const pageObs: Observable<AnalyseFlatTreePage>[] = [of(startPage)];
          for (let i = queryParams.page_start + 1; i <= page_end; i++) {
            const param: AnalyseTreeQueryParams = {
              page: i,
              page_size: queryParams.page_size
            };
            if (queryParams.expand) {
              param.expand = queryParams.expand;
            }
            pageObs.push(this.getFlattenedTree(id, param, groupId, options));
          }
          return this.forkJoinMultiplePages(pageObs);
        })
      );
    }

  }

  /**
   * Get the complete Indices or Locations Tree for the specified Country Risk View with score data. Score data
   * can be filtered by edition and location. The tree is returned as flat list of nodes.
   *
   * @param parameters mandatory and optional parameters to filter scores and sort results
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getScoredTree(
    parameters: CountryRiskViewsScoredParameters,
    options: HttpGETCustomOptions = {
      forceAPICall: false,
      retryOptions: { customRetryAttempts: 0 }
    }
  ): Observable<AnalyseScorecardFlatTreePage> {
    const queryParams = {
      ...R.omit(['path_parameters', 'score_categories'], parameters),
      category: parameters.score_categories
    };

    const resourceConfig: PaginatedResourceConfig = parameters.path_parameters.group
      ? this.indexLocationTreeResourceConfig.groupNodeScoredTree._configuration
      : this.indexLocationTreeResourceConfig.rootNodeScoredTree._configuration;

    const groupedParameter = parameters.path_parameters.grouped ? 'grouped' : 'ungrouped';
    const pathParams: PathParams = { ...parameters.path_parameters, grouped: groupedParameter };
    return this.http.getPaginated<ApiAnalyseScorecardFlatTreeResponse>(
      resourceConfig,
      {
        ...options,
        pathParams,
        queryParams
      }
    ).pipe(map(responseContext => ({
      total: responseContext.response.total,
      averages: responseContext.response.averages,
      editions: responseContext.response.editions,
      scored: responseContext.response.scored,
      // TODO: P2-3111 Remove 1 fallback, once API is always providing scoring_progress property
      scoring_progress: typeof responseContext.response.scoring_progress === 'number' ? responseContext.response.scoring_progress : 1,
      results: this.mapScoredFlatTreeNodes(responseContext.response.results as ApiAnalyseScorecardFlatTreeNode[]),
      pagination: this.paginationService
        .getNewPaginationContext<CountryRiskViewsScoredParameters>(responseContext.response.links, parameters)
    }))
    );
  }

  protected initApiConfigs(
    indexLocationResourceConfig: CountryRiskConfigurationDetailIndexLocationResourcesConfig,
    indexLocationTreeResourceConfig: CountryRiskConfigurationDetailIndexTreeResourcesConfig
    | CountryRiskConfigurationDetailLocationTreeResourcesConfig
  ): void {
    this.indexLocationResourceConfig = indexLocationResourceConfig;
    this.indexLocationTreeResourceConfig = indexLocationTreeResourceConfig;
  }

  protected getHttpClient(): PortalHttpClient {
    return this.http;
  }

  private forkJoinMultiplePages(pageObservables: Observable<AnalyseFlatTreePage>[]): Observable<AnalyseFlatTreePage> {
    return forkJoin(pageObservables).pipe(
      map(pages => pages.reduce((previous, current) => {
        const accumulated: AnalyseFlatTreePage = {
          total: current.total,
          results: previous.results.concat(current.results),
          paginationContext: current.paginationContext
        };
        return accumulated;
      }))
    );
  }

  private mapScoredFlatTreeNodes(
    apiFlatNodes: ApiAnalyseScorecardFlatTreeNode[]
  ): AnalyseScorecardFlatTreeNode[] {
    const flatNodes: AnalyseScorecardFlatTreeNode[] = [];
    let indicatorParent: { indexParent: string; indexParentGroup: string } | null = null;

    apiFlatNodes.forEach(apiNode => {
      if (!R.isNil(apiNode.parent)) {
        const parentApiNode = apiFlatNodes.find(node => node.id.toString() === apiNode.parent?.toString());

        // Apply is_subnational to child nodes
        if (R.isNil(apiNode.is_subnational) && !R.isNil(parentApiNode?.is_subnational)) {
          apiNode.is_subnational = parentApiNode?.is_subnational;
        }

        // Apply is_subnational to group nodes
        if (apiNode.type === 'index' && parentApiNode?.type === 'group' && !R.isNil(apiNode.is_subnational)) {
          parentApiNode.is_subnational = apiNode.is_subnational;
        }
      }

      // HACK: Need to "initialize" node, even though it gets completely overwritten
      // in the following switch statement. Otherwise, the following "push" statement fails.
      // using "default:" is not working, as the ...apiNode spread fails, as apiNode will be of type "never"
      let node: AnalyseScorecardFlatTreeNode = { ...apiNode, allowsChildNodes: false } as any;
      switch (apiNode.type) {
        case 'group':
          node = { ...apiNode, parent: apiNode.parent !== null ? `${apiNode.parent}` : null, allowsChildNodes: true };
          indicatorParent = null;
          break;
        case 'index':
          node = { ...apiNode, parent: `${apiNode.parent}`, allowsChildNodes: true };
          indicatorParent = { indexParentGroup: node.parent, indexParent: node.id };
          break;
        case 'country':
        case 'site':
          node = { ...apiNode, parent: `${apiNode.parent}`, allowsChildNodes: false };
          break;
        case 'indicator_group':
          node = {
            ...apiNode,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parentReference: indicatorParent!.indexParent,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parentReferenceGroup: indicatorParent!.indexParentGroup,
            allowsChildNodes: true
          };
          break;
        case 'indicator':
          node = {
            ...apiNode,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parentReference: indicatorParent!.indexParent,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parentReferenceGroup: indicatorParent!.indexParentGroup,
            allowsChildNodes: false,
          };
          break;
      }
      flatNodes.push(node);
    });

    return flatNodes;
  }
}
