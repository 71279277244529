import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { BodyContentItem } from '../../../shared-models';
import { WrittenContentLinkActionsUnion } from '../../listeners/written-content-anchor-click-listener';

@Component({
  selector: 'grid-ui-body-item',
  templateUrl: './body-item.component.html',
  styleUrls: ['./body-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemComponent {

  @Input() public bodyItem: BodyContentItem | null = null;
  @Input() public previousBodyItem: BodyContentItem | null = null;
  @Input() public enableNestedBodyItem = false;
  @Output() public writtenContentLinkActionEmit = new EventEmitter<WrittenContentLinkActionsUnion>();
  @Output() public imageLoaded = new EventEmitter<void>();

  public emitWrittenContentLinkAction(action: WrittenContentLinkActionsUnion): void {
    this.writtenContentLinkActionEmit.emit(action);
  }

  public handleImageLoaded(): void {
    this.imageLoaded.emit();
  }

  public isConsecutiveH3() {
    return this.previousBodyItem && this.previousBodyItem.type === 'heading';
  }
}
