import { createAction, props } from '@ngrx/store';

export enum GlobalRoutingActions {
    RouteChange = '[Global Router] Route Change',
    RouteChangeSuccess = '[Global Router] Route Change Success',
    RouteChangeFailure = '[Global Router] Route Change Failure',
}

export const routeChange = createAction(
    GlobalRoutingActions.RouteChange,
    props<{ route: string }>()
);

export const routeChangeSuccess = createAction(GlobalRoutingActions.RouteChangeSuccess, props<{ route: string }>());

export const routeChangeFailure = createAction(
    GlobalRoutingActions.RouteChangeFailure,
    props<{ error: any }>()
);

export const GLOBAL_ROUTING_ACTION_CREATORS = {
    routeChange,
    routeChangeSuccess,
    routeChangeFailure
};