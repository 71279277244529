import {
  ApiResponseWithQueryContext,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  PageQueryParameter,
  PortalHttpClient,
  QueryParams,
  HttpGETCustomOptions,
  PaginatedV4Response,
} from '@grid-ui/common';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of, forkJoin, EMPTY } from 'rxjs';
import { map, switchMap, catchError, take, expand, reduce, tap } from 'rxjs/operators';

import { SetFilterValuesFuncParams } from '@ag-grid-community/core';

import * as R from 'ramda';

import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PaginatedResourceConfig } from '@grid-ui/common';
import { AppConfigService } from '../../../app-config';

import {
  LocationAttributesCollection,
  LocationAttribute,
  LocationAttributeDetail,
  LocationAttributesWithChoicesCollection,
  LocationAttributeWithChoices,
  LocationAttributesWithAsyncChoicesCollection,
  LocationAttributeWithCount,
  LocationAttributeCollectionV4,
} from '../../../shared-models';
import { ConcreteAttributeKeys, ConcreteAttributeSourceMapV4, V4_CONCRETE_ATTRIBUTES } from '../../../api-services/locations';
import { generateSyntheticBackendColumnKey } from '../../../shared-utilities/generate-synthetic-backend-column-key';
import { LocationAttributeMappingService } from '../../services';
import {
  ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES,
  LOCATION_ATTRIBUTE_KEY_MAPPING,
  OTHER_RESERVED_LOCATION_PSEUDO_ATTRIBUTE_KEYS,
  LIST_FILTERABLE_GLOBAL_SITE_ATTRIBUTES,
  ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES_WITH_CHOICES,
  RESERVED_LOCATION_ATTRIBUTE_KEY_MAPPING,
} from '../../constants';

import { mapRegionChoices } from '../../utils';

import { DataWizardUploadCreateAttributeRequest, DataWizardUploadCreateAttributeResponse } from '../../data-wizard-upload/models';

import {
  ApiLocationAttributesCollection,
  LocationAttributesMappingOptions,
  ApiLocationAttributeDetail
} from '../models';

import { LoggingService } from '../../../core/services/logging';
import { capitalizeFirstLetter } from '../../../shared-utilities/strings';

/**
 * A simple class to pass into a closure which
 * manages a cache, but needs to be reset from
 * outside the closure
 */
export class CacheResetIndicator {
  /**
   * @param cacheReset A flag indicating whether the cache needs to be reset
   */
  constructor(public cacheReset: boolean = false) {}
}

/**
 * A factory function returning a filter setter function for use with
 * async ag Grid set filters.
 *
 * The returned filter setter function accepts an ag Grid params object
 * passed in by ag Grid when asynchronously requesting current set filter values.
 * When invoked the `params` argument corresponds to a column in the ag Grid with
 * a set filter. The `colId` property accessed through the `params` argument is
 * expected to reflect the attribute key of a Location Attribute. If filter choices
 * are validly cached or loaded from the backend, they are passed to the `params.success(...)`
 * method in order to appear in the ag Grid filter.
 *
 * IMPORTANT: The ag Grid set filter does not support multi-page loading of set filter choices.
 * Therefore we are only loading the first page of choice with the specified `pageSize`. This
 * implies that the set filter should only be applied to columns which have no more than `pageSize`
 * number of choices.
 *
 * The returned function internally caches asynchronously loaded attribute choices
 * in a map by attribute key. This cached map will be used on repeat invocations for
 * a previously loaded attribute. However, as site adds/delets/edits may invalidate
 * the cached choices that cache can be reset.
 *
 * In order to reset the cache from outside the closure function, the `cacheResetIndicator` argument
 * to this factory function can be used.
 *
 * @param cacheResetIndicator An object with a flag indicating whether the cache should be reset.
 * If there is a need to reset the cached attribute choices to force a reload, set the flag to `true`.
 * On the next invocation of the async filter choices getter by ag Grid, the cache will be reset the flag will
 * be set to `false` again. Choices will be refreshed from the backend for any column the async getter gets invoked. The cache
 * will be updated attribute by attribute.
 * @param locationAttributesService An injected instance of the `LocationAttributesService` used to load the location attribute details
 * @param loggingService An injected instance of `LoggingService` to log any errors encountered when loading location attributes details
 * @param keysMapped A flag indicating whether location attribute keys have been mapped, so that certain ag Grid column `colId` values
 * need to be "unmapped" before using the attribute key in an API call for location attribute details.
 * @param pageSize The page size of choices results to request from the location attribute details API endpoint.
 */
export function createAsyncChoiceGetter(
  cacheResetIndicator: CacheResetIndicator,
  locationAttributesService: LocationAttributesService,
  locationAttributeMappingService: LocationAttributeMappingService,
  loggingService: LoggingService,
  keysMapped: boolean,
  pageSize: number,
  attrSrcMapV4?: ConcreteAttributeSourceMapV4,
): (params: SetFilterValuesFuncParams) => void {
  const unmappedRegionKey = 'region';
  const asyncChoiceMap = new Map<string, string[] | null>();
  const v4 = !!attrSrcMapV4;

  return function (params: SetFilterValuesFuncParams): void {
    if (cacheResetIndicator.cacheReset) {
      asyncChoiceMap.clear();
      cacheResetIndicator.cacheReset = false;
    }
    let attrKey = params.colDef.colId;
    if (keysMapped) {
      const existingAttr = LOCATION_ATTRIBUTE_KEY_MAPPING.find(attr => attrKey === attr.mappedKey);
      if (existingAttr) {
        attrKey = existingAttr.unmappedKey;
      }
    }
    if (attrKey === undefined) {
      // HACK: there is no failure callback on the ag Grid set filter params
      params.success([]);
      throw (new Error('ColId of ag Grid Location Attribute-based not defined.'));
    } else {
      if (!R.isNil(asyncChoiceMap.get(attrKey))) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        params.success(asyncChoiceMap.get(attrKey)!);
      } else {
        const options = { page: 1, page_size: pageSize };
        const handleSuccess = (choices: string[]) => {
          const shouldCapitalize = attrKey === 'entity_collection';
          choices = shouldCapitalize ? choices.map(capitalizeFirstLetter) : choices;
          params.success(choices);
          asyncChoiceMap.set(attrKey || '', choices);
        };
        const handleErrs = (err: HttpErrorResponse) => {
          // HACK: there is no failure callback on the ag Grid set filter params
          params.success([]);
          loggingService.logUnexpected(err);
          console.error(err);
        };

        if (v4) {
          if (ConcreteAttributeSourceMapV4.isMappedAttribute(attrKey)) {
            const key = attrKey as ConcreteAttributeKeys;
            attrSrcMapV4?.getSource(key)
              .pipe(take(1))
              .subscribe(
                r => handleSuccess(r.results.map(c => c[key] as string)),
                handleErrs,
              );
          } else {
            locationAttributesService.getAllLocationAttributeDetailV4(attrKey, options)
              .pipe(take(1))
              .subscribe(
                r => handleSuccess(r.response.results),
                handleErrs,
              );
          }
        } else {
          locationAttributesService.getLocationAttributeDetail(attrKey, options)
            .pipe(take(1))
            .subscribe(
              details => {
                let choices = details.response.results;
                if (attrKey === unmappedRegionKey) {
                  choices = mapRegionChoices(choices);
                }

                const capitalize = attrKey === 'entity_collection';
                const labels = choices.map(r => capitalize ? capitalizeFirstLetter(r.label) : r.label);

                params.success(labels);
                asyncChoiceMap.set(attrKey || '', labels);
              },
              handleErrs,
            );
        }
      }
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class LocationAttributesService {

  /**
   * A list of reserved key strings, which cannot be used for Custom Attribute
   * keys.
   *
   * _Important:_ The list is _in addition_ to the keys contained in the Location
   * Attributes response. It includes key which are part of mappings, or would result
   * in Custom Attribute labels, which would duplicate mapped labels.
   *
   * These conflicts, would not be identified by Backend Validation!!!
   */
  public readonly otherReservedAttributeKeys: string[];

  private attributesResourceConfig: NonPaginatedResourceConfig;
  private attributeDetailResourceConfig: PaginatedResourceConfig;
  private attributeMappingsResourceConfig: NonPaginatedResourceConfig;
  private attributeMappingDetailResourceConfig: NonPaginatedResourceConfig;
  private attributeDetailResourceConfigV4: PaginatedResourceConfig;
  private attributesResourceConfigV4: PaginatedResourceConfig;

  private attributeDetailsCache: Map<string, LocationAttributeDetail> = new Map<string, LocationAttributeDetail>();
  private attributeDetailsCacheV4: Map<string, string[]> = new Map<string, string[]>();

  constructor(
    private readonly locationAttributeMappingService: LocationAttributeMappingService,
    private readonly http: PortalHttpClient,
    private readonly loggingService: LoggingService,
    private readonly appConfigService: AppConfigService
  ) {
    this.attributeDetailResourceConfig = API_SERVICES_CONFIG.v3.locations.attributes.attribute._configuration;
    this.attributeDetailResourceConfigV4 = API_SERVICES_CONFIG.v4.location.attribute.detail._configuration;
    this.attributesResourceConfig = API_SERVICES_CONFIG.v3.locations.attributes._configuration;
    this.attributesResourceConfigV4 = API_SERVICES_CONFIG.v4.location.attribute._configuration;
    this.attributeMappingsResourceConfig = API_SERVICES_CONFIG.v3.locations.attributesMapping._configuration;
    this.attributeMappingDetailResourceConfig = API_SERVICES_CONFIG.v3.locations.attributesMapping.attributeMapping._configuration;
    this.otherReservedAttributeKeys = this.getOtherReservedAttributeKeys();
  }

  public getAttributeDetailsFromCache(key: string) {
    return this.attributeDetailsCache.get(key);
  }

  public setAttributeDetailsCacheItem(key: string, value: LocationAttributeDetail) {
    this.attributeDetailsCache.set(key, value);
  }

  public clearAttributeDetailsCache() {
    this.attributeDetailsCache.clear();
  }

  public getAttributeDetailsFromCacheV4(key: string) {
    return this.attributeDetailsCacheV4.get(key);
  }

  public setAttributeDetailsCacheItemV4(key: string, values: string[]) {
    let allValues = values;

    if (this.attributeDetailsCacheV4.has(key)) {
      const isUniqueValue = (v: string, i: number, a: string[]) => a.indexOf(v) === i;

      allValues = [
        ...(this.attributeDetailsCacheV4.get(key) || []),
        ...values,
      ].filter(isUniqueValue);
    }

    this.attributeDetailsCacheV4.set(key, allValues);
  }

  public clearAttributeDetailsCacheV4() {
    this.attributeDetailsCacheV4.clear();
  }

  public createAttribute(
    newAttribute: DataWizardUploadCreateAttributeRequest
  ): Observable<DataWizardUploadCreateAttributeResponse> {
    return this.http.post<DataWizardUploadCreateAttributeResponse>(
      this.attributeMappingsResourceConfig,
      { body: newAttribute }
    );
  }

  public deleteAttribute(
    key: string
  ): Observable<void> {
    return this.http.delete(
      this.attributeMappingDetailResourceConfig,
      {
        pathParams: { id: key }
      }
    );
  }

  public renameAttribute(
    key: string,
    name: string,
  ): Observable<void> {
    return this.http.patch(
      this.attributeMappingDetailResourceConfig,
      {
        body: { name },
        pathParams: { id: key }
      }
    );
  }

  /**
   * Get the location attribute detail including choices for the
   * specified attribute key.
   *
   * @param key Attribute key
   * @param paginationParameters Pagination related parameters
   * @param options Http request options
   */
  public getLocationAttributeDetail(
    key: string,
    paginationParameters: PageQueryParameter | null = null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<ApiResponseWithQueryContext<LocationAttributeDetail>> {
    const queryParams: QueryParams = paginationParameters || {};
    return this.http.getPaginated<ApiLocationAttributeDetail>(
      this.attributeDetailResourceConfig,
      {
        ...options,
        pathParams: { id: key },
        queryParams
      }
    ).pipe(
      tap(apiResponse => this.setAttributeDetailsCacheItem(key, apiResponse.response))
    );
  }

  public getLocationAttributeDetailV4(
    key: string,
    paginationParameters: PageQueryParameter | null = null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<ApiResponseWithQueryContext<PaginatedV4Response<string>>> {
    const queryParams: QueryParams = paginationParameters || {};
    return this.http.getPaginated<PaginatedV4Response<string>>(
      this.attributeDetailResourceConfigV4,
      {
        ...options,
        pathParams: { id: key },
        queryParams
      }
    ).pipe(
      tap(apiResponse => this.setAttributeDetailsCacheItemV4(key, apiResponse.response.results)),
    );
  }

  public getAllLocationAttributeDetailV4(
    key: string,
    pageParams: PageQueryParameter | null = null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<ApiResponseWithQueryContext<PaginatedV4Response<string>>> {
    let page = 1;

    return this.getLocationAttributeDetailV4(key, { ...pageParams, page }, options).pipe(
      expand(data => data.response?.links?.next
        ? this.getLocationAttributeDetailV4(key, { ...pageParams, page: ++page }, options)
        : EMPTY
      ),
      reduce((acc, data) => ({
        ...data, response: {
          ...data.response,
          results: acc.response.results.concat(data.response.results)
        }
      })),
    );
  }

  /**
   * A method which return a collection of Location Attributes with an
   * getter function to asynchronously load and cache location attribute
   * choices for ag Grid columns with set filters.
   *
   * Each location attribute in the collection has a `count` property which indicates
   * the current number of value choices for the location attribute. This `count` can
   * be used to determine the appropriate filter type for the corresponding ag Grid column
   *
   * @param mappingOptions An object containing flags determining which mappings/enhancements to apply
   * to the Location Attributes
   * * @param cacheResetIndicator An object with a flag indicating whether the cache should be reset.
   * If there is a need to reset the cached attribute choices to force a reload, set the flag to `true`.
   * On the next invocation of the async filter choices getter by ag Grid, the cache will be reset the flag will
   * be set to `false` again. Choices will be refreshed from the backend for any column the async getter gets invoked. The cache
   * will be updated attribute by attribute.
   * @param choicesPageSize The page size of choices results to request from the location attribute details API endpoint.
   * @param show_empty Optional, default is true. Sets value of show_empty querystring param
   * @param options Optional. Custom options for the underlying Http GET request
   */
  public getLocationAttributesWithAsyncChoices(
    mappingOptions: LocationAttributesMappingOptions,
    cacheResetIndicator: CacheResetIndicator,
    choicesPageSize: number,
    show_empty: boolean = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<LocationAttributesWithAsyncChoicesCollection> {

    return this.http.get<ApiLocationAttributesCollection>(
      this.attributesResourceConfig,
      {
        ...options,
        queryParams: { show_empty, page_size: 200 }
      }
    ).pipe(
      map(attribListResponse => {
        const collection: LocationAttributesWithAsyncChoicesCollection = {
          total: attribListResponse.total,
          results: attribListResponse.results.map(attr => {

            const attrib: LocationAttributeWithCount = {
              ...attr,
              choicesCount: 0
            };

            return attrib;
          }),
          asyncGetter: createAsyncChoiceGetter(
            cacheResetIndicator,
            this,
            this.locationAttributeMappingService,
            this.loggingService,
            mappingOptions.mapKeys,
            Math.min(choicesPageSize, this.appConfigService.getConfig().locationTables.filterMaxOptions)
          )
        };
        return collection;
      }),
      map(response => {

        const results: LocationAttributeWithCount[] = response.results.map(attr => {
          attr = mappingOptions.mapKeys
            ? this.locationAttributeMappingService.mapAttributeKeyToLocationKey(attr)
            : attr;
          attr = mappingOptions.mapLabels
            ? this.locationAttributeMappingService.mapAttributeLabel(attr)
            : attr;
          return attr;
        });
        return {
          ...response,
          results: [
            ...results,
            ...(mappingOptions.appendPseudoAttributes ? ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES_WITH_CHOICES : [])
          ]
        };
      })
    );
  }

  /**
   * TODO: Remove/Replace this method. We need to change the logic in the application to prevent the need for us to make lots of API calls
   * to build up a complete attribute list, previously the API gave us all the attributes and their values in a single call. see GRID-577
   * Changes to better use new API structure to done as part of GRID-243 & GRID-581
   * ------
   * Get all attributes the user has access to and then query the API for details of each attribute and populate the choices
   * Warnings:
   * 1. will potentially trigger a lot of API calls so only use if attribute choices or total are required
   * 2. uses a whitelist to limit the number of "global" (i.e. system/default) attributes that we get the choices/total for
   * doing this as there a bunch of global attributes we don't generally use
   */
  public getLocationAttributesWithChoices(
    mappingOptions: LocationAttributesMappingOptions,
    show_empty: boolean = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<LocationAttributesWithChoicesCollection> {
    return this.http.get<ApiLocationAttributesCollection>(
      this.attributesResourceConfig,
      {
        ...options,
        queryParams: { show_empty }
      }
    ).pipe(
      switchMap(attribListResponse => {
        const detailsObs: Record<string, Observable<LocationAttributeDetail | null>> = {};
        attribListResponse.results.forEach(attrib => {
          // TODO: only hit API for non-global and whitelisted global attributes
          detailsObs[attrib.key] = attrib.global && !LIST_FILTERABLE_GLOBAL_SITE_ATTRIBUTES.includes(attrib.key)
            ? of(null)
            : this.getLocationAttributeDetail(attrib.key).pipe(
              map(detail => detail.response),
              catchError(error => {
                // we need to swallow the error to not break downstream functionality
                this.loggingService.logUnexpected(error);
                console.error(error);
                return of(null);
              })
            );
        });
        return forkJoin<Record<string, Observable<LocationAttributeDetail | null>>, string>(detailsObs).pipe(
          map(details => {
            const collection: LocationAttributesWithChoicesCollection = {
              total: attribListResponse.total,
              results: attribListResponse.results.map(attr => {
                const attrib: LocationAttributeWithChoices = {
                  ...attr,
                  choicesCount: 0,
                  choices: []
                };
                const detail = details[attr.key];
                if (detail) {
                  attrib.choices = detail.results;
                  attrib.choicesCount = detail.total;
                }
                return attrib;
              })
            };
            return collection;
          })
        );
      }),
      map(response => {
        const regionKey = mappingOptions.mapKeys ? 'subregion' : 'region';

        const results: LocationAttributeWithChoices[] = response.results.map(attr => {
          attr = mappingOptions.mapKeys
            ? this.locationAttributeMappingService.mapAttributeKeyToLocationKey(attr)
            : attr;

          attr = mappingOptions.mapLabels
            ? this.locationAttributeMappingService.mapAttributeLabel(attr)
            : attr;

          if (attr.key === regionKey && attr.choices && attr.choices.length) {
            attr.choices = [...mapRegionChoices(attr.choices)];
          }

          attr = {
            ...attr,
            choices: attr.choices,
          };

          return attr;
        });

        return {
          ...response,
          results: [
            ...results,
            ...(mappingOptions.appendPseudoAttributes ? ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES_WITH_CHOICES : [])
          ]
        };
      })
    );
  }
  /**
   * This API can be used to return a description of the available location attributes that are active in the Portal.
   * These attributes are account specific, as derived from the permissions set on the calling user’s credentials.
   *
   * @param mappingOptions An object containing flags determining which mappings/enhancements to apply
   * to the Location Attributes
   * @param show_empty Optional, default is true. Sets value of show_empty querystring param
   * @param options Optional. Custom options for the underlying Http GET request
   * @param page Optional. Specific page to return attributes for
   */
  public getLocationAttributes(
    mappingOptions: LocationAttributesMappingOptions,
    show_empty: boolean = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    page?: number,
    pageSize?: number,
  ): Observable<LocationAttributesCollection> {
    pageSize = pageSize ? pageSize : 200;
    const queryParams = page === undefined ? { show_empty, page_size: pageSize } : { show_empty, page, page_size: pageSize };
    return this.http.get<ApiLocationAttributesCollection>(
      this.attributesResourceConfig,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map(response => {

        let results: LocationAttribute[];

        results = mappingOptions.mapKeys
          ? this.locationAttributeMappingService.mapAttributeKeysToLocationKeys(response.results)
          : response.results;
        results = mappingOptions.mapLabels
          ? this.locationAttributeMappingService.mapAttributeLabels(results)
          : results;

        return {
          ...response,
          results: [
            ...results,
            ...(mappingOptions.appendPseudoAttributes ? ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES : [])
          ]
        };
      })
    );
  }

  public getLocationAttributesV4(
    mapKeys: boolean,
    includeConcrete = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    page?: number,
    pageSize?: number,
  ): Observable<LocationAttributeCollectionV4> {
    pageSize = pageSize ? pageSize : 300;
    const queryParams = page === undefined ? { page_size: pageSize } : { page, page_size: pageSize };
    return this.http.get<LocationAttributeCollectionV4>(
      this.attributesResourceConfigV4,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map(resp => ({
        ...resp,
        results: [
          ...(mapKeys
            ? this.locationAttributeMappingService.mapAttributeKeysToLocationKeys(resp.results)
            : resp.results
          ),
          ...(includeConcrete ? V4_CONCRETE_ATTRIBUTES : [])
        ]
      })),
    );
  }

  /**
   * Wrapper for `getLocationAttributes` to return all paginated location attributes
   * @param mappingOptions mappings/enhancements flags to apply to the Location Attributes
   * @param show_empty Sets value of show_empty querystring param
   * @param options Custom options for the underlying Http GET request
   */
  public getAllLocationAttributes(
    mappingOptions: LocationAttributesMappingOptions,
    show_empty: boolean = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<LocationAttributesCollection> {
    const endpointArgs = [mappingOptions, show_empty, options] as const;
    let page = 1;

    return this.getLocationAttributes(...endpointArgs, page).pipe(
      expand(data => data?.links?.next
        ? this.getLocationAttributes(...endpointArgs, ++page)
        : EMPTY
      ),
      reduce((acc, data) =>
        ({ ...data, results: acc.results.concat(data.results) })
      ),
    );
  }

  public getAllLocationAttributesV4(
    mapKeys: boolean,
    includeConcrete = true,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<LocationAttributeCollectionV4> {
    const endpointArgs = [ mapKeys, includeConcrete, options ] as const;
    let page = 1;

    return this.getLocationAttributesV4(...endpointArgs, page).pipe(
      expand(data => data?.links?.next
        ? this.getLocationAttributesV4(...endpointArgs, ++page)
        : EMPTY
      ),
      reduce((acc, data) =>
        ({ ...data, results: acc.results.concat(data.results) })
      ),
    );
  }

  /**
  * Check whether the provided new attribute name (column name) would
  * be converted to an attribute key which matches one of the other reserved keys,
  * which are not actual Location Attribute API response keys.
  *
  * @param newAttributeName A location attribute name (column name) considered
  * for a customer location attribute
  */
  public isOtherReservedAttributeKey(
    newAttributeName: string
  ): boolean {
    const newAttributeKey = generateSyntheticBackendColumnKey(newAttributeName);
    return this.otherReservedAttributeKeys.some(key => key === newAttributeKey);
  }

  isConcreteAttributeV4(key: string): boolean {
    return !!V4_CONCRETE_ATTRIBUTES.find(a => a.key === key);
  }

  /**
   * Create a list of reserved key strings, which cannot be used for Custom Attribute
   * keys.
   *
   * Note: The returned list is _in addition_ to the keys contained in the Location
   * Attributes response. It includes key which are part of mappings, or would result
   * in Custom Attribute labels, which would duplicate mapped labels.
   */
  private getOtherReservedAttributeKeys(): string[] {
    const uniqueReservedKeys = new Set<string>();
    RESERVED_LOCATION_ATTRIBUTE_KEY_MAPPING.forEach(attr => uniqueReservedKeys.add(attr.mappedKey));
    ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES.forEach(attr => {
      uniqueReservedKeys.add(attr.key);
      uniqueReservedKeys.add(generateSyntheticBackendColumnKey(attr.label));
    });
    OTHER_RESERVED_LOCATION_PSEUDO_ATTRIBUTE_KEYS.forEach(key => uniqueReservedKeys.add(key));
    return [...uniqueReservedKeys];
  }

}
