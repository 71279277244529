<div class="content row d-flex align-content-center">
  <div class="col-6 col-md-4 offset-md-4 text-center">
    <ng-container *ngIf="complete$ | async ; else incomplete">
      <div class="message-container">
        <grid-ui-icon class="small-check" name="tick-circle"></grid-ui-icon>
        Your extract is ready to download. <a class="action-text download-link" (click)="downloadExtract()">Download now</a>
      </div>
    </ng-container>
    <ng-template #incomplete>
      <div class="message-container">
        <grid-ui-loading
          class="loading-icon"
          [message]="''"
          [color]="'secondary'"
        >
        </grid-ui-loading>
        <span>Your extract is being generated. <a class="action-text" (click)="cancel()">Cancel extract</a></span> 
      </div>
    </ng-template>
  </div>
  <div class="col-6 col-md-4 text-right pr-5">
    <a class="action-text" (click)="closeNotification()">Dismiss this notice (will not affect download)</a>
  </div>
</div>
