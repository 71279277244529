import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { Spec } from 'vega';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * The full screen image 'thin' component to be used in the BodyItemImageComponent.
 * this component does not have an input property because it will be loaded
 * externally from ImageComponent
 *
 * @export
 * @class BodyItemExpandedImageComponent
 * @implements OnInit
 */
@Component({
  selector: 'grid-ui-body-item-expanded-scorecard',
  templateUrl: './body-item-expanded-scorecard.component.html',
  styleUrls: ['./body-item-expanded-scorecard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemExpandedScorecardComponent {

  @Input() public title = '';
  @Input() public subtitle = '';
  @Input() public attribution = '';
  @Input() public caption = '';
  @Input() public chartData: Spec | null = null;
  @Input() public chartRef: ElementRef<HTMLElement> | null = null;

  @Output() public dismiss = new EventEmitter();

  @ViewChild('chart', { read: ElementRef }) public chart: ElementRef<HTMLElement> | null = null;
  @ViewChild('modalHeaderRef', { read: ElementRef }) public modalHeaderElementRef: ElementRef<HTMLDivElement> | null = null;

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activeModal: NgbActiveModal
  ) {
  }

  /**
   * Listen to the click event of the mouse and close modal if clicked on backdrop
   * @param  {MouseEvent} $event
   * @return {void}@memberof ExpandedScorecardComponent
   */
  @HostListener('click', ['$event'])
  public onMouseClick($event: MouseEvent): void {
    if ($event.target) {
      if (($event.target as Element).classList.contains('modal')) {
        this.close();
      }
    }
  }

  /**
   * Listen to the Esc key and hide the modal if Esc key is pressed
   * @param  {KeyboardEvent} $event
   * @return {void}@memberof ExpandedImageComponent
   */
  @HostListener('document:keyup', ['$event'])
  public onEscapeKey($event: KeyboardEvent): void {
    if ($event.key === 'Escape' || $event.key === 'Esc') {
      this.close();
    }
  }

  /**
   * Dismiss the full screen image modal
   * @return {void}@memberof ExpandedImageComponent
   */
  public close(): void {
    this.dismiss.emit();
    this.activeModal.close();
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public setSubTitle(subtitle: string): void {
    this.subtitle = subtitle;
  }

  public setCaption(caption: string | null): void {
    this.caption = caption ? caption : '';
  }
}
