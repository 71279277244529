import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {  IconModule } from '../icon';
import { ErrorModule } from '../error';
import { SubscriptionDetailsNorowsOverlayComponent } from './';

@NgModule({
  declarations: [
    SubscriptionDetailsNorowsOverlayComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    ErrorModule,
  ],
  exports: [
    SubscriptionDetailsNorowsOverlayComponent,
  ]
})
export class SubscriptionDetailsNorowsOverlayModule { }
