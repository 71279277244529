import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  NgbModalModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';

import { FeatureToggleModule, IconModule } from '@grid-ui/common';
import { ApiServicesModule } from '../api-services';
import { WindowProviderModule } from '../shared-utilities/window-services';

import { LoadingComponent, LoadingModule } from '../shared-ui-components/loading';
import { StickyTopElementModule } from '../shared-ui-components/sticky-top-element';
import { UnexpectedErrorStatusModule } from '../shared-ui-components/unexpected-error-status';

import { PreviewModeWatermarkComponent } from '../preview-mode-watermark/preview-mode-watermark.component';
import { SearchableDropdownModule } from '../shared-ui-components/searchable-dropdown';
import { GridSharedPipesModule } from '../shared-ui-components/shared-pipes/shared-pipes.module';
import {
  AppSideAreaComponent,
  AppSideAreaLinksComponent
} from './app-side-area';
import {
  AccountSettingsModalComponent,
  AccountSettingsModalService,
  AppTopAreaComponent,
  TopRightHelpMenuComponent,
  TopRouteBreadcrumbsComponent,
  TopUserMenuComponent
} from './app-top-area';
import { PermissionDirective } from './permission/directives/permission.directive';
import { CanLoadPermissionGuard, PermissionGuard } from './permission/permission-guard';
import { SitePermissionGuard } from './permission/site-permission-guard';
import { RoutedErrorComponent } from './routed-error';
import {
  NavService,
  PermissionsPollingService,
  PermissionsService,
  REFRESH_PERMISSIONS,
  SvgService,
  TopBreadcrumbTrailService
} from './services';
import { WhoAmIEffects } from './store';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbTooltipModule,
    ApiServicesModule.forRoot(),
    LoadingModule,
    SearchableDropdownModule,
    GridSharedPipesModule,
    WindowProviderModule.forRoot(),
    StickyTopElementModule,
    UnexpectedErrorStatusModule,
    FeatureToggleModule,
    IconModule
  ],
  declarations: [
    AccountSettingsModalComponent,
    AppSideAreaComponent,
    AppTopAreaComponent,
    TopRouteBreadcrumbsComponent,
    AppSideAreaLinksComponent,
    TopUserMenuComponent,
    RoutedErrorComponent,
    TopRightHelpMenuComponent,
    PreviewModeWatermarkComponent,
    PermissionDirective
  ],
  exports: [
    AppSideAreaComponent,
    AppTopAreaComponent,
    RoutedErrorComponent,
    LoadingComponent,
    PreviewModeWatermarkComponent,
  ],
  providers: [
    AccountSettingsModalService,
    CanLoadPermissionGuard,
    SitePermissionGuard,
    TopBreadcrumbTrailService,
    NavService,
    SvgService,
    WhoAmIEffects,
    { provide: REFRESH_PERMISSIONS, useValue: 5 * 60 * 1000 },
    PermissionsService,
    PermissionsPollingService,
    PermissionGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
