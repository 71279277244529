import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mc-ie-warning',
  templateUrl: './ie-warning.component.html',
  styleUrls: ['./ie-warning.component.scss']
})
export class IEWarningComponent {

  @Output() warningDismissed = new EventEmitter<boolean>();

  constructor() { }

  public dismiss(): void {
    this.warningDismissed.emit(true);
  }
}
