import { CommodityDropdownItem, CountryDropdownItem, CountryRiskIndexDropdownItem, CountryRiskViewDropdownItem, GeoLocationDropdownItem } from '../../../shared-models';

export interface QuickSearchDropdownItemType {
  name: string;
  isHeader: boolean;
  isDisabled: boolean;
};

export function isQuickSearchDropdownItemLocation(item: QuickSearchDropdownItemType): item is CountryDropdownItem | GeoLocationDropdownItem {
  return (item as CountryDropdownItem | GeoLocationDropdownItem).geo_id !== undefined;
}

export function isQuickSearchDropdownIndex(item: QuickSearchDropdownItemType): item is CountryRiskIndexDropdownItem | CommodityDropdownItem {
  return (item as CountryRiskIndexDropdownItem | CommodityDropdownItem).id !== undefined && typeof (item as CountryRiskIndexDropdownItem | CommodityDropdownItem).id === 'string';
}

export function isQuickSearchDropdownRecentView(item: QuickSearchDropdownItemType): item is CountryRiskViewDropdownItem {
  return (item as CountryRiskViewDropdownItem).id !== undefined && typeof (item as CountryRiskViewDropdownItem).id === 'number';
}
