import { SortCriterion } from '../parameterization';
import { DatePickerFilterSelection } from '../../shared-ui-components/date-picker-filter/models';
import { DateRange } from '../date-range.interface';
import { CountryRiskIndex } from '../country-risk-index';
import { ItemInformationListingItemDatatype } from './item-information-listing-item-datatype.type';
import { ItemInformationListingActionItemPayload } from './item-information-listing-action-item-payload.interface';

export enum ItemInformationListingActionTypes {
  ANALYSE = '[Item Information Listing] Analyse Item',
  CHANGE_SORT_CRITERION = '[Item Information Listing] Change Sort Criterion',
  UPDATE_DATE_FILTER = '[Item Information Listing] Update Date Filter',
  CLICK = '[Item Information Listing] Item Clicked',
  DELETE = '[Item Information Listing] Delete Item',
  DOWNLOAD = '[Item Information Listing] Download Item',
  EDIT = '[Item Information Listing] Edit Item',
  INDEX_CLICK = '[Item Information Listing] Risk Index in Information Dropdown Clicked',
  TOGGLE_INDEX_INFO_DETAIL = '[Item Information Listing] Toggle Risk Index Information Detail',
  SHOW_INDEX_DETAILS = '[Item Information Listing] Show Index Details',
  READ = '[Item Information Listing] Read Item',
  SHARE = '[Item Information Listing] Toggle Item Sharing Status',
  UNSHARE = '[Item Information Listing] Unshare item',
  SUBSCRIBE = '[Item Information Listing] Subscribe to Item',
  VIEW_SCORE_UPDATE_CHANGE = '[Item Information Listing] View Score Update Change'
}

/**
 * Action triggered by clicking the "Analyse" action in the listing's Actions column.
 */
export class ItemInformationListingAnalyseAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.ANALYSE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "sort by" arrows for a supporting sortable column header.
 */
export class ItemInformationListingChangeSortCriterionAction {
  public readonly type = ItemInformationListingActionTypes.CHANGE_SORT_CRITERION;
  constructor(public payload: SortCriterion<string>) { }
}

/**
 * Action triggered by clicking the "date filter" icon for a supporting date filterable column header.
 */
export class ItemInformationListingUpdateDateFilterAction {
  public readonly type = ItemInformationListingActionTypes.UPDATE_DATE_FILTER;
  constructor(public payload: DateRange) { }
}

/**
 * Action triggered by clicking a supporting cell (e.g. name) to "choose" the
 * item for an action to be determined by event handler.
 */
export class ItemInformationListingClickAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.CLICK;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Delete" icon in the listing's Actions column.
 */
export class ItemInformationListingDeleteAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.DELETE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Download" icon in the listing's Actions column.
 */
export class ItemInformationListingDownloadAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.DOWNLOAD;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Edit" icon in the listing's Actions column.
 */
export class ItemInformationListingEditAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.EDIT;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking on a Risk Index listed in the composition
 * changes section of a listing items information dropdown row
 */
export class ItemInformationListingIndexClickAction {
  public readonly type = ItemInformationListingActionTypes.INDEX_CLICK;
  constructor(public payload: { indexId: string }) { }
}

/**
 * Action triggered by clicking a listing items "Show Information icon".
 */
export class ItemInformationListingToggleIndexInformationDetailAction<
  T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype
> {
  public readonly type = ItemInformationListingActionTypes.TOGGLE_INDEX_INFO_DETAIL;
  constructor(public payload: { itemPayload: ItemInformationListingActionItemPayload<T> }) { }
}

/**
 * Action triggered by clicking a listing items show details icon.
 */
export class ItemInformationListingShowDetails {
  public readonly type = ItemInformationListingActionTypes.SHOW_INDEX_DETAILS;
  constructor(public payload: string) { }
}

/**
 * Action triggered by clicking the "Read" action in the listing's Actions column.
 */
export class ItemInformationListingReadAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.READ;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Share/Unshare" icon in the listing's Actions column.
 */
export class ItemInformationListingShareAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.SHARE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Share/Unshare" icon in the listing's Actions column.
 */
export class ItemInformationListingUnShareAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.UNSHARE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Subscribe" action in the listing's Actions column.
 */
export class ItemInformationListingSubscribeAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.SUBSCRIBE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

/**
 * Action triggered by clicking the "Subscribe" action in the listing's Actions column.
 */
export class ItemInformationViewScoreUpdateChangeAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = ItemInformationListingActionTypes.VIEW_SCORE_UPDATE_CHANGE;
  constructor(public payload: ItemInformationListingActionItemPayload<T>) { }
}

export type ItemInformationListingActionsUnion<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> =
  ItemInformationListingAnalyseAction<T>
  | ItemInformationListingChangeSortCriterionAction
  | ItemInformationListingUpdateDateFilterAction
  | ItemInformationListingClickAction<T>
  | ItemInformationListingDeleteAction<T>
  | ItemInformationListingDownloadAction<T>
  | ItemInformationListingEditAction<T>
  | ItemInformationListingIndexClickAction
  | ItemInformationListingReadAction<T>
  | ItemInformationListingShareAction<T>
  | ItemInformationListingUnShareAction<T>
  | ItemInformationListingToggleIndexInformationDetailAction<T>
  | ItemInformationListingSubscribeAction<T>
  | ItemInformationViewScoreUpdateChangeAction<T>
  | ItemInformationListingShowDetails;
