import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import { Spec } from 'vega';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VegaSchemaUtilsService } from '../../vega-chart';
import { ScorecardItemValue } from '../../../shared-models';
import { BodyItemExpandedScorecardComponent } from './body-item-expanded-scorecard/body-item-expanded-scorecard.component';

@Component({
  selector: 'grid-ui-body-item-scorecard',
  templateUrl: './body-item-scorecard.component.html',
  styleUrls: ['./body-item-scorecard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemScorecardComponent implements OnInit {

  @Input()
  public set value(val) {
    this.itemValue = val;
    const rawSpec: any = this.itemValue ?
      this.itemValue.embed_spec ?
        this.itemValue.embed_spec.spec :
        null :
      null;
    this.chartData = rawSpec ? this.vegaSchemaUtils.getV3Schema(rawSpec) : null;
  }

  public get value(): ScorecardItemValue | null {
    return this.itemValue;
  }

  @ViewChild('chart', { read: ElementRef }) public chart: ElementRef<HTMLDivElement> | null = null;

  public chartData: Spec | null = null;
  public scorecardID: string | null = null;
  private itemValue: ScorecardItemValue | null = null;

  public constructor(
    private readonly ngModalService: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private vegaSchemaUtils: VegaSchemaUtilsService) {
  }

  public ngOnInit(): void {
    // Append a quasi-unique string to make id of the vega parent
    // DOM element globally unique in the app DOM.
    const quasiGUID = (Math.random() + '').split('.')[1];
    this.scorecardID = `scorecard-chart-${quasiGUID}`;
  }

  public openExpander(): void {
    const modalRef = this.ngModalService.open(
      BodyItemExpandedScorecardComponent, { centered: true, size: 'lg' }
    );
    const modalComponent: BodyItemExpandedScorecardComponent = modalRef.componentInstance;
    modalComponent.chartData = this.chartData;
    if (this.chart) {
      modalComponent.chartRef = this.chart;
    }

    if (this.value) {
      modalComponent.setTitle(this.value.title || '');
      modalComponent.setSubTitle(this.value.subtitle || '');
      modalComponent.setCaption(this.value.caption || '');
    }
  }
}
