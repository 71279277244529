import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'ramda';


/**
 * A pipe to round up a decimal number to a given fixed decimal point
 */
@Pipe({
  name: 'mcFixedUp'
})
export class FixedUpPipe implements PipeTransform {
  public transform(value?: number | null, point: number = 2): string | undefined {
    if (isNil(value)) {
      return;
    }

    return ceilRiskScoreToString(value, point);
  }
}

/**
 * Rounds up a decimal number to a given fixed decimal point.
 */
export function ceilRiskScoreToString(value: number, point: number = 2): string {
  const factor = 10 ** point;
  const alreadyFixedUp = `${value}`.split('.')[1]?.length <= point;
  const ceilingedValue = alreadyFixedUp ? value : Math.ceil(value * factor) / factor;

  return ceilingedValue.toFixed(point);
}
