<button
  class="ask-an-analyst-button btn"
  [ngClass]="primaryButton ? 'mc-btn-primary' : 'mc-btn-secondary'"
  [disabled]="disabled"
  (click)="showModal()"
  data-hook="ask-the-analyst-button"
  [ngbTooltip]="tooltipText"
  container="body"
  [placement]="tooltipPlacement"
  [disableTooltip]="tooltipDisabled"
>
  <grid-ui-icon
    class="ask-an-analyst-button-icon"
    [ngClass]="primaryButton ? '' : 'active'"
    name="person-suit"
  ></grid-ui-icon>
  <span
    class="analyst-text"
    *ngIf="showButtonLabelText"
    data-hook="ask-the-analyst-button-text"
  >
    {{ label }}
  </span>
</button>
