<div class="loading" [ngClass]="{
  'loading--column': messagePosition === 'bottom' && message,
  'loading--row': messagePosition === 'right' && message
}">
  <img
    class="loading__icon" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'small' && color === 'primary'"
    src="assets/img/icons/loading-16.svg"
  />
  <img
    class="loading__icon loading__icon--medium" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'medium' && color === 'primary'"
    src="assets/img/icons/loading-24.svg"
  />
  <img
    class="loading__icon loading__icon--large" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'large' && color === 'primary'"
    src="assets/img/icons/loading-32.svg"
  />
  <img
    class="loading__icon loading__icon--very-large" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'very-large' && color === 'primary'"
    src="assets/img/icons/loading-48.svg"
  />
  <img
    class="loading__icon" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'small' && color === 'secondary'"
    src="assets/img/icons/loading-16-gold.svg"
  />
  <img
    class="loading__icon loading__icon--medium" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'medium' && color === 'secondary'"
    src="assets/img/icons/loading-24-gold.svg"
  />
  <img
    class="loading__icon loading__icon--large" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'large' && color === 'secondary'"
    src="assets/img/icons/loading-32-gold.svg"
  />
  <img
    class="loading__icon loading__icon--very-large" 
    [ngClass]="{
      'loading__icon--top': messagePosition === 'bottom' && message,
      'loading__icon--left': messagePosition === 'right' && message
    }"
    *ngIf="size === 'very-large' && color === 'secondary'"
    src="assets/img/icons/loading-48-gold.svg"
  />
  <p class="loading__message" [ngClass]="{
    'loading__message--small': fontSize === 'small'
  }"
    *ngIf="message"
  >
    {{message}}
  </p>
</div>
