import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PortalHttpClient } from '@grid-ui/common';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PaginatedResourceConfig } from '@grid-ui/common';

import { PaginatedReleaseNote, ReleaseNote, ReleaseNoteQueryParams } from '../models';

@Injectable()
export class ReleaseNoteService {
  private releaseNoteConfig: PaginatedResourceConfig;
  private releaseNoteDetailConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient,
  ) {
    this.releaseNoteConfig = API_SERVICES_CONFIG.feApi.releaseNotes._configuration;
    this.releaseNoteDetailConfig = API_SERVICES_CONFIG.feApi.releaseNotes.detail._configuration;
  }

  getReleaseNote(noteId: string): Observable<ReleaseNote> {
    return this.http.get<ReleaseNote>(
      this.releaseNoteDetailConfig,
      { pathParams: { noteId } },
    );
  }

  getReleaseNotes(queryParams?: ReleaseNoteQueryParams): Observable<PaginatedReleaseNote> {
    return this.http.get<PaginatedReleaseNote>(
      this.releaseNoteConfig,
      { queryParams },
    );
  }
}
