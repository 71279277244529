import { AnalyseDataTableHistoricalFlatTreeNode } from '../../../shared-models';

import { ApiAnalyseDataTableHistoricalFlatTreeNode } from '../../models';

export function mapAllDataTableHistoricalFlatNodeFromApiToApp(
  apiNode: ApiAnalyseDataTableHistoricalFlatTreeNode
): AnalyseDataTableHistoricalFlatTreeNode {
  const mappedNode: AnalyseDataTableHistoricalFlatTreeNode = apiNode.type === 'group'
    ? {
      ...apiNode,
      parent: apiNode.parent !== null ? `${apiNode.parent}` : null
    }
    : {
      ...apiNode,
      parent: `${apiNode.parent}`
    };
  return mappedNode;
}
