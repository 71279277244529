import { Observable } from 'rxjs';
import { AnalyseLeafTypeAheadItem, AnalyseLeafTypeAheadRow } from './analyse-leaf-typeahead-item.interface';

/** Interface to which a service's implemented methods must conform to support typeahead handling */
export interface AnalyseLeafAdditionHelperService {
  /** Clears any underlying cached unfiltered type ahead list */
  clearLeafTypeaheadList: () => void;
  /** Clear type ahead search term */
  clearLeafTypeaheadSearchTerm: () => void;
  /**
   * Return an Observable of an array of Typeahead List items representing leaves (Risk Indices, Countries or Sites
   * depending on service implementation) whose name matches the specified search term.
   * Returns [null], if no matching results could be found.
   *
   * @param searchTerm A search term to use for a contains search on the leaf names.
   */
  getLeafTypeaheadList: (searchTerm: string) => Observable<AnalyseLeafTypeAheadRow[]>;
}

export class AnalyseLeafAdditionHelper {
  constructor(
    private readonly self: AnalyseLeafAdditionHelperService,
    public readonly leafTypeSingular: string,
    public readonly leafTypePlural: string
  ) { }
  /** Clears any underlying cached unfiltered type ahead list */
  public clearLeafTypeaheadList(): void {
    this.self.clearLeafTypeaheadList();
  }

  /** Clear type ahead search term */
  public clearLeafTypeaheadSearchTerm(): void {
    this.self.clearLeafTypeaheadSearchTerm();
  }

  /**
   * Return an Observable of an array of Typeahead List items representing leaves (Risk Indices, Countries or Sites
   * depending on service implementation) whose name matches the specified search term.
   * Returns [null], if no matching results could be found.
   *
   * @param searchTerm A search term to use for a contains search on the leaf names.
   */
  public getLeafTypeaheadList(searchTerm: string): Observable<AnalyseLeafTypeAheadRow[]> {
    return this.self.getLeafTypeaheadList(searchTerm);
  }
}
