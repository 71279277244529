import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';


@Component({
  selector: 'grid-ui-cell-renderer-ellipsis-tooltip',
  templateUrl: './cell-renderer-ellipsis-tooltip.component.html',
  styleUrls: ['./cell-renderer-ellipsis-tooltip.component.scss']
})
export class CellRendererEllipsisTooltipComponent implements ICellRendererAngularComp {
  @ViewChild('cellContainer', { read: ElementRef }) private cellContainer?: ElementRef<HTMLSpanElement>;
  public displayValue = '';

  public get shouldDisableTooltip(): boolean {
    return !this.displayValue || (
      (this.cellContainer?.nativeElement?.parentElement?.parentElement?.offsetWidth || 0) - 35 >
      (this.cellContainer?.nativeElement?.offsetWidth || 0)
    );
  }

  public refresh(): boolean {
    throw new Error('Method not implemented.');
  }

  public agInit(params: ICellRendererParams): void {
    this.displayValue = params.valueFormatted ?? params.value;
  }
}

